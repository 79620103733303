import useApi from '@/services/api';
import { ApiTypes } from '@/types/api';

const profileApis = {
  getProfile: () =>
    useApi({
      query: 'user/profile',
      method: ApiTypes.GET,
      isLazy: true
    }),
  updateEnvironment: () =>
    useApi({
      query: 'user-projects/:userProjectId',
      method: ApiTypes.PATCH,
      isLazy: true
    }),
  changePassword: () =>
    useApi({
      query: 'auth/change-password',
      method: ApiTypes.POST,
      isLazy: true
    }),
  updateProfile: () =>
    useApi({
      query: 'user/profile',
      method: ApiTypes.PATCH,
      isLazy: true
    })
};

export const { getProfile, changePassword, updateEnvironment, updateProfile } = profileApis;
