import useProfileStore from '@/stores/profile';

export default () => {
  const profileStore = useProfileStore();

  const hasPermission = (permission: string) =>
    !!profileStore.profile?.permissions?.includes(permission);

  const isUserInvited = !!profileStore.profile?.activeOrganization?.hasAccess;

  return {
    hasPermission,
    isUserInvited
  };
};
