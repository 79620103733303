import { toRaw } from 'vue';
import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized
} from 'vue-router';
import { storeToRefs } from 'pinia';

import HomeLayout from '@/layouts/HomeLayout.vue';
import LocalStorage from '@/services/storage';
import usePermissions from '@/composables/usePermissions';
import useProfileStore from '@/stores/profile';
import useProjectStore from '@/stores/project';
import useFilterStore from '@/stores/filter';

import { PUBLIC_ENDPOINTS, ROUTES } from '@/constants/path';
import { LOGIN_STATUS } from '@/constants/common';
import { Permissions } from '@/constants/permissions';
import { getProfile } from '@/views/profile/api/api';
import { CAMPAIGN_TENANT } from '@/constants/config';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: ROUTES.DEFAULT,
      name: 'HomeLayout',
      component: HomeLayout,
      children: [
        {
          path: ROUTES.PROVIDERS,
          name: 'Providers',
          component: () => import('@/views/providers/Providers.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.PROVIDERS_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.PROVIDER_INTEGRATIONS,
          name: 'ProviderIntegrations',
          component: () => import('@/views/provider-integrations/ProviderIntegrations.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.PROVIDER_INTEGRATIONS_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.WORKFLOWS,
          name: 'Workflows',
          props: { name: 'Workflows' },
          component: () => import('@/views/workflow/WorkFlow.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.WORKFLOWS_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.WORKFLOW_CREATE,
          name: 'Workflow Create',
          props: { name: 'Workflow Create' },
          component: () => import('@/views/workflow-create/WorkflowCreate.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.WORKFLOW_EDIT_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.CONFIGURATION,
          name: 'Configuration',
          props: { name: 'Configuration' },
          component: () => import('@/views/configurations/Configuration.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.KEYS_PAGE_VIEW, Permissions.WEBHOOK_CONFIG_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.TEMPLATES,
          name: 'Templates',
          props: { name: 'Templates' },
          component: () => import('@/views/templates/Template.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.TEMPLATES_PAGE_VIEW]
          }
        },
        {
          path: `${ROUTES.TEMPLATES}/:id/edit`,
          name: 'TemplateEdit',
          props: { name: 'Template Edit' },
          component: () => import('@/views/template-edit/TemplateEdit.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.TEMPLATE_EDIT_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.LOGS,
          name: 'Logs',
          props: { name: 'Logs' },
          component: () => import('@/views/logs/Logs.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.LOGS_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.DATA_SOURCE,
          name: 'data-source',
          component: () => import('@/views/data-source/DataSource.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.DATASOURCES_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.DATA_SOURCE_EDIT,
          name: 'data-source-edit',
          component: () => import('@/views/data-source/DataSourceEdit.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.DATASOURCE_CONNECTIONS_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.SEGMENT_DETAIL,
          name: 'segment-detail',
          component: () => import('@/views/data-source/SegmentDetail.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.SEGMENT_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.QUERIES_EDIT,
          name: 'Edit query',
          props: { name: 'Edit Query' },
          component: () => import('@/views/queries/QueriesEdit.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.DATA_FETCH_INFO_DETAILS_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.COHORTS,
          name: 'Cohorts',
          props: { name: 'Cohorts' },
          component: () => import('@/views/cohorts/Cohorts.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.COHORTS_PAGE_VIEW],
            hasFeatureFlag: true
          }
        },
        {
          path: ROUTES.CREATE_COHORT,
          name: 'Create Cohort',
          props: { name: 'Create Cohort' },
          component: () => import('@/views/cohorts/CreateCohort.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.COHORT_CREATE]
          }
        },
        {
          path: ROUTES.COHORTS_EDIT,
          name: 'Cohorts Edit',
          props: { name: 'Edit Cohort' },
          component: () => import('@/views/cohorts/CohortsEdit.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.COHORT_EDIT],
            hasFeatureFlag: true
          }
        },
        {
          path: ROUTES.CAMPAIGNS,
          name: 'campaign-list',
          component: () => import('@/views/campaigns/Campaigns.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.CAMPAIGNS_PAGE_VIEW],
            hasFeatureFlag: true
          }
        },
        {
          path: ROUTES.CAMPAIGNS_PREVIEW,
          name: 'campaign-preview',
          component: () => import('@/views/campaigns/CampaignPreview.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.CAMPAIGNS_PAGE_VIEW],
            hasFeatureFlag: true
          }
        },
        {
          path: ROUTES.CAMPAIGNS_EDIT,
          name: 'campaign-edit',
          component: () => import('@/views/campaigns/CampaignEdit.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.CAMPAIGN_EDIT],
            hasFeatureFlag: true
          }
        },
        {
          path: ROUTES.DASHBOARD,
          name: 'Dashboard',
          props: { name: 'Dashboard' },
          component: () => import('@/views/dashboard/Dashboard.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.DASHBOARD_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.PROFILE,
          name: 'Profile',
          component: () => import('@/views/profile/Profile.vue'),
          meta: {
            requiresAuth: false
          }
        },
        {
          path: ROUTES.TEAMS,
          name: 'Team',
          component: () => import('@/views/team/Team.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.TEAM_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.USERS,
          name: 'Users',
          component: () => import('@/views/users/Users.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.USER_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.USER_DETAIL,
          name: 'UserDetail',
          component: () => import('@/views/users/UserDetail.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.USER_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.USER_ADD,
          name: 'UserAdd',
          component: () => import('@/views/users/UserEdit.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.USER_CREATE]
          }
        },
        {
          path: ROUTES.USER_EDIT,
          name: 'UserEdit',
          component: () => import('@/views/users/UserEdit.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.USER_EDIT]
          }
        },
        {
          path: ROUTES.SCHEDULE,
          name: 'Schedule',
          component: () => import('@/views/schedule/Schedule.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.SCHEDULES_PAGE_VIEW]
          }
        },
        {
          path: ROUTES.SCHEDULE_EDIT,
          name: 'ScheduleEdit',
          props: { name: 'Schedule Edit' },
          component: () => import('@/views/schedule/EditSchedule.vue'),
          meta: {
            requiresAuth: true,
            permission: [Permissions.SCHEDULES_EDIT]
          }
        },
        {
          path: ROUTES.CONTACT_ADMIN,
          name: 'ContactAdmin',
          component: () => import('@/views/contact-admin/ContactAdmin.vue')
        },
        {
          path: ROUTES.ERROR_FALLBACK,
          name: 'ErrorFallback',
          component: () => import('@/views/error-fallback/ErrorFallback.vue')
        },
        {
          path: ROUTES.PAGE_NOT_FOUND,
          name: 'PageNotFound',
          component: () => import('@/views/page-404/Page404.vue')
        },
        {
          path: ROUTES.INVALD_URL,
          name: 'InvalidUrl',
          component: () => import('@/views/page-404/Page404.vue')
        }
      ]
    },
    {
      path: ROUTES.LOGIN,
      name: 'Login',
      component: () => import('@/views/login/Login.vue')
    },
    {
      path: ROUTES.SIGNUP,
      name: 'Signup',
      component: () => import('@/views/signup/Signup.vue')
    },
    {
      path: ROUTES.VERIFY_ACCOUNT,
      name: 'Verify',
      component: () => import('@/views/verify/Verify.vue')
    },
    {
      path: ROUTES.VERIFY_INVITE,
      name: 'VerifyInvite',
      component: () => import('@/views/invite-verification/VerifyInvite.vue')
    },
    {
      path: ROUTES.PASSWORD_RESET,
      name: 'PasswordReset',
      component: () => import('@/views/password-reset/PasswordReset.vue')
    },
    {
      path: ROUTES.PASSWORD_RESET_SUCCESS,
      name: 'PasswordResetSuccess',
      component: () => import('@/views/password-reset/PasswordResetSuccess.vue')
    },
    {
      path: ROUTES.WORKFLOW_CLONE_SUCCESS,
      name: 'WorkflowCloneSuccess',
      component: () => import('@/views/workflow/WorkflowCloneComplete.vue')
    },
    {
      path: ROUTES.WORKFLOW_CLONE_FAIL,
      name: 'WorkflowCloneFail',
      component: () => import('@/views/workflow/WorkflowCloneComplete.vue')
    },
    {
      path: ROUTES.WELCOME,
      name: 'Welcome',
      component: () => import('@/views/welcome/Welcome.vue')
    }
  ]
});

const hasNewlyAcceptedInvites = () => {
  const profileStore = useProfileStore();
  const { profile } = storeToRefs(profileStore);
  const { parentOrganization, externalOrganizations } = profile.value || {};

  return [
    ...(parentOrganization ? [parentOrganization] : []),
    ...(externalOrganizations || [])
  ].some((organization) => organization.hasAccess && organization.isFirstVisit);
};

const redirect = (to: RouteLocationNormalized, next: NavigationGuardNext) => {
  const profileStore = useProfileStore();
  const { isUserInvited } = usePermissions();

  if (to.fullPath === ROUTES.WELCOME) next();
  else if (hasNewlyAcceptedInvites()) next(ROUTES.WELCOME);
  else if (to.fullPath === ROUTES.LOGIN || to.fullPath === ROUTES.DEFAULT) next(ROUTES.DASHBOARD);
  else if (
    to?.meta?.hasFeatureFlag &&
    !JSON.parse(CAMPAIGN_TENANT).includes(profileStore.profile?.activeOrganization.name)
  )
    next(ROUTES.PAGE_NOT_FOUND);
  else if (to.fullPath === ROUTES.PROFILE) next();
  else if (to.fullPath !== ROUTES.CONTACT_ADMIN && !isUserInvited) next(ROUTES.CONTACT_ADMIN);
  else if (to.fullPath === ROUTES.CONTACT_ADMIN && isUserInvited) next(ROUTES.DASHBOARD);
  else next();
};

const getProfileData = async (to: RouteLocationNormalized, next: NavigationGuardNext) => {
  const profileStore = useProfileStore();
  const projectStore = useProjectStore();

  const { data: profileData, isError, makeCall: getProfileDetails } = getProfile();

  await getProfileDetails();

  if (!isError.value) {
    const profile = toRaw(profileData.value.data);

    const { project, activeProjectEnvironmentId } = profile.userProjects[0] || {};
    let activeEnv = null;

    if (activeProjectEnvironmentId) activeEnv = activeProjectEnvironmentId;
    else if (project?.projectEnvironments?.length) activeEnv = project.projectEnvironments[0].id;

    profileStore.setProfile(profile);
    projectStore.setProjectDetails(profile.userProjects[0]);
    projectStore.setProjectEnvironment(activeEnv);

    redirect(to, next);
  } else {
    next();
  }
};

router.beforeEach(async (to, from, next) => {
  const profileStore = useProfileStore();
  const filterStore = useFilterStore();
  const loginStatus = LocalStorage.getItem(LOGIN_STATUS);
  const toPath = to.path;
  const fromPath = from.path.split('/')[1];

  if (
    !toPath.includes(fromPath) &&
    !(
      toPath === ROUTES.LOGS &&
      (from.path === ROUTES.DASHBOARD ||
        (from.path !== ROUTES.CAMPAIGNS && from.path.includes(ROUTES.CAMPAIGNS)))
    )
  )
    filterStore.clearFilters();

  if (
    loginStatus === 'ACTIVE' &&
    ![ROUTES.VERIFY_ACCOUNT, ROUTES.VERIFY_INVITE].includes(to.path as ROUTES)
  )
    if (profileStore.profile) redirect(to, next);
    else await getProfileData(to, next);
  else if (PUBLIC_ENDPOINTS.includes(to.path as ROUTES)) next();
  else next(ROUTES.LOGIN);
});

export default router;
