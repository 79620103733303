import { passwrodStrengthFields } from '@/constants/login';
import { ESCAPE_CHAR_REGEX } from '@/constants/regex';

export const checkRegexMatch = (regex: RegExp, text: string) => new RegExp(regex).test(text);

export const checkPasswordStrength = (password: string) => {
  let isValidPassword = true;

  passwrodStrengthFields.forEach((strengthField) => {
    const match = checkRegexMatch(strengthField.regex, password);

    if (!match) isValidPassword = false;
  });

  return isValidPassword;
};

export const removeExtraBackslash = (text: string) => text.replace(ESCAPE_CHAR_REGEX, '\n');
