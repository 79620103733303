<template>
  <Transition
    name="modal"
    :class="reference && 'animate'"
    @before-enter="setInitialPosition"
    @leave="setFinalPosition"
  >
    <div
      v-show="open"
      tabindex="-1"
      aria-hidden="true"
      class="fixed top-0 left-0 right-0 z-[10000] w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full bg-[#1f1f306b]"
      :class="className"
    >
      <div class="flex flex-col w-full h-full justify-center items-center">
        <div class="text-blue_3 bg-white rounded-md">
          <slot v-if="showCustomHeader" name="header" />
          <div v-else class="flex border border-transparent p-6 lg:min-w-[400px]">
            <div v-if="headerImage" class="p-2 mr-4 h-12 w-12 min-w-[48px] bg-grey_100 rounded-xl">
              <img class="w-full h-full" :src="headerImage" alt="header" />
            </div>
            <div class="w-full">
              <div class="flex justify-between items-center">
                <p class="mr-2 font-semibold text-lg leading-7 max-w-[330px] break-words">
                  {{ title }}
                </p>
                <button type="button" @click="$emit('handle-cancel')" v-show="showCloseIcon">
                  <img :width="20" :height="20" src="@/assets/icons/CloseBlue.svg" alt="Close" />
                </button>
              </div>
              <p class="text-sm w-full break-words text-grey_500">{{ subtitle }}</p>
            </div>
          </div>
          <div class="py-4 px-6 max-h-[70vh] max-w-[90vw] overflow-y-auto" :class="bodyClassName">
            <div :class="contentWrapperClassName">
              <slot name="content" />
            </div>
          </div>
          <div
            v-show="showFooter"
            class="border border-transparent border-t-grey_2 py-4 px-6 flex justify-between items-center lg:min-w-[400px]"
          >
            <slot name="footer" />
          </div>
        </div>
        <div v-if="showPagination" class="pt-4 flex gap-2 justify-center items-center">
          <div
            v-for="count in totalPages"
            :key="count"
            class="w-[13px] h-[13px] rounded-full"
            :class="count === currentPage ? 'bg-primary_400' : 'bg-[#F2F2F2]'"
          />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
type Props = {
  open: boolean;
  title?: string;
  subtitle?: string;
  showCloseIcon?: boolean;
  showFooter?: boolean;
  className?: string;
  bodyClassName?: string;
  contentWrapperClassName?: string;
  showCustomHeader?: boolean;
  headerImage?: string;
  reference?: HTMLElement | null;
  showPagination?: boolean;
  totalPages?: number;
  currentPage?: number;
};
const props = withDefaults(defineProps<Props>(), {
  open: false,
  title: '',
  subtitle: '',
  showCloseIcon: false,
  className: '',
  bodyClassName: '',
  contentWrapperClassName: '',
  showFooter: false,
  headerImage: '',
  reference: null,
  totalPages: 0,
  currentPage: 0
});

defineEmits(['handle-cancel']);

const setInitialPosition = (element: Element) => {
  if (props.reference) {
    const triggerButtonRect = props.reference.getBoundingClientRect();

    const modalEl = element as HTMLElement;

    modalEl.style.transform = `translate(-${triggerButtonRect.top}px, ${triggerButtonRect.left}px) scale(0)`;
    modalEl.style.transition = 'transform 0.3s ease-in-out';
    setTimeout(() => {
      modalEl.style.transform = 'translate(0, 0) scale(1)';
    }, 10);
  }
};

const setFinalPosition = (element: Element) => {
  if (props.reference) {
    const triggerButtonRect = props.reference.getBoundingClientRect();
    const modalEl = element as HTMLElement;

    modalEl.style.transform = `translate(-${triggerButtonRect.top}px, ${triggerButtonRect.left}px) scale(0)`;
  }
};
</script>

<style scoped>
.animate.modal-enter-active {
  transition: transform 0.3s ease-in-out;
}
.animate.modal-leave-active {
  transition: transform 0.3s ease-in-out;
}
</style>
