<template>
  <div class="w-[20px] h-[20px]">
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 8.83333H17.5M2.5 12.1667H17.5M10 8.83333V15.5M4.16667 15.5H15.8333C16.7538 15.5 17.5 14.7538 17.5 13.8333V7.16667C17.5 6.24619 16.7538 5.5 15.8333 5.5H4.16667C3.24619 5.5 2.5 6.24619 2.5 7.16667V13.8333C2.5 14.7538 3.24619 15.5 4.16667 15.5Z"
        stroke="#101928"
        stroke-width="1.4"
      />
    </svg>
  </div>
</template>
