export const MAX_PROVIDER_ACCOUNTS = 5;
export const MS_TEAMS_CHANNEL_LIMIT = 5;

export enum ProviderDropDownKeys {
  EDIT = 'edit',
  DELETE = 'delete'
}

export const ProviderDropDownData = [
  {
    id: ProviderDropDownKeys.EDIT,
    value: 'edit'
  },
  {
    id: ProviderDropDownKeys.DELETE,
    value: 'delete',
    customStyle: 'text-rose_400'
  }
];

// TODO Replace from backend
export const ProviderUrlMap: Record<string, string> = {
  MessageBird: 'messagebird.com',
  MSG91: 'msg91.com',
  Twilio: 'twilio.com',
  Plivo: 'plivo.com',
  'Kaleyra.io': 'kaleyra.com',
  Meta: 'about.meta.com',
  WATI: 'wati.io',
  GUPSHUP: 'gupshup.io',
  SendGrid: 'sendgrid.com',
  Outlook: 'microsoft.com',
  Postmark: 'postmarkapp.com',
  Mailchimp: 'mailchimp.com',
  Gmail: 'developers.google.com',
  SES: 'aws.amazon.com/ses',
  Mailgun: 'mailgun.com',
  FCM: 'firebase.google.com',
  OneSignal: 'onesignal.com',
  Slack: 'slack.com',
  'Teams Webhook': 'microsoft.com',
  Siren: 'app.trysiren.io',
  Discord: 'discord.com',
  Line: 'line.me'
};

export const ProviderDocMap: Record<string, string> = {
  MessageBird: 'developers.messagebird.com/quickstarts/sms-overview',
  MSG91: 'docs.msg91.com',
  Twilio: 'twilio.com/docs',
  Plivo: 'plivo.com/docs/messaging',
  'Kaleyra.io': 'developers.kaleyra.io/docs/home-kaleyra-cloud-developer-hub',
  Meta: 'developers.facebook.com/docs/whatsapp',
  WATI: 'docs.wati.io',
  GUPSHUP: 'docs.gupshup.io/docs/overview',
  SendGrid: 'twilio.com/docs/sendgrid',
  Outlook: 'learn.microsoft.com/en-us/graph/outlook-mail-concept-overview',
  Postmark: 'postmarkapp.com/developer/api/email-api',
  Mailchimp: 'mailchimp.com/developer/transactional/guides/quick-start',
  Gmail: 'developers.google.com/gmail/api/guides',
  SES: 'docs.aws.amazon.com/ses',
  Mailgun: 'documentation.mailgun.com/docs/mailgun/user-manual/get-started',
  FCM: 'firebase.google.com/docs/cloud-messaging/concept-options',
  OneSignal: 'documentation.onesignal.com',
  Slack: 'api.slack.com/docs',
  'Teams Webhook':
    'learn.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook?tabs=newteams%2Cdotnet',
  Siren: 'docs.trysiren.io',
  Discord: 'discord.com/developers/docs/intro',
  Line: 'developers.line.biz/en/docs'
};

export enum ProviderCode {
  FCM = 'PUSH_FCM'
}
