import i18n from '@/i18n/config';
import SirenLogo from '@/assets/icons/Siren.svg';
import {
  ATTACHMENT_CONTENT_REGEX,
  URL_REGEX,
  VARIABLE_REGEX,
  TEMPLATE_VARIABLE,
  PLACEHOLDER_REGEX,
  CURLY_BRACE_REGEX,
  CONTAINS_DOLLAR_SIGN
} from '@/constants/regex';
import {
  LINE_TEMPLATE_FIELDS,
  LineMessageTypes,
  MandatoryTemplateFields,
  MessageTypes,
  WHATSAPP_TEMPLATE_FIELDS,
  WhatsappTemplateButtonSubTypePayload,
  WhatsappTemplateButtons,
  templateIcons
} from '@/constants/templates';
import { CohortIcon } from '@/constants/campaigns';
import { TEMPLATE_FIELD_ERRORS } from '@/constants/errors';
import { TemplateTypes } from '@/constants/common';

import type { DropDownDataType, Map } from '@/types';
import type {
  TemplateType,
  Attachment,
  AttachmentErrors,
  PushNotificationPreviewType,
  TemplateFieldType,
  TemplateVariables,
  WhatsappHeaderMessageType,
  WhatsappTemplateButtonsType
} from '@/types/templates';
import type { variablesListType } from '@/types/campaigns';

import {
  isAllFieldsEmptyInObject,
  isEmptyObject,
  isEmptyString,
  isObject,
  isUndefinedOrNull,
  isValidJsonString
} from '../checks';
import { flattenObject, getCommaSeparatedString, getUnionOfKeys } from '../generic';

export const replaceEmptyStringsWithNull = (obj: Map) => {
  const result: Map = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null)
      result[key] = replaceEmptyStringsWithNull(value);
    else result[key] = value === '' ? null : value;
  });

  return result;
};

export const replaceWithVariables = (preview: string, variableString: string) => {
  const variables: TemplateVariables[] = JSON.parse(variableString);
  let newPreview = preview;

  variables.forEach((variable) => {
    const variableName = `{{${variable.name}}}|{{{${variable.name}}}}`;

    newPreview = newPreview.replace(
      new RegExp(variableName, 'g'),
      () => variable?.placeHolder ?? ''
    );
  });

  return newPreview;
};

export const checkIfInvalidVariable = (content: string) => {
  const variables = content.match(VARIABLE_REGEX);
  const nonMatches = variables?.filter(
    (variable: string) => !variable.slice(2, -2) || !TEMPLATE_VARIABLE.test(variable.slice(2, -2))
  );

  return nonMatches && nonMatches.length > 0;
};

export const isTemplateAttachmentsInvalid = (attachments: Attachment[]) => {
  const isAttachmentInvalid = attachments.find((attachment) => {
    const fileName = attachment.fileName.split('.')[0];

    return (
      !fileName ||
      !attachment.type ||
      !attachment.content ||
      !PLACEHOLDER_REGEX.test(attachment.content) ||
      checkIfInvalidVariable(attachment.content) ||
      (PLACEHOLDER_REGEX.test(fileName) && checkIfInvalidVariable(fileName))
    );
  });

  return attachments.length > 0 && !!isAttachmentInvalid;
};

export const getTemplateAttachmentErrors = (attachments: Attachment[]): AttachmentErrors[] => {
  const fields = ['fileName', 'type', 'content'];

  const attachementErrors = attachments.map((attachment: any) => {
    let errors = { fileName: '', type: '', content: '' };

    fields.forEach((field: string) => {
      if (!attachment[field]) errors = { ...errors, [field]: `Please enter ${field}` };
      if (
        field === 'content' &&
        attachment.content &&
        !ATTACHMENT_CONTENT_REGEX.test(attachment.content)
      )
        errors = { ...errors, [field]: 'Content format is {{content}}' };
    });

    return errors;
  });

  return attachementErrors;
};

export const checkIfValidPushFields = (
  pushNotificationValues: PushNotificationPreviewType
): MandatoryTemplateFields[] => {
  const { title, message, additionalSettings, additionalData, androidSettings, iosSettings } =
    pushNotificationValues;

  const errorFields: MandatoryTemplateFields[] = [];

  if (!title) errorFields.push(MandatoryTemplateFields.PUSH_TITLE);
  if (!message) errorFields.push(MandatoryTemplateFields.PUSH_MESSAGE);
  if (additionalSettings?.ttl && !additionalSettings.duration)
    errorFields.push(MandatoryTemplateFields.PUSH_DURATION);
  if (additionalData && !isValidJsonString(additionalData))
    errorFields.push(MandatoryTemplateFields.PUSH_ADDITIONAL_DATA);
  if (androidSettings?.image && !URL_REGEX.test(androidSettings?.image))
    errorFields.push(MandatoryTemplateFields.PUSH_ANDROID_IMAGE);
  if (iosSettings?.image && !URL_REGEX.test(iosSettings?.image))
    errorFields.push(MandatoryTemplateFields.PUSH_IOS_IMAGE);

  return errorFields;
};

export const checkIfLiveData = (liveData: Map, fieldValues: Map) => {
  let isUpdated = false;

  const unionOfKeys = getUnionOfKeys(liveData, fieldValues);

  unionOfKeys.forEach((key: string) => {
    const liveDataValue = liveData?.[key];
    const fieldValue = fieldValues?.[key];

    if (isUndefinedOrNull(liveDataValue)) {
      if (Array.isArray(fieldValue) && fieldValue.length > 0) isUpdated = true;
      else if (
        isObject(fieldValue) &&
        !isEmptyObject(fieldValue) &&
        !isAllFieldsEmptyInObject(fieldValue)
      )
        isUpdated = true;
      else if (
        !isObject(fieldValue) &&
        !isUndefinedOrNull(fieldValue) &&
        !isEmptyString(fieldValue) &&
        fieldValue
      )
        isUpdated = true;
    } else if (isUndefinedOrNull(fieldValue) || isEmptyString(fieldValue)) {
      isUpdated = true;
    } else if (
      /* Condition to handle when the params are arrays, say fieldValue is [1, 2, 3]
      and liveDataValue is [1, 2, 3, 4] the recursive call fails to detect
      that one element was removed.
      */
      Array.isArray(fieldValue) &&
      JSON.stringify(fieldValue) !== JSON.stringify(liveDataValue)
    ) {
      isUpdated = true;
    } else if (
      !Array.isArray(fieldValue) &&
      isObject(fieldValue) &&
      (checkIfLiveData(liveDataValue, fieldValue) ||
        (isEmptyObject(fieldValue) && !isEmptyObject(liveDataValue)))
    ) {
      isUpdated = true;
    } else if (!isObject(fieldValue) && fieldValue !== liveDataValue) {
      isUpdated = true;
    }
  });

  return isUpdated;
};

export const extractVariables = (input: string) => {
  const matches = input.match(VARIABLE_REGEX);
  const variables = matches?.map((item) => item.replace(CURLY_BRACE_REGEX, ''));

  return variables;
};

export const extractVariableCount = (input: string) => {
  const variables = extractVariables(input);

  if (!variables) return 0;

  return variables.length;
};

export const getInputText = (inputRef: HTMLInputElement, text?: string) => {
  let inputText: string = '';
  let newCursorPosition: number = 0;

  if (inputRef) {
    const currentValue = inputRef.value;
    const variableCount = extractVariableCount(currentValue);
    const textToInsert = `${text ?? `{{var${variableCount + 1}}}`}`;
    const cursorPosition = inputRef.selectionStart ?? 0;

    inputText =
      currentValue.substring(0, cursorPosition) +
      textToInsert +
      currentValue.substring(cursorPosition);
    newCursorPosition = cursorPosition + textToInsert.length;
    inputRef.focus();
  }

  return { inputText, newCursorPosition };
};

export const parseString = (string: string) => {
  let data = {};

  try {
    data = JSON.parse(string);
  } catch (err) {
    data = string;
  }

  return data;
};

export const getFormattedTemplates = (templateList: TemplateType[]) =>
  templateList.map((template: TemplateType) => ({
    id: template.id,
    value: template.name,
    channels: template.publishedVersion?.channelTemplates?.map((item) => item.toString()) || [],
    variables: template?.variableMapping ?? [],
    customStyle: 'truncate max-w-full text-ellipsis',
    tags: getCommaSeparatedString(template.tags, 'name')
  }));

export const formatPushPayload = (pushValue: PushNotificationPreviewType) =>
  pushValue.additionalData
    ? {
        ...pushValue,
        additionalData: parseString(pushValue.additionalData)
      }
    : pushValue;

export const getFieldErrors = (obj: Map, fields: TemplateFieldType[]) => {
  const flattenedObj: Map = flattenObject(obj);
  let errors = {};

  fields.forEach((field) => {
    // Should be inside for loop since index for regex changes every time in when using inside loop.
    const varRegex = new RegExp(PLACEHOLDER_REGEX);

    if (field.isMandatory && field.label && !flattenedObj[field.key]?.trim()) {
      const fieldLabel = i18n.global.t(field.label);

      errors = {
        ...errors,
        [field.key]: i18n.global.t('field_is_mandatory', { fieldName: fieldLabel })
      };
    } else if (
      field.regex &&
      flattenedObj[field.key]?.trim() &&
      !field.regex.test(flattenedObj[field.key].trim()) &&
      !varRegex.test(flattenedObj[field.key]?.trim())
    ) {
      errors = { ...errors, [field.key]: i18n.global.t(field?.errorMessage ?? '') };
    } else if (
      flattenedObj[field.key] &&
      checkIfInvalidVariable(flattenedObj[field.key].toString())
    ) {
      errors = { ...errors, [field.key]: i18n.global.t(TEMPLATE_FIELD_ERRORS.VARIABLE) };
    }
  });

  return errors;
};

export const getWhatsappFields = (
  messageType: WhatsappHeaderMessageType,
  buttons: WhatsappTemplateButtonsType
) => {
  const fields: TemplateFieldType[] = [
    {
      key: WHATSAPP_TEMPLATE_FIELDS.TITLE,
      isMandatory: true,
      label: 'title'
    }
  ];

  switch (messageType.type) {
    case MessageTypes.TEXT:
      fields.push({ key: WHATSAPP_TEMPLATE_FIELDS.URL, isMandatory: true, label: 'placeholder' });
      break;
    case MessageTypes.IMAGE:
    case MessageTypes.VIDEO:
      fields.push({
        key: WHATSAPP_TEMPLATE_FIELDS.URL,
        isMandatory: true,
        errorMessage: TEMPLATE_FIELD_ERRORS.WHATSAPP_URL,
        regex: URL_REGEX,
        label: 'url'
      });
      break;
    case MessageTypes.FILE:
      fields.push({
        key: WHATSAPP_TEMPLATE_FIELDS.URL,
        isMandatory: true,
        errorMessage: TEMPLATE_FIELD_ERRORS.WHATSAPP_URL,
        regex: URL_REGEX,
        label: 'url'
      });
      fields.push({
        key: WHATSAPP_TEMPLATE_FIELDS.FILENAME,
        isMandatory: true,
        label: 'file_name'
      });
      break;
    default:
  }

  buttons.buttonsArray.forEach((_, index) => {
    fields.push({
      key: `${buttons.buttonType}_${index + 1}`,
      isMandatory: false
    });
  });

  return fields;
};

export const getTemplateIconPath = (item: TemplateTypes) => templateIcons[item];

export const getButtonTypeObjectFromArray = (button: any) => {
  const buttonIndex = button.index;
  let buttonPlaceholder = '';

  if (button.parameters?.length > 0)
    if (button.sub_type === WhatsappTemplateButtonSubTypePayload.CALL_TO_ACTION)
      buttonPlaceholder = button.parameters[0].text;
    else if (button.sub_type === WhatsappTemplateButtonSubTypePayload.QUICK_REPLIES)
      buttonPlaceholder = button.parameters[0].payload;

  return { index: buttonIndex, placeholder: buttonPlaceholder };
};

export const getFormattedWhatsappValue = (configuration: Map) => {
  const componentsArray = configuration?.template?.components;
  const buttonsExists = componentsArray?.length > 2;

  return {
    name: configuration?.template?.name,
    header: configuration?.template?.components[0]?.parameters[0],
    body: configuration?.template?.components[1]?.parameters.map((item: Map, index: number) => ({
      index: index + 1,
      placeholder: item?.text
    })),
    buttons: buttonsExists
      ? {
          buttonType:
            configuration?.template?.components[2]?.sub_type ===
            WhatsappTemplateButtonSubTypePayload.CALL_TO_ACTION
              ? WhatsappTemplateButtons.CALL_TO_ACTION
              : WhatsappTemplateButtons.QUICK_REPLIES,
          buttonsArray: componentsArray
            ?.slice(2)
            .map((button: any) => getButtonTypeObjectFromArray(button))
        }
      : {
          buttonType: WhatsappTemplateButtons.NONE,
          buttonsArray: []
        }
  };
};

export const getFormattedMessageType = (header?: Map) => {
  const message = {
    type: MessageTypes.NONE,
    url: '',
    fileName: ''
  };

  if (header) {
    message.type = header?.type;
    switch (header?.type) {
      case MessageTypes.TEXT:
        message.url = header?.text;
        break;
      case MessageTypes.IMAGE:
        message.url = header?.image?.link;
        break;
      case MessageTypes.VIDEO:
        message.url = header?.video?.link;
        break;
      case MessageTypes.FILE:
        message.url = header?.document?.link;
        message.fileName = header?.document?.filename;
        break;
      default:
    }
  }

  return message;
};

export const getLineErrorFields = (type: string) => {
  const fields: TemplateFieldType[] = [
    {
      key: LINE_TEMPLATE_FIELDS.TYPE,
      isMandatory: true,
      label: 'type'
    }
  ];

  switch (type) {
    case LineMessageTypes.TEXT:
      fields.push({
        key: LINE_TEMPLATE_FIELDS.TEXT,
        isMandatory: true,
        label: 'text'
      });
      break;
    case LineMessageTypes.IMAGE:
    case LineMessageTypes.VIDEO:
      fields.push(
        {
          key: LINE_TEMPLATE_FIELDS.CONTENT_URL,
          isMandatory: true,
          errorMessage: TEMPLATE_FIELD_ERRORS.LINE_URL,
          regex: URL_REGEX,
          label: 'content url'
        },
        {
          key: LINE_TEMPLATE_FIELDS.PREVIEW_URL,
          isMandatory: true,
          errorMessage: TEMPLATE_FIELD_ERRORS.LINE_URL,
          regex: URL_REGEX,
          label: 'preview url'
        }
      );
      break;
    default:
  }

  return fields;
};

export const getCurrentTime = () =>
  new Date().toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });

export const showLineUrlInputFields = (type: string) =>
  [String(LineMessageTypes.IMAGE), String(LineMessageTypes.VIDEO)].includes(type);

export const formatFilters = (selectedFilters: Map) => {
  const newObj: Map = {};

  Object.keys(selectedFilters).forEach((key) => {
    if (selectedFilters[key] && selectedFilters[key]?.length > 0)
      selectedFilters[key]?.forEach((filter: { value: string }) => {
        if (newObj[key]) newObj[key] = [...newObj[key], filter.value];
        else newObj[key] = [filter.value];
      });
  });

  return newObj;
};

export const replaceMappedVariables = (text: string, variableMap: variablesListType[]) => {
  let newText = text;

  variableMap.forEach((variable) => {
    newText = newText?.replace(
      new RegExp(`{{${variable.value}}}|{{{${variable.value}}}}`, 'g'),
      () => `{{${variable.selectedCohort}}}`
    );
  });

  return newText;
};

export const getStyledVariables = (text: string, variables: DropDownDataType[]) => {
  let styledText = text;

  variables.forEach((variable) => {
    const variableValue = variable.value.replace(CONTAINS_DOLLAR_SIGN, '\\$');

    styledText = styledText?.replace(
      new RegExp(`{{\\s*${variableValue}\\s*}}|{{{\\s*${variableValue}\\s*}}}`, 'g'),
      () => `<span style="color: rgb(245, 102, 48);">{{${variable.value}}}</span>`
    );
  });

  return styledText;
};

export const removeDollarFromText = (input: string) =>
  input?.replace(/{{\s*\$.*?\s*}}/g, (match) => {
    const dollarCount = (match.match(CONTAINS_DOLLAR_SIGN) || [])?.length;

    if (dollarCount >= 1) return match?.replace(/\$/, '');

    return match;
  });

export const getMappedVariables = (text: string, variables: DropDownDataType[], row: Map) => {
  let mappedText = text;

  variables.forEach((variable) => {
    const variableValue = variable.value.replace(CONTAINS_DOLLAR_SIGN, '\\$');

    mappedText = mappedText?.replace(
      new RegExp(`{{\\s*${variableValue}\\s*}}|{{{\\s*${variableValue}\\s*}}}`, 'g'),
      row?.[variable.value] ?? ''
    );
  });

  return mappedText;
};

export const getTitleKey = (channel: TemplateTypes) => {
  let key = '';

  switch (channel) {
    case TemplateTypes.EMAIL:
      key = 'subject';
      break;
    case TemplateTypes.PUSH:
      key = 'title';
      break;
    case TemplateTypes.IN_APP:
      key = 'header';
      break;
    default:
  }

  return key;
};

export const getBodyKey = (channel: TemplateTypes) => {
  let key: string = '';

  switch (channel) {
    case TemplateTypes.SMS:
    case TemplateTypes.EMAIL:
    case TemplateTypes.SLACK:
    case TemplateTypes.TEAMS:
    case TemplateTypes.IN_APP:
      key = 'body';
      break;
    case TemplateTypes.PUSH:
      key = 'message';
      break;
    case TemplateTypes.DISCORD:
      key = 'content';
      break;
    default:
  }

  return key;
};

export const getVariable = (variables: DropDownDataType[], cohortType: string) => {
  const list = variables?.map((variable) => {
    if (variable.value?.match(CONTAINS_DOLLAR_SIGN))
      return {
        id: variable.id,
        value: variable.value,
        imgUrl: CohortIcon[cohortType] ?? ''
      };

    return {
      id: variable.id,
      value: variable.value,
      imgUrl: SirenLogo
    };
  });

  return list ?? [];
};
