<template>
  <Modal :open="isOpen" :showCustomHeader="true" bodyClassName="max-h-[446px] min-w-[500px]">
    <template #header>
      <div class="rounded-t-md px-6 pt-6 mx-1 mb-1">
        <div class="flex justify-between items-center mt-1">
          <div class="text-black text-[22px] leading-[26px] font-semibold">
            {{ $t('switch_organization') }}
          </div>
          <button type="button" class="flex items-center justify-center" @click="onClose">
            <img width="20" height="20" src="@/assets/icons/CloseBlue.svg" alt="Close" />
          </button>
        </div>
        <div class="text-grey_400 text-sm font-medium mt-2 max-w-[445px]">
          {{ $t('choose_to_switch') }}
        </div>
      </div>
    </template>
    <template #content>
      <OrganizationList
        :organizations="sortedOrganizations"
        :activeOrganizationId="activeOrganization?.id || ''"
        :disabled="isSwitchDisabled"
        :primaryButtonLabel="$t('switch')"
        :onClose="onClose"
        @onSelect="onSelectOrganization"
      />
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import Modal from '@/components/Modal.vue';
import OrganizationList from '@/components/OrganizationList.vue';
import useProfileStore from '@/stores/profile';

defineProps<{
  isOpen: boolean;
  onClose: () => void;
}>();

const profileStore = useProfileStore();
const { activeOrganization, parentOrganization, externalOrganizations } =
  profileStore.profile || {};

const isSwitchDisabled = ref<boolean>(true);

const sortedOrganizations = computed(() => {
  const sortedList = [
    ...(parentOrganization ? [parentOrganization] : []),
    ...(externalOrganizations || [])
  ];

  return sortedList.filter(
    (organization) => organization.id === parentOrganization?.id || organization.hasAccess
  );
});

const onSelectOrganization = (selectedOrganizationId: string) => {
  isSwitchDisabled.value = selectedOrganizationId === activeOrganization?.id;
};
</script>
