<script setup lang="ts">
import { onMounted } from 'vue';

onMounted(() => {
  document.getElementById('loader')?.remove();
});
</script>

<template>
  <RouterView />
</template>
