import type { Map } from '@/types';

import { STATUS_COLOR } from './common';

export enum ScheduleDropDownKeys {
  RENAME = 'rename',
  DELETE = 'delete'
}

export enum ScheduleStatusTypes {
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED',
  ACTIVE = 'ACTIVE'
}

export const SCHEDULE_STATUS_COLORS = {
  [ScheduleStatusTypes.DRAFT]: STATUS_COLOR.GREY,
  [ScheduleStatusTypes.SCHEDULED]: STATUS_COLOR.YELLOW,
  [ScheduleStatusTypes.PAUSED]: STATUS_COLOR.RED,
  [ScheduleStatusTypes.ACTIVE]: STATUS_COLOR.GREEN,
  [ScheduleStatusTypes.COMPLETED]: STATUS_COLOR.BLUE
};

export enum SchedulingFrequencyTypes {
  ONCE = 'ONCE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  CUSTOM = 'CUSTOM'
}

export const SchedulingFrequency = [
  {
    id: SchedulingFrequencyTypes.ONCE,
    value: 'once'
  },
  {
    id: SchedulingFrequencyTypes.DAILY,
    value: 'daily'
  },
  {
    id: SchedulingFrequencyTypes.WEEKLY,
    value: 'weekly'
  },
  {
    id: SchedulingFrequencyTypes.MONTHLY,
    value: 'monthly'
  }
  // TDOO: Uncomment this when custom scheduling needs to be activated
  // {
  //   id: SchedulingFrequencyTypes.CUSTOM,
  //   value: 'custom'
  // }
];

export enum EndOptionType {
  NEVER = 'NEVER',
  CUSTOM_DATE = 'CUSTOM_DATE',
  NUM_OF_OCCURENCES = 'NUM_OF_OCCURENCES'
}

export const ScheduleEndOption = [
  {
    id: EndOptionType.NEVER,
    value: 'never'
  },
  {
    id: EndOptionType.CUSTOM_DATE,
    value: 'custom_date'
  }
];

export const DayMap: Map = {
  SUN: 7,
  MON: 1,
  TUE: 2,
  WED: 3,
  THU: 4,
  FRI: 5,
  SAT: 6
};

export const Weeks = [
  {
    id: 7,
    value: 'S'
  },
  {
    id: 1,
    value: 'M'
  },
  {
    id: 2,
    value: 'T'
  },
  {
    id: 3,
    value: 'W'
  },
  {
    id: 4,
    value: 'T'
  },
  {
    id: 5,
    value: 'F'
  },
  {
    id: 6,
    value: 'S'
  }
];

export enum MonthType {
  FIRST_DAY = 'FIRST_DAY',
  LAST_DAY = 'LAST_DAY',
  CUSTOM_DAY = 'CUSTOM_DATE'
}

export const Months = [
  {
    id: MonthType.FIRST_DAY,
    value: 'first_day'
  },
  {
    id: MonthType.LAST_DAY,
    value: 'last_day'
  },
  {
    id: MonthType.CUSTOM_DAY,
    value: 'custom_day_of_month'
  }
];

export const MAX_SCHEDULE_TITLE_LENGTH = 70;

export const DAYS_IN_MONTH = 28;

export enum SchedulesSection {
  CRON_REGEX = 'cronRegex',
  START_DATE_TIME = 'startDateAndTime',
  END_SCHEDULE = 'scheduleEnd',
  WEEK_SECTION = 'weekSection',
  MONTH_SECTION = 'monthSection',
  CUSTOM_END_DATE = 'customEndDate',
  WORK_FLOW = 'workflowId',
  COHORT_TYPE = 'cohortId',
  SCHEDULE_TYPE = 'scheduleType',
  SCHEDULE_TIME = 'scheduleTime',
  TIME_ZONE = 'timezoneId',
  NUM_OF_OCCURENCES = 'noOfOccurrences',
  UPDATED_AT = 'modifiedAt'
}

export const scheduleFrequencies = [
  {
    id: SchedulingFrequencyTypes.ONCE,
    value: 'once'
  },
  {
    id: SchedulingFrequencyTypes.DAILY,
    value: 'daily'
  }
];

export const scheduleWeekDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
