import { ref } from 'vue';
import { defineStore } from 'pinia';

import type { ProjectType } from '@/types/projects';
import type { DropDownDataType } from '@/types';

const useProjectStore = defineStore('project', () => {
  const project = ref<ProjectType>();
  const projectEnvironments = ref<DropDownDataType[]>([]);
  const projectEnv = ref<DropDownDataType | undefined>();

  const setProjectEnvironment = (envId: string) => {
    projectEnv.value = projectEnvironments?.value?.find(
      (env: DropDownDataType) => env.id === envId
    );
  };

  const setProjectDetails = (projectDetails: ProjectType) => {
    project.value = projectDetails;

    projectEnvironments.value = projectDetails?.project?.projectEnvironments?.map((env) => ({
      id: env.id,
      value: env.environment
    }));
  };

  const reset = () => {
    project.value = undefined;
    projectEnvironments.value = [];
    projectEnv.value = undefined;
  };

  return {
    project,
    projectEnv,
    projectEnvironments,
    setProjectDetails,
    setProjectEnvironment,
    reset
  };
});

export default useProjectStore;
