import useApi from '@/services/api';
import { ApiTypes } from '@/types/api';

const profileApis = {
  userLogin: () =>
    useApi({
      query: 'auth/login',
      method: ApiTypes.POST,
      isLazy: true
    }),
  userLogout: () =>
    useApi({
      query: 'auth/logout',
      method: ApiTypes.POST,
      isLazy: true
    }),
  forgotPassword: () =>
    useApi({
      query: 'auth/forgot-password',
      method: ApiTypes.POST,
      isLazy: true
    })
};

export const { userLogin, userLogout, forgotPassword } = profileApis;
