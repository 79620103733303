import type { DropDownDataType } from '@/types';

export const LOGIN_STATUS = 'loginStatus';

export enum ApiServiceType {
  DEFAULT = 'DEFAULT',
  CAMPAIGN = 'CAMPAIGN',
  CAMPAIGN_AI = 'CAMPAIGN_AI'
}

export enum ProjectEnvs {
  PRODUCTION = 'PRODUCTION',
  STAGING = 'STAGING',
  SANDBOX = 'SANDBOX'
}

export enum ModalTypes {
  WARNING = 'warning',
  ERROR = 'error',
  HIDE = 'hide',
  INFO = 'info',
  ALERT = 'alert'
}

export enum TemplateTypes {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  WHATSAPP = 'WHATSAPP',
  SLACK = 'SLACK',
  TEAMS = 'TEAMS',
  DISCORD = 'DISCORD',
  IN_APP = 'IN_APP',
  LINE = 'LINE'
}

export const defaultModalType = {
  show: false,
  type: ModalTypes.HIDE,
  title: ''
};

export enum NotificationTypes {
  INFO = 'INFO',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export enum ValidationErrors {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  IN_PROGRESS = 'IN_PROGRESS'
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
  UPDATED_AT = 'updatedAt',
  CREATED_ON = 'createdOn',
  MODIFIED_AT_ASC = 'modifiedAt',
  MODIFIED_AT_DESC = '-modifiedAt'
}

export enum FilterOperation {
  IN = 'in',
  NOT_IN = 'ni',
  EQUAL_TO = 'eq',
  NOT_EQUAL_TO = 'ne',
  LESS_THAN = 'lt',
  GREATER_THAN = 'gt',
  LESS_THAN_EQUAL_TO = 'le',
  GREATER_THAN_EQUAL_TO = 'ge',
  CONTAINS = 'like'
}

export enum ERROR_CODES {
  USER_EXISTS_BUT_NOT_VERIFIED = 'USER_EXISTS_BUT_NOT_VERIFIED',
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  USER_ALREADY_VERIFIED = 'USER_ALREADY_VERIFIED',
  USER_INVITE_ALREADY_ACCEPTED = 'USER_INVITE_ALREADY_ACCEPTED',
  NOT_FOUND = 'NOT_FOUND',
  RESOURCE_USED_IN_WORKFLOW = 'RESOURCE_USED_IN_WORKFLOW',
  RESOURCE_USED_IN_QUERIES = 'RESOURCE_USED_IN_QUERIES',
  RESOURCE_USED_IN_COHORT = 'RESOURCE_USED_IN_COHORT',
  RESOURCE_USED_IN_CAMPAIGN = 'RESOURCE_USED_IN_CAMPAIGN',
  VALIDATION_EXCEPTION = 'VALIDATION_EXCEPTION'
}

export enum FIELD_TYPES {
  TEXT = 'string',
  DROPDOWN = 'drop-down',
  LIST_OBJECT = 'list_object',
  INTEGER = 'integer',
  NUMBER = 'number'
}

export enum BUTTON_TYPES {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  BLUE = 'blue',
  LIGHT_BLUE = 'light-blue'
}

export enum VersionTypes {
  DRAFT = 'DRAFT',
  PUBLISHED_LATEST = 'PUBLISHED_LATEST',
  PUBLISHED = 'PUBLISHED'
}

export enum KeyPressTypes {
  ENTER = 'Enter',
  TAB = 'Tab'
}

export const ALERTS = {
  clearAlert: {
    show: false,
    isSuccess: false,
    message: ''
  },
  invalidJsonAlert: {
    show: true,
    isSuccess: false,
    message: 'invalid_json'
  },
  invalidFormatAlert: {
    show: true,
    isSuccess: false,
    message: 'invalid_format_json'
  },
  formattedJsonAlert: {
    show: true,
    isSuccess: true,
    message: 'formatted'
  },
  copiedJsonAlert: {
    show: true,
    isSuccess: true,
    message: 'copied'
  },
  executionFailedAlert: {
    show: true,
    isSuccess: false,
    message: 'execution_failed'
  },
  executionCompletedAlert: {
    show: true,
    isSuccess: true,
    message: 'execution_completed'
  }
};

export enum ERROR_STATUS_CODES {
  ERROR_401 = 401,
  ERROR_403 = 403,
  ERROR_404 = 404,
  ERROR_429 = 429
}

export const allowedFileUploadTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/gif'];

export enum TIME_UNIT {
  YEAR = 'year',
  MONTH = 'month',
  DAY = 'day',
  HOUR = 'hour',
  MINUTE = 'minute',
  SECOND = 'second'
}

export enum STATUS_COLOR {
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREY = 'GREY'
}

export enum STATUS_SIZE {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM'
}

// Paginiation count
export const USERS_PAGINATION_COUNT = 10;
export const COHORTS_PAGINATION_COUNT = 10;
export const CAMPAIGN_COHORTS_PAGINATION_COUNT = 51;
export const CAMPAIGNS_PAGINATION_COUNT = 10;
export const DATASOURCE_PAGINATION_COUNT = 8;
export const TEMPLATES_PAGINATION_COUNT = 10;
export const CAMPAIGN_TEMPLATES_PAGINATION_COUNT = 50;
export const WORKFLOW_PAGINATION_COUNT = 10;
export const LOGS_PAGINATION_COUNT = 10;
export const SCHEDULE_PAGINATION_COUNT = 10;
export const MIX_PANEL_COHORTS_PAGINATION_COUNT = 9;
export const QUERIES_PAGINATION_COUNT = 9;
export const ALL_QUERIES_PAGINATION_COUNT = 1000;
export const ALL_COHORTS_PAGINATION_COUNT = 100;
export const ALL_DATASOURCE_PAGINATION_COUNT = 1000;
export const CAMPAIGN_EXECUTION_DATA_PAGINATION_COUNT = 90;
export const FETCH_COHORTS_COUNT = 250;
export const USERS_PGINATION_COUNT = 10;
export const USERS_LIST_PAGINATION_COUNT = 10;
export const COHORTS_USERS_PAGINATION_COUNT = 9;

export const MAX_PAGE_NUMBER_VISIBLE = 9999;

export enum StepperType {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PENDING = 'pending'
}

export const stepperCountColor = {
  [StepperType.ACTIVE]: 'bg-primary_400',
  [StepperType.COMPLETED]: 'bg-success_400',
  [StepperType.PENDING]: 'bg-grey_400'
};

export const stepperTextColor = {
  [StepperType.ACTIVE]: 'text-grey_600',
  [StepperType.COMPLETED]: 'text-grey_500',
  [StepperType.PENDING]: 'text-grey_400'
};

export const emptySelectedDropdownValue: DropDownDataType = {
  id: '',
  value: ''
};

export const weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const BetaFeatures = ['campaigns'];

export const MIN_TITLE_LENGTH = 3;
export const MAX_TITLE_LENGTH = 70;

export enum FileTypes {
  TEXT_PLAIN = 'text/plain',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
  APPLICATION_PDF = 'application/pdf'
}

export enum GlobalTimeFrameType {
  WEEK = 'Last 7 Days',
  MONTH = 'Last 30 Days',
  CUSTOM = 'Custom'
}

export const GlobalTimeTabOptions = [
  { label: GlobalTimeFrameType.WEEK },
  { label: GlobalTimeFrameType.MONTH },
  { label: GlobalTimeFrameType.CUSTOM }
];
