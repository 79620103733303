import { ref, toRaw } from 'vue';
import { defineStore } from 'pinia';
import useProjectStore from './project';

import { getProfile } from '@/views/profile/api/api';
import type { ProfileData } from '@/types/profile';

const useProfileStore = defineStore('profile', () => {
  const { data: profileData, isError, makeCall: getProfileDetails } = getProfile();
  const profile = ref<ProfileData>();

  const setProfile = (payload: ProfileData) => {
    profile.value = payload;
  };

  const reset = () => {
    profile.value = undefined;
  };

  const getProfileData = async () => {
    const profileStore = useProfileStore();
    const projectStore = useProjectStore();

    await getProfileDetails();

    if (!isError.value) {
      const profile = toRaw(profileData.value.data);

      const { project, activeProjectEnvironmentId } = profile.userProjects[0] || {};
      let activeEnv = null;

      if (activeProjectEnvironmentId) activeEnv = activeProjectEnvironmentId;
      else if (project?.projectEnvironments?.length) activeEnv = project.projectEnvironments[0].id;

      profileStore.setProfile(profile);
      projectStore.setProjectDetails(profile.userProjects[0]);
      projectStore.setProjectEnvironment(activeEnv);
    }
  };

  return { profile, setProfile, reset, getProfileData };
});

export default useProfileStore;
