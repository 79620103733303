import { ref } from 'vue';
import { defineStore } from 'pinia';

import type { KeyType } from '@/types/apiKeys';

const useKeysStore = defineStore('keys', () => {
  const keysList = ref<KeyType[]>([]);

  const setKeysList = (data: KeyType[]) => {
    keysList.value = data;
  };

  const reset = () => {
    keysList.value = [];
  };

  return {
    keysList,
    setKeysList,
    reset
  };
});

export default useKeysStore;
