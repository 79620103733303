import {
  NUM_REGEX,
  CAP_LETTER_REGEX,
  SPECIAL_CHAR_REGEX,
  EIGHT_CHAR_REGEX
} from '@/constants/regex';

export enum LoginItemKeys {
  LOGIN = 'login',
  REGISTER = 'register',
  VERIFY_EMAIL = 'verify-email',
  FORGOT_PASSWORD = 'forgot-password'
}

export const passwrodStrengthFields = [
  {
    label: 'password_strength_field.one_number',
    regex: NUM_REGEX
  },
  {
    label: 'password_strength_field.one_capital_letter',
    regex: CAP_LETTER_REGEX
  },
  {
    label: 'password_strength_field.one_special_character',
    regex: SPECIAL_CHAR_REGEX
  },
  {
    label: 'password_strength_field.eight_characters',
    regex: EIGHT_CHAR_REGEX
  }
];

export const LOGIN_FIELDS = {
  USERNAME: 'username',
  PASSWORD: 'password'
};

export const SIGNUP_FIELDS = {
  EMAIL: 'email',
  PASSWORD: 'password',
  FULLNAME: 'name',
  ORGANIZATION_NAME: 'organizationName'
};

export enum EmailSentType {
  REGISTRATION = 'registration',
  RESET_PASSWORD = 'reset password'
}

export const enum AccountVerficationStates {
  LINK_EXPIRED = 'LINK_EXPIRED',
  VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS',
  RESEND_SUCCESS = 'RESEND_SUCCESS'
}
