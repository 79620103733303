import { RESTRICTED_SQL_OPS_REGEX, STRING_COMPARE_REPLACE_REGEX } from '@/constants/regex';
import type { StringMap } from '@/types';

export const isObject = (obj: any): boolean => Boolean(obj && typeof obj === 'object');

export const isArray = (obj: any): boolean => Boolean(isObject(obj) && obj instanceof Array);

export const isEmptyArray = (obj: any): boolean => Boolean(isArray(obj) && obj.length === 0);

export const isNonEmptyArray = (arr: any) => Array.isArray(arr) && arr.length > 0;

export const isNonEmptyObject = (obj: any) => obj && Object.keys(obj).length > 0;

export const isEmptyObject = (obj: any) => obj && Object.keys(obj).length === 0;

export const isNonEmptySet = (set: any) => set && set.size > 0;

export const isBoolean = (val: any): boolean => typeof val === 'boolean';

export const isNonEmptyString = (str: any) => typeof str === 'string' && str?.trim().length > 0;

export const isJsonStringEmptyArrayOrObject = (input: string): boolean => {
  const trimmedInput = input.trim();
  // Check if the trimmed input is one of the specified values

  if (trimmedInput === '' || trimmedInput === '[]' || trimmedInput === '{}') return false;

  return true;
};

export const isEmptyString = (str: any): boolean =>
  Boolean(typeof str === 'string' && str?.trim().length === 0);

export const getNonEmptyString = (data: any): string => {
  if (isNonEmptyString(data)) return data;

  return '';
};

export const isUndefinedOrNull = (value: any) => {
  if (value == null) return true;

  return false;
};

export const isUndefined = (value: any) => value === undefined;

export const hasEmptyFields = (object: StringMap) =>
  Object.values(object).some((field) => isEmptyString(field));

export const isAllFieldsEmptyInObject = (object: StringMap) =>
  Object.values(object).every((field) => !field || isEmptyString(field));

export const isValidJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

const replacer = (key: any, value: any) =>
  value instanceof Object && !(value instanceof Array)
    ? Object.keys(value)
        .sort()
        .reduce((sorted: StringMap, key: string) => {
          // eslint-disable-next-line no-param-reassign
          sorted[key] = value[key];

          return sorted;
        }, {})
    : value;

export const areJsonSame = (obj1: any, obj2: any, additionalReplace?: RegExp): boolean => {
  const jsonString1 = JSON.stringify(obj1, replacer);
  const jsonString2 = JSON.stringify(obj2, replacer);
  // Remove whitespace and line breaks from both strings for comparison
  let initialText = jsonString1?.replace(STRING_COMPARE_REPLACE_REGEX, '')?.replace('\\"', '"');
  let cleanedCurrentText = jsonString2
    ?.replace(STRING_COMPARE_REPLACE_REGEX, '')
    ?.replace('\\"', '"');

  if (additionalReplace) {
    initialText = initialText?.replace(additionalReplace, '');
    cleanedCurrentText = cleanedCurrentText?.replace(additionalReplace, '');
  }

  return initialText === cleanedCurrentText;
};

export const areStringsEqual = (oldVal: string, newVal: string): boolean => {
  const result = oldVal.trim() === newVal.trim();

  return result;
};

export const checkIfRestrictedSqlOperation = (sqlQuery: string) =>
  RESTRICTED_SQL_OPS_REGEX.test(sqlQuery);

// To check if two values are arrays and if they are equal
export const areArrayValuesEqual = (value1: any, value2: any) => {
  // Handle null, undefined and empty string as equivalent
  const normalize = (value: any) =>
    value === null || value === undefined || value === '' ? '' : value;
  const normalizedValue1 = normalize(value1);
  const normalizedValue2 = normalize(value2);

  if (Array.isArray(normalizedValue1) && Array.isArray(normalizedValue2))
    return (
      normalizedValue1.length === normalizedValue2.length &&
      value1.every((item: any, index: number) => item === normalizedValue2[index])
    );

  if (typeof normalizedValue1 === 'object' && typeof normalizedValue2 === 'object') {
    const keys1 = Object.keys(normalizedValue1);
    const keys2 = Object.keys(normalizedValue2);

    if (keys1.length !== keys2.length) return false;

    return keys1.every(
      (key) => key in normalizedValue2 && normalizedValue1[key] === normalizedValue2[key]
    );
  }

  return normalizedValue1 === normalizedValue2;
};

export const areObjectsEqual = (ob1: Record<string, any>, ob2: Record<string, any>) => {
  const ob2Keys = Object.keys(ob2);

  return ob2Keys.every((key) => key in ob1 && areArrayValuesEqual(ob1[key], ob2[key]));
};

export const hasValue = (fields: any[]) =>
  fields.some((value) => value !== undefined && value !== null && value?.trim() !== '');

export const areNestedObjectsEmpty = (obj: Record<string, any>) =>
  Object.values(obj).every((nestedObj) => isEmptyObject(nestedObj));
