<template>
  <div
    :style="{ backgroundColor: bgColor }"
    class="w-12 h-12 rounded-full flex justify-center items-center"
  >
    <div class="text-black text-[16px] font-semibold">{{ getInitials(name) }}</div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

import { getInitials, getRandomColor } from '@/utils/generic';

defineProps<{ name: string }>();
const bgColor = ref<string>('#CBE4F9');

onMounted(() => {
  const color = getRandomColor();

  bgColor.value = color;
});
</script>
