import useApi from '@/services/api';
import { ApiTypes } from '@/types/api';

const userApis = {
  getUsers: () =>
    useApi({
      query: 'organization/users',
      method: ApiTypes.GET,
      isLazy: true
    }),
  getRoles: () =>
    useApi({
      query: 'roles',
      method: ApiTypes.GET,
      isLazy: true
    }),
  inviteUser: () =>
    useApi({
      query: 'invitations/send-invitation',
      method: ApiTypes.POST,
      isLazy: true
    }),
  resendInvite: () =>
    useApi({
      query: 'invitations/resend-invitation',
      method: ApiTypes.POST,
      isLazy: true
    }),
  updateUser: () =>
    useApi({
      query: 'roles/assign-role',
      method: ApiTypes.POST,
      isLazy: true
    }),
  removeUser: () =>
    useApi({
      query: 'roles/remove-user',
      method: ApiTypes.POST,
      isLazy: true
    }),
  switchUserOrganization: () =>
    useApi({
      query: 'user/switch-organization',
      method: ApiTypes.POST,
      isLazy: true
    }),
  updateOrganizationFirstVisit: () =>
    useApi({
      query: 'user/update-organization-first-visit',
      method: ApiTypes.PATCH,
      isLazy: true
    }),
  updateProfileFirstVisit: () =>
    useApi({
      query: 'user/update-profile-first-visit',
      method: ApiTypes.PATCH,
      isLazy: true
    })
};

export const {
  getUsers,
  getRoles,
  inviteUser,
  resendInvite,
  updateUser,
  removeUser,
  switchUserOrganization,
  updateOrganizationFirstVisit,
  updateProfileFirstVisit
} = userApis;
