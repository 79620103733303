export const SIGNUP_ERRORS = {
  EMPTY_EMAIL: 'signup_errors.empty_email',
  INVALID_EMAIL: 'signup_errors.invalid_email',
  EMPTY_PASSWORD: 'signup_errors.empty_password',
  EMPTY_FULLNAME: 'signup_errors.empty_fullname',
  INVALID_FULLNAME: 'signup_errors.invalid_fullname',
  EMPTY_ORGANIZATION_NAME: 'signup_errors.empty_organization_name',
  INVALID_ORGANIZATION_NAME: 'signup_errors.invalid_organization_name',
  PASSWORD_MISMATCH: 'signup_errors.password_mismatch',
  PASSWORD_INVALID: 'signup_errors.password_invalid'
};

export const LOGIN_ERRORS = {
  INVALID_CREDENTIALS: 'login_errors.invalid_credentials',
  EMPTY_USERNAME: 'login_errors.empty_username',
  INVALID_EMAIL: 'login_errors.invalid_email',
  EMPTY_PASSWORD: 'login_errors.empty_password'
};

export const GENERIC_FIELD_ERRORS = {
  EMPTY_NAME: 'generic_field_errors.empty_name',
  SAME_NAME: 'generic_field_errors.same_name',
  EMPTY_FILE: 'generic_field_errors.empty_file'
};

export const WORKFLOW_FIELD_ERRORS = {
  WORKFLOW_INVALID_NAME: 'workflow_errors.workflow_name',
  WORKFLOW_INVALID_DESC: 'generic_errors.invalid_desc',
  WORKFLOW_TITLE_LIMIT: 'generic_errors.title_limit'
};

export const TEMPLATE_FIELD_ERRORS = {
  TEMPLATE_TITLE_LIMIT: 'template_errors.template_title_limit',
  TEMPLATE_INVALID_NAME: 'template_errors.template_name',
  VARIABLES: 'template_errors.variables',
  VARIABLES_SAVE: 'template_errors.variables_save',
  VARIABLE: 'template_errors.variable',
  EMAIL_SUBJECT: 'template_errors.email_subject',
  EMAIL_TEMPLATE: 'template_errors.email_template',
  INVALID_EMAIL: 'template_errors.invalid_email',
  PUSH_TITLE: 'template_errors.push_title',
  PUSH_MESSAGE: 'template_errors.push_message',
  PUSH_ADDITIONAL_DATA: 'template_errors.push_additional_data',
  PUSH_VALID_URL: 'template_errors.push_valid_url',
  PUSH_VALID_COLOR: 'template_errors.push_valid_color',
  PUSH_VALID_TTL: 'template_errors.push_valid_ttl',
  PUSH_VALID_BADGE: 'template_errors.push_valid_ttl',
  SMS_MESSAGE: 'template_errors.sms_message',
  SMS_VARIABLE: 'template_errors.variable',
  PUSH_DURATION: 'template_errors.push_duration',
  WHATSAPP_NAME: 'template_errors.whatsapp_name',
  WHATSAPP_PLACEHOLDER: 'template_errors.whatsapp_placeholder',
  WHATSAPP_URL: 'template_errors.whatsapp_url',
  WHATSAPP_FILENAME: 'template_errors.whatsapp_filename',
  SLACK_FILENAME: 'template_errors.slack_filename',
  SLACK_URL: 'template_errors.slack_url',
  SLACK_MESSAGE: 'template_errors.slack_message',
  MS_TEAMS_TYPE: 'template_errors.ms_teams_type',
  MS_TEAMS_URL: 'template_errors.ms_teams_url',
  MS_TEAMS_MESSAGE: 'template_errors.ms_teams_message',
  IN_APP_MESSAGE: 'template_errors.in_app_message',
  IN_APP_VALID_URL: 'template_errors.push_valid_url',
  IN_APP_ADDITIONAL_DATA: 'template_errors.in_app_additional_data',
  IN_APP_THUMBNAIL_URL: 'template_errors.in_app_thumbnail_url',
  DISCORD_MESSAGE: 'template_errors.discord_message',
  ATTACHMENT_CONTENT: 'template_errors.attachment_content',
  LINE_URL: 'template_errors.line_url'
};

export const CHANGE_PASSWORD_ERRORS = {
  EMPTY_CURRENT_PASSWORD: 'change_password_errors.empty_current_password',
  EMPTY_NEW_PASSWORD: 'change_password_errors.empty_new_password',
  EMPTY_CONFIRM_PASSWORD: 'change_password_errors.empty_confirm_password',
  PASSWORD_NOT_EQUAL: 'change_password_errors.password_not_equal',
  INVALID_PASSWORD: 'change_password_errors.invalid_password'
};

export const FORGOT_PASSWORD_ERRORS = {
  EMPTY_EMAIL: 'forgot_password_errors.empty_email',
  INVALID_EMAIL: 'forgot_password_errors.invalid_email'
};

export const COHORT_FIELD_ERRORS = {
  COHORT_INVALID_NAME: 'cohort_errors.cohort_name',
  COHORT_NO_DESC: 'generic_errors.no_desc',
  COHORT_INVALID_DESC: 'generic_errors.invalid_desc',
  COHORT_TITLE_LIMIT: 'generic_errors.title_limit',
  COHORT_NO_QUERY: 'cohort_errors.no_query_selected'
};

export const CAMPAIGN_FIELD_ERRORS = {
  CAMPAIGN_INVALID_NAME: 'campaign_errors.campaign_name',
  CAMPAIGN_TITLE_LIMIT: 'generic_errors.title_limit'
};

export const SCHEDULE_ERRORS = {
  SCHEDULE_NAME_ERROR: 'schedule_name_error',
  EMPTY_FREQUENCY: 'frequency_mandatory',
  EMPTY_WORKFLOW: 'workflow_mandatory',
  EMPTY_START_DATE: 'start_date_mandatory',
  EMPTY_TIMEZONE: 'timeZone_mandatory',
  EMPTY_SCHEDULE_TIME: 'schedule_time_mandatory',
  EMPTY_END_DATE: 'end_date_mandatory',
  EMPTY_DAY: 'day_mandatory',
  EMPTY_CRON_REGEX: 'cron_regex_mandatory'
};

export const USER_ERRORS = {
  FIRST_NAME_REQUIRED_ERROR: 'user_name_required_error',
  USER_NAME_ERROR: 'user_name_error',
  REFERENCE_ID_REQUIRED_ERROR: 'reference_required_error',
  INVALID_PHONE_ERROR: 'invalid_phone_error',
  INVALID_EMAIL: 'invalid_email',
  CHANNEL_ERROR: 'channel_error',
  KEY_REQUIRED: 'key_required',
  VALUE_REQUIRED: 'value_required',
  KEY_ALREADY_EXISTS: 'key_already_exists',
  INVALID_PROPERTY_NAME: 'invalid_property_name'
};
