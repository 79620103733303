import { ref } from 'vue';
import { defineStore } from 'pinia';

import { getGroupedProviders } from '@/utils/providers/providers';
import { defaultModalType } from '@/constants/common';
import { getFormattedTemplates } from '@/utils/templates/template';
import type { TemplateType } from '@/types/templates';
import type { ProviderAccount, ProviderIntegrations, ProviderType } from '@/types/provider';
import type { ActionModalType, Map } from '@/types';
import type {
  EdgeStatusListType,
  StatusListType,
  WorkflowDataType,
  WorkflowTemplateType,
  WorkflowVersion
} from '@/types/workflow';

const useWorkflowStore = defineStore('workflow', () => {
  const recommendedTemplates = ref<WorkflowTemplateType[]>([]);
  const excludedTemplates = ref<WorkflowTemplateType[]>([]);
  const providers = ref();
  const providerIconMap = ref();
  const providerIntegrations = ref<ProviderIntegrations | undefined>();
  const showActionModal = ref<ActionModalType>(defaultModalType);
  const nodesStatus = ref<StatusListType>();
  const edgesStatus = ref<EdgeStatusListType>();
  const selectedErrorNodeId = ref<string>();
  const issueCount = ref<number>(0);
  const isWorkflowExecuting = ref<boolean>(false);
  const isEditMode = ref<boolean>(true);
  const isDebugMode = ref<boolean>(false);
  const workflowDetails = ref<WorkflowDataType>();
  const workflowTriggerData = ref<string | null>();
  const isTemplateLoading = ref<boolean>(false);
  const selectedVersion = ref<WorkflowVersion | null>();
  const testCode = ref<string>();
  const workflowList = ref<WorkflowDataType[]>([]);
  const forceUpdate = ref<boolean>(true);
  const selectedSwitchCase = ref<string>();
  const isSampleTriggerDataLoading = ref<boolean>();
  const selectNodeId = ref<string>('');
  const nodeMap = ref<Map>({});

  // Workflow Undo/Redo
  const graph = ref<any>(null);
  const workflowHistory = ref<string[]>([]);
  const pointer = ref<number>(-1);

  const setWorkflowList = (data: WorkflowDataType[]) => {
    workflowList.value = data;
  };

  const setEdgesStatus = (errorList: EdgeStatusListType | {}) => {
    edgesStatus.value = errorList;
  };

  const setIssueCount = (count: number) => {
    issueCount.value = count;
  };

  const setWorkflowExecuting = (isRunning: boolean) => {
    isWorkflowExecuting.value = isRunning;
  };

  const setDebugmode = (isDebug: boolean) => {
    isDebugMode.value = isDebug;
  };

  const setWorkflowDetails = (workflow: WorkflowDataType) => {
    workflowDetails.value = workflow;
  };

  const setTriggerDataLoading = (isLoading: boolean) => {
    isSampleTriggerDataLoading.value = isLoading;
  };

  const setWorkflowSampleTriggerData = (sampleTriggerData: string) => {
    workflowTriggerData.value = sampleTriggerData;
  };

  const setSelectedVersion = (version: WorkflowVersion | null) => {
    selectedVersion.value = version;
  };

  const setRecommendedTemplates = (templateList: TemplateType[]) => {
    recommendedTemplates.value = getFormattedTemplates(templateList);
  };

  const setExcludedTemplates = (templateList: TemplateType[]) => {
    excludedTemplates.value = getFormattedTemplates(templateList);
  };

  const setTemplateLoading = (isLoading: boolean) => {
    isTemplateLoading.value = isLoading;
  };

  const setProviders = (providerList: ProviderType[]) => {
    providers.value = providerList.map((provider: ProviderType) => ({
      id: provider.id,
      value: provider.name,
      channel: provider.channel,
      supportedWebhookEvents: provider.supportedWebhookEvents,
      code: provider.code
    }));

    let iconMap = {};

    providerList.forEach((provider) => {
      iconMap = { ...iconMap, [provider.code]: provider.logoUrl };
    });

    providerIconMap.value = iconMap;
  };

  const setProviderIntegrations = (
    providerList: ProviderType[],
    providerIntegrationList: ProviderAccount[]
  ) => {
    if (providerList?.length && providerIntegrationList?.length)
      providerIntegrations.value = getGroupedProviders(providerList, providerIntegrationList);
  };

  const setShowActionModal = (payload: ActionModalType) => {
    showActionModal.value = payload;
  };

  const setEditMode = (mode: boolean) => {
    isEditMode.value = mode;
  };

  const setSelectedErrorNodeId = (nodeId: string) => {
    selectedErrorNodeId.value = nodeId;
  };

  const setNodesStatus = (errorList: StatusListType | {}) => {
    nodesStatus.value = errorList;
  };

  const setTestCode = (code: string) => {
    testCode.value = code;
  };

  const setSelectedSwitchCase = (selectedCase: string) => {
    selectedSwitchCase.value = selectedCase;
  };

  // Workflow Undo/Redo
  const setGraph = (data: any) => {
    forceUpdate.value = false;
    let copyHistory = [...workflowHistory.value];
    const nextPointer = pointer.value + 1;

    // Clearing next redo states if new changes comes after undo
    if (copyHistory[nextPointer]) copyHistory = copyHistory.slice(0, nextPointer);

    graph.value = data;
    workflowHistory.value = [...copyHistory, JSON.stringify(data)];
    pointer.value = nextPointer;
  };

  const undoWorkflow = () => {
    if (pointer.value > 0) {
      forceUpdate.value = true;
      const currentPointer = pointer.value - 1;

      graph.value = JSON.parse(workflowHistory.value[currentPointer]);
      pointer.value = currentPointer;
    }
  };

  const redoWorkflow = () => {
    if (pointer.value < workflowHistory.value.length - 1) {
      forceUpdate.value = true;
      const currentPointer = pointer.value + 1;

      graph.value = JSON.parse(workflowHistory.value[currentPointer]);
      pointer.value = currentPointer;
    }
  };

  const setNodeMap = (data: Map) => {
    nodeMap.value = data;
  };

  const clearGraph = () => {
    graph.value = null;
  };

  const clearWorkflowHistory = () => {
    workflowHistory.value = [];
    pointer.value = -1;
  };

  const setSelectNodeId = (id: string) => {
    selectNodeId.value = id;
  };

  const reset = () => {
    recommendedTemplates.value = [];
    excludedTemplates.value = [];
    providers.value = undefined;
    providerIconMap.value = undefined;
    providerIntegrations.value = undefined;
    showActionModal.value = defaultModalType;
    nodesStatus.value = undefined;
    edgesStatus.value = undefined;
    selectedErrorNodeId.value = undefined;
    issueCount.value = 0;
    isWorkflowExecuting.value = false;
    isEditMode.value = true;
    workflowDetails.value = undefined;
    selectedVersion.value = undefined;
    testCode.value = undefined;
    workflowList.value = [];
    forceUpdate.value = true;
    selectedSwitchCase.value = undefined;
    selectNodeId.value = '';
    nodeMap.value = {};
  };

  return {
    nodesStatus,
    recommendedTemplates,
    excludedTemplates,
    providers,
    providerIntegrations,
    providerIconMap,
    showActionModal,
    selectedErrorNodeId,
    isEditMode,
    issueCount,
    edgesStatus,
    isWorkflowExecuting,
    isDebugMode,
    workflowDetails,
    selectedVersion,
    testCode,
    workflowList,
    graph,
    workflowHistory,
    pointer,
    forceUpdate,
    selectedSwitchCase,
    workflowTriggerData,
    isSampleTriggerDataLoading,
    selectNodeId,
    isTemplateLoading,
    nodeMap,

    setWorkflowList,
    setNodesStatus,
    setEdgesStatus,
    setIssueCount,
    setRecommendedTemplates,
    setExcludedTemplates,
    setProviders,
    setShowActionModal,
    setProviderIntegrations,
    setEditMode,
    setSelectedErrorNodeId,
    setWorkflowExecuting,
    setDebugmode,
    setWorkflowDetails,
    setSelectedVersion,
    setTestCode,
    setGraph,
    clearWorkflowHistory,
    clearGraph,
    undoWorkflow,
    redoWorkflow,
    reset,
    setSelectedSwitchCase,
    setWorkflowSampleTriggerData,
    setTriggerDataLoading,
    setSelectNodeId,
    setTemplateLoading,
    setNodeMap
  };
});

export default useWorkflowStore;
