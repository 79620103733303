<template>
  <div class="video-container relative group w-full h-full rounded-xl overflow-hidden">
    <video
      @ended="handleVideoEnded"
      class="rounded-xl w-full h-full"
      ref="videoPlayer"
      playsinline
      autoplay
      muted
    >
      <source :src="source.webm" type="video/webm" />
      <source :src="source.mp4" type="video/mp4" />
    </video>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const videoPlayer = ref<HTMLVideoElement | null>(null);

const props = defineProps<{
  source: { webm: any; mp4: any };
  onVideoEnded: () => void;
}>();

const handleVideoEnded = () => {
  videoPlayer.value?.play();
  props.onVideoEnded();
};
</script>
