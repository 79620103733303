import Dashboard from '@/assets/icons/sidebar/Dashboard.vue';
import Templates from '@/assets/icons/sidebar/Templates.vue';
import Workflow from '@/assets/icons/sidebar/Workflow.vue';
import Logs from '@/assets/icons/sidebar/Logs.vue';
import Users from '@/assets/icons/sidebar/Users.vue';
import Settings from '@/assets/icons/sidebar/Settings.vue';
import Campaigns from '@/assets/icons/sidebar/Campaigns.vue';

import { Permissions } from './permissions';

export enum ROUTES {
  DEFAULT = '/',
  LOGIN = '/login',
  SIGNUP = '/signup',
  WELCOME = '/welcome',
  HOME = '/home',
  PROVIDERS = '/providers',
  PROVIDER_INTEGRATIONS = '/providers/:id/integrations',
  CONFIGURATION = '/configuration',
  WORKFLOWS = '/workflows',
  WORKFLOW_CREATE = '/workflows/:id',
  WORKFLOW_CLONE_SUCCESS = '/workflow-clone-success',
  WORKFLOW_CLONE_FAIL = '/workflow-clone-fail',
  TEAMS = '/team',
  TEMPLATES = '/templates',
  TEMPLATES_EDIT = '/templates/:id/edit',
  LOGS = '/logs',
  VERIFY_ACCOUNT = '/verify',
  PASSWORD_RESET = '/password-reset',
  PASSWORD_RESET_SUCCESS = '/password-reset-success',
  DASHBOARD = '/dashboard',
  SCHEDULE = '/schedules',
  SCHEDULE_EDIT = '/schedules/:id',
  PROFILE = '/profile',
  VERIFY_INVITE = '/verify-invite',
  CONTACT_ADMIN = '/contact-admin',
  ERROR_FALLBACK = '/error',
  DATA_SOURCE = '/data-source',
  DATA_SOURCE_EDIT = '/data-source/:id',
  QUERIES_EDIT = '/data-source/:id/queries/:queryId',
  COHORTS = '/cohorts',
  COHORTS_EDIT = '/cohorts/:id/edit',
  CAMPAIGNS = '/campaigns',
  CAMPAIGNS_PREVIEW = '/campaigns/:id',
  CAMPAIGNS_EDIT = '/campaigns/:id/edit',
  PAGE_NOT_FOUND = '/page-not-found',
  INVALD_URL = '/:pathMatch(.*)*',
  USERS = '/users',
  USER_DETAIL = '/users/:id',
  USER_ADD = '/users/add',
  USER_EDIT = '/users/:id/edit',
  SEGMENT_DETAIL = '/segment/:id',
  CREATE_COHORT = '/create-cohort'
}

export interface NavType {
  label: string;
  path: ROUTES;
  icon?: any;
  access: string[];
  subRoutes?: NavType[];
  childRoutes?: string[];
  hasFeatureFlag?: boolean;
}

export enum HEADER_TYPES {
  WORKFLOWS = 'workflow',
  PROVIDERS = 'providers',
  TEMPLATES = 'templates'
}

export const headerNavs: NavType[] = [
  {
    label: 'dashboard',
    path: ROUTES.DASHBOARD,
    icon: Dashboard,
    access: [Permissions.DASHBOARD_PAGE_VIEW]
  },
  {
    label: 'templates',
    path: ROUTES.TEMPLATES,
    icon: Templates,
    childRoutes: [ROUTES.TEMPLATES_EDIT],
    access: [Permissions.TEMPLATES_PAGE_VIEW]
  },
  {
    label: 'workflows',
    path: ROUTES.WORKFLOWS,
    icon: Workflow,
    subRoutes: [
      {
        label: 'my_workflows',
        path: ROUTES.WORKFLOWS,
        childRoutes: [ROUTES.WORKFLOW_CREATE],
        access: [Permissions.WORKFLOWS_PAGE_VIEW]
      },
      {
        label: 'schedules',
        path: ROUTES.SCHEDULE,
        childRoutes: [ROUTES.SCHEDULE_EDIT],
        access: [Permissions.SCHEDULES_PAGE_VIEW]
      }
    ],
    childRoutes: [ROUTES.WORKFLOW_CREATE, ROUTES.SCHEDULE_EDIT, ROUTES.SCHEDULE],
    access: [Permissions.WORKFLOWS_PAGE_VIEW]
  },
  {
    label: 'campaigns',
    path: ROUTES.CAMPAIGNS,
    icon: Campaigns,
    subRoutes: [
      {
        label: 'my_campaigns',
        path: ROUTES.CAMPAIGNS,
        childRoutes: [ROUTES.CAMPAIGNS_EDIT, ROUTES.CAMPAIGNS_PREVIEW],
        access: [Permissions.CAMPAIGNS_PAGE_VIEW]
      },
      {
        label: 'cohorts',
        path: ROUTES.COHORTS,
        childRoutes: [ROUTES.COHORTS_EDIT],
        access: [Permissions.COHORTS_PAGE_VIEW]
      }
    ],
    childRoutes: [
      ROUTES.CAMPAIGNS_EDIT,
      ROUTES.CAMPAIGNS_PREVIEW,
      ROUTES.COHORTS,
      ROUTES.COHORTS_EDIT
    ],
    access: [Permissions.CAMPAIGNS_PAGE_VIEW],
    hasFeatureFlag: true
  },
  {
    label: 'users',
    path: ROUTES.USERS,
    icon: Users,
    access: [Permissions.USER_PAGE_VIEW]
  },
  {
    label: 'logs',
    path: ROUTES.LOGS,
    icon: Logs,
    access: [Permissions.LOGS_PAGE_VIEW]
  },
  {
    label: 'settings',
    path: ROUTES.CONFIGURATION,
    icon: Settings,
    subRoutes: [
      {
        label: 'providers',
        path: ROUTES.PROVIDERS,
        childRoutes: [ROUTES.PROVIDER_INTEGRATIONS],
        access: [Permissions.PROVIDERS_PAGE_VIEW]
      },
      {
        label: 'datasources',
        path: ROUTES.DATA_SOURCE,
        childRoutes: [ROUTES.DATA_SOURCE],
        access: [Permissions.DATASOURCES_PAGE_VIEW]
      },
      {
        label: 'configurations',
        path: ROUTES.CONFIGURATION,
        icon: Settings,
        access: [Permissions.KEYS_PAGE_VIEW, Permissions.WEBHOOK_CONFIG_PAGE_VIEW]
      }
    ],
    childRoutes: [
      ROUTES.PROVIDER_INTEGRATIONS,
      ROUTES.PROVIDERS,
      ROUTES.DATA_SOURCE,
      ROUTES.DATA_SOURCE_EDIT
    ],
    access: [
      Permissions.KEYS_PAGE_VIEW,
      Permissions.WEBHOOK_CONFIG_PAGE_VIEW,
      Permissions.DATASOURCES_PAGE_VIEW,
      Permissions.PROVIDERS_PAGE_VIEW
    ]
  }
];

export const PUBLIC_ENDPOINTS = [
  ROUTES.LOGIN,
  ROUTES.SIGNUP,
  ROUTES.VERIFY_ACCOUNT,
  ROUTES.VERIFY_INVITE,
  ROUTES.PASSWORD_RESET,
  ROUTES.PASSWORD_RESET_SUCCESS
];
