import { ref } from 'vue';
import { defineStore } from 'pinia';

import type { ProviderAccount, ProviderGroups, ProviderType } from '@/types/provider';
import type { Tab } from '@/types';
import { getProviders } from '@/utils/providers/providers';

const useProviderStore = defineStore('provider', () => {
  const providers = ref<ProviderType[]>([]);
  const providerGroups = ref<ProviderGroups>();
  const providerTabs = ref<Tab[]>([]);

  const setProviders = (data: ProviderType[]) => {
    providers.value = data;
  };

  const setProviderGroups = (providerInegrations: ProviderAccount[]) => {
    providerGroups.value = getProviders(providers.value, providerInegrations);
    providerTabs.value = Object.keys(providerGroups.value).map((group, index) => ({
      index,
      label: group
    }));
  };

  const reset = () => {
    providers.value = [];
    providerGroups.value = undefined;
    providerTabs.value = [];
  };

  return {
    providers,
    providerGroups,
    providerTabs,
    setProviders,
    setProviderGroups,
    reset
  };
});

export default useProviderStore;
