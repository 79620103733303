<template>
  <div v-if="isLoading" class="h-screen"><Loader /></div>
  <div v-else-if="!isLoading && isLoggedIn" class="h-screen bg-white_1 pt-[57px]">
    <Header />
    <SideBar />

    <div class="h-[calc(100vh-57px)] relative overflow-auto ml-[82px] bg-grey_50">
      <router-view v-if="hasAccessToRoute" />
      <AccessDenied v-else-if="!isUndefinedOrNull(profileStore.profile)" />
    </div>
    <Toast v-if="!notifier?.notification?.isInline" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

import Header from '@/components/Header.vue';
import Toast from '@/components/Toast.vue';
import SideBar from '@/components/SideBar.vue';
import Loader from '@/components/Loader.vue';
import AccessDenied from '@/views/access-denied/AccessDenied.vue';
import useProfileStore from '@/stores/profile';
import useNotifierStore from '@/stores/notifier';
import usePermissions from '@/composables/usePermissions';
import { isUndefinedOrNull } from '@/utils/checks';
import { getProfile } from '@/views/profile/api/api';

const { isLoading } = getProfile();

const route = useRoute();
const profileStore = useProfileStore();
const notifier = useNotifierStore();

const { hasPermission } = usePermissions();

const isLoggedIn = ref<boolean>(false);
const hasAccessToRoute = ref<boolean>(false);

const setAccessToRoute = () => {
  let hasAccess = true;

  if (route.meta?.requiresAuth) {
    const availablePermissions = (route.meta?.permission as string[]) ?? [''];

    hasAccess = availablePermissions?.some((permission) => hasPermission(permission));
  }

  hasAccessToRoute.value = hasAccess;
};

watch(
  () => [route.meta, profileStore.profile?.permissions],
  () => {
    setAccessToRoute();
  },
  { deep: true }
);

onMounted(() => {
  setAccessToRoute();
});

watchEffect(() => {
  if (profileStore.profile) isLoggedIn.value = true;
});
</script>
