<template>
  <div class="h-full w-full flex items-center justify-center flex-col">
    <img src="@/assets/icons/access-denied.svg" class="w-[300px] h-[320px]" alt="access_denied" />
    <div class="text-center pt-4">
      <div class="text-grey_900 text-2xl font-semibold">{{ $t('something_wrong_expression') }}</div>
      <div class="text-grey_900 text-xl font-semibold">{{ $t('access_issues.no_access') }}</div>
    </div>

    <div class="text-grey_400 text-base text-center pt-2">
      {{ $t('access_issues.request_access') }}
    </div>
  </div>
</template>
