import { ref } from 'vue';
import { defineStore } from 'pinia';

const useLogsStore = defineStore('logs', () => {
  const isLogsDataInvalidated = ref(false);

  const setLogsDataInvalidated = (value: boolean) => {
    isLogsDataInvalidated.value = value;
  };

  const reset = () => {
    isLogsDataInvalidated.value = false;
  };

  return {
    isLogsDataInvalidated,
    setLogsDataInvalidated,
    reset
  };
});

export default useLogsStore;
