import DashboardWebM from '@/assets/videos/webm/dashboard.webm';
import ProvidersWebM from '@/assets/videos/webm/providers.webm';
import TemplatesWebM from '@/assets/videos/webm/templates.webm';
import WorkflowsWebM from '@/assets/videos/webm/workflows.webm';
import DashboardMp4 from '@/assets/videos/mp4/dashboard.mp4';
import ProvidersMp4 from '@/assets/videos/mp4/providers.mp4';
import TemplatesMp4 from '@/assets/videos/mp4/templates.mp4';
import WorkflowsMp4 from '@/assets/videos/mp4/workflows.mp4';

export interface OnBoardingData {
  id: string;
  video: {
    webm: any;
    mp4: any;
  };
  title: string;
  description: string;
}

export const ONBOARDING_DATA: OnBoardingData[] = [
  {
    id: 'dashboard',
    video: {
      webm: DashboardWebM,
      mp4: DashboardMp4
    },
    title: 'app_tour.title1',
    description: 'app_tour.description1'
  },
  {
    id: 'providers',
    video: {
      webm: ProvidersWebM,
      mp4: ProvidersMp4
    },
    title: 'app_tour.title2',
    description: 'app_tour.description2'
  },
  {
    id: 'templates',
    video: {
      webm: TemplatesWebM,
      mp4: TemplatesMp4
    },
    title: 'app_tour.title3',
    description: 'app_tour.description3'
  },
  {
    id: 'workflows',
    video: {
      webm: WorkflowsWebM,
      mp4: WorkflowsMp4
    },
    title: 'app_tour.title4',
    description: 'app_tour.description4'
  }
];
