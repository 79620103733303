import RootCirtificate from '@/assets/icons/datasources/rootcertificate.svg';
import ClientCirtificate from '@/assets/icons/datasources/clientcertificate.svg';
import ClientKey from '@/assets/icons/datasources/clientkey.svg';
import PostgreSQL from '@/assets/icons/datasources/postgres.svg';
import MySQL from '@/assets/icons/datasources/mysql.svg';
import MongoDB from '@/assets/icons/datasources/mongodb.svg';
import Microsoft from '@/assets/icons/datasources/microsoft.svg';

import type { DropDownDataType, Tab } from '@/types';
import type {
  DataBaseCredentials,
  IntegrationCredentials,
  dataSourceListType,
  documentTypesType
} from '@/types/datasource';
import { STATUS_COLOR } from './common';

export enum DBConnectionStatusType {
  SUCCESS = 'SUCCESS',
  UNVERIFIED = 'UNVERIFIED',
  FAILURE = 'FAILURE'
}

export const SCHEDULE_STATUS_COLORS = {
  [DBConnectionStatusType.SUCCESS]: STATUS_COLOR.GREEN,
  [DBConnectionStatusType.FAILURE]: STATUS_COLOR.RED,
  [DBConnectionStatusType.UNVERIFIED]: STATUS_COLOR.GREY
};

export enum sslFieldTypes {
  SSL_MODE = 'sslmode',
  SSL_ROOT = 'rootCirtificate',
  SSL_CLIENT = 'clientCertificate',
  SSL_KEY = 'clientKey'
}

export const documentTypesData: documentTypesType[] = [
  {
    id: 'rootCirtificate',
    label: 'SSL root certificate',
    icon: RootCirtificate,
    file: null
  },
  {
    id: 'clientCertificate',
    label: 'SSL client certificate',
    icon: ClientCirtificate,
    file: null
  },
  {
    id: 'clientKey',
    label: 'SSL client key',
    icon: ClientKey,
    file: null
  }
];

export const sslData = [
  { id: 'disable', value: 'Disable' },
  { id: 'allow', value: 'Allow' },
  { id: 'prefer', value: 'Prefer' },
  { id: 'require', value: 'Require' },
  { id: 'verify-ca', value: 'Verify CA' },
  { id: 'verify-full', value: 'Verify Full' }
];

export const dataSourceTabs: Tab[] = [
  { id: 'DATABASE', index: 0, label: 'DATABASES' },
  { id: 'INTEGRATION', index: 1, label: 'INTEGRATIONS' }
];

export const dataSources: DropDownDataType[] = [
  { id: 'DATABASE', value: 'DATABASES' },
  { id: 'CDAP', value: 'INTEGRATIONS' }
];

export const dataBaseList: dataSourceListType[] = [
  {
    id: 'postgres',
    label: 'PostgreSQL',
    isactive: true,
    logo: PostgreSQL,
    accountsCount: 2
  },
  {
    id: 'mysql',
    label: 'MySQL',
    isactive: false,
    logo: MySQL,
    accountsCount: 0
  },
  {
    id: 'mongodb',
    label: 'MongoDB',
    isactive: false,
    logo: MongoDB,
    accountsCount: 0
  },
  {
    id: 'microsoft',
    label: 'Microsoft SQL Server',
    isactive: false,
    logo: Microsoft,
    accountsCount: 0
  }
];

export const dataSourceConnectionDefaultValues: DataBaseCredentials = {
  dbname: '',
  host: '',
  password: '',
  port: '',
  user: '',
  sslmode: sslData[0].id,
  sslrootcertFile: '',
  sslcertFile: '',
  sslkeyFile: ''
};

export const integrationDefaultValues: IntegrationCredentials = {
  projectId: '',
  username: '',
  password: '',
  server: ''
};

export enum dataSourceActions {
  EDIT = 'edit',
  DELETE = 'delete'
}

export const segmentDropDownActions = [
  {
    id: dataSourceActions.EDIT,
    value: 'edit'
  }
];

export const dataSourceDropDownActions = [
  {
    id: dataSourceActions.EDIT,
    value: 'edit'
  },
  {
    id: dataSourceActions.DELETE,
    value: 'delete',
    customStyle: 'text-rose_400'
  }
];

export enum dataSourceTypes {
  DATABASE = 'DATABASE',
  CDAP = 'CDAP',
  SEGMENTS = 'SEGMENT'
}

export const dataSourceQueryMap = {
  [dataSourceTypes.DATABASE]: 'database',
  [dataSourceTypes.CDAP]: 'integration',
  [dataSourceTypes.SEGMENTS]: 'segment'
};

export const userFields = [
  { id: 'referenceId', value: 'referenceId' },
  { id: 'firstName', value: 'firstName' },
  { id: 'lastName', value: 'lastName' },
  { id: 'sms', value: 'sms' },
  { id: 'email', value: 'email' },
  { id: 'pushToken', value: 'pushToken' },
  { id: 'whatsapp', value: 'whatsapp' },
  { id: 'slack', value: 'slack' },
  { id: 'teams', value: 'teams' },
  { id: 'inApp', value: 'inApp' },
  { id: 'discord', value: 'discord' },
  { id: 'line', value: 'line' }
];

export enum DataSourceDropDownKeys {
  EDIT = 'edit'
}

export const DataSourceDropDownData = [
  {
    id: DataSourceDropDownKeys.EDIT,
    value: 'edit'
  }
];
