/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
export const EMAIL =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PINCODE = /^[1-9][0-9]{5}$/;
export const PHONE = /^(\+\d{1,3}[- ]?)?\d{8,13}$/;
export const ALLPASS = /.*/;
export const NUMBER_INPUT_REGEX = /^[0-9\b]+$/;
export const NON_NUMBER_INPUT_REGEX = /[^0-9]/g;

export const NUM_REGEX = /\d/;
export const CAP_LETTER_REGEX = /[A-Z]/;
export const SPECIAL_CHAR_REGEX = /.*[!@#$%^&*()\-_=+\[\]{};:'",.<>\/?`~\\|].*/;
export const EIGHT_CHAR_REGEX = /^.{8,}$/;
export const ESCAPE_CHAR_REGEX = /\\[a-z,A-Z,0-9]/g;

export const ATTACHMENT_CONTENT_REGEX = /(?<={{).+(?=}})/;
export const INTERPOLATION_FORMAT_REGEX = /\$\{[^}]+\}/;
export const SWITCH_INPUT_PARAM_REGEX = /\${(.*?)}/;
export const WORKFLOW_TEMPLATE_NAME_REGEX =
  /^[\w-]*[a-zA-Z0-9][\w-]*[a-zA-Z0-9][\w-]*[a-zA-Z0-9][\w-]*$/;

export const PLACEHOLDER_REGEX = /^(\{\{\{[^{}]*\}\}\}|\{\{[^{}]*\}\})$/;
export const JSON_PLACEHOLDER_REGEX = /^\{\{\{\s*[^{}]+\s*\}\}\}$/;
export const ZINDEX_SELECTED_REGEX =
  /,"z":\d+|,"zIndex":\d+|,"selected":(true|false)|(?:,|)"animated":(true|false)(?:,|)/g;

export const URL_REGEX =
  /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

export const URL_PROTOCOL_REGEX = /(^\w+:|^)\/\//;

export const VARIABLE_REGEX = /{{([^{}]*)}}/g;
export const TEMPLATE_VARIABLE = /^[a-zA-Z_$][a-zA-Z0-9_$]*$/;
export const EMPTY_SPACE_REGEX = /\s/g;
export const COMMA_SEPARATED_EMAIL =
  /^(([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,})(\s*,\s*[\w+-.%]+@[\w-.]+\.[A-Za-z]{2,})*)$/;
export const COLOR_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
export const WAIT_VARIABLE_REGEX = /^\$$|^\$\{.*/;
export const DATE_TIME_REGEX = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
export const DHMS_REGEX_REGEX = /(\d+)d(\d+)h(\d+)m(\d+)s/;
export const NAME_REGEX = /^[a-zA-Z\s]+$/;
export const ZERO_WIDTH_SPACE_REGEX = /\u200B/g;

export const WEBHOOK_CONFIG_HEADER_KEY_REGEX = /^[a-zA-Z0-9_-]+$/;
export const WEBHOOK_CONFIG_HEADER_VALUE_REGEX =
  /^[a-zA-Z0-9_ :;.,\/\\'"?!(){}\[\]@<>=\-+*#$&`|~^%]+$/;

export const STRING_COMPARE_REPLACE_REGEX = /\\t|\\n/g;

export const TITLE_NAME_REGEX = /^[\w-]*[a-zA-Z0-9][\w-]*[a-zA-Z0-9][\w-]*[a-zA-Z0-9][\w-]*$/;
export const TITLE_NAME_SPACE_REGEX = /^[a-zA-Z0-9][\w\s-]+[a-zA-Z0-9]$/;

export const RESTRICTED_SQL_OPS_REGEX =
  /\b(DELETE\s+FROM|DROP\s+TABLE|UPDATE\s+\w+\s+(SET|WHERE\s+.*?)|ALTER\s+TABLE|INSERT\s+INTO|TRUNCATE|CREATE\s+(TABLE|INDEX|VIEW|FUNCTION|PROCEDURE|TRIGGER)|GRANT|REVOKE|MERGE|EXECUTE|LOCK\s+TABLE|CALL)\b/i;

export const ALPHA_NUMERIC_START_END_REGEX = /^[a-zA-Z0-9][\w-]+[a-zA-Z0-9]$/;
export const ORGANIZATION_REGEX = /^\w[\w.\-#@_&\s]*$/;
export const CODE_EDITOR_REGEX = /[\w${}]*/;
export const CURLY_BRACE_REGEX = /{{|}}/g;
export const PHONE_NUMBER_REGEX = /^\+\d+$/;

export const EDIT_CAMPAIGN_URL_REGEX = /^\/campaigns\/[^\/]+\/edit$/;
export const CONTAINS_DOLLAR_SIGN = /\$/g;
