import { TIME_UNIT, monthNames } from '@/constants/common';

export const getDDMonthYYYYFormat = (date: Date) => {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.toLocaleString('default', { day: '2-digit' });

  // Generate dd mmm yyyy date string
  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
};

export const getDDMonthYYFormat = (date: Date) => {
  const year = date.toLocaleString('default', { year: '2-digit' });
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.toLocaleString('default', { day: '2-digit' });

  // Generate dd mmm yy date string
  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
};

export const getDDMonthYYYYdayFormat = (date: Date) => {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.toLocaleString('default', { day: '2-digit' });
  const weekday = date.toLocaleString('default', { weekday: 'short' });

  // Generate dd mmm yyyy date string
  const formattedDate = `${day} ${month} ${year}, ${weekday}`;

  return formattedDate;
};

export const getddMMMFormat = (date: Date) => {
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.toLocaleString('default', { day: '2-digit' });

  // Generate dd mmm date string
  const formattedDate = `${day} ${month}`;

  return formattedDate;
};

export const getTimeDifferenceFromNow = (dateString: string) => {
  const currentDate: number = +new Date();
  const givenDate: number = +new Date(dateString);
  const seconds: number = +Math.floor((givenDate - currentDate) / 1000);

  const intervals = [
    { label: TIME_UNIT.YEAR, seconds: 31536000 },
    { label: TIME_UNIT.MONTH, seconds: 2592000 },
    { label: TIME_UNIT.DAY, seconds: 86400 },
    { label: TIME_UNIT.HOUR, seconds: 3600 },
    { label: TIME_UNIT.MINUTE, seconds: 60 },
    { label: TIME_UNIT.SECOND, seconds: 1 }
  ];

  const totalTimeDiff: {
    isFutureDate: boolean;
    timeDiff: { count: number; unit: TIME_UNIT }[];
    formattedTimeDiff: string;
  } = {
    isFutureDate: seconds > 0,
    timeDiff: [],
    formattedTimeDiff: ''
  };

  intervals.forEach((interval: { label: TIME_UNIT; seconds: number }) => {
    let count = 0;

    if (!totalTimeDiff.timeDiff.length) {
      count = Math.floor(Math.abs(seconds) / interval.seconds);
    } else {
      let remainingTime = Math.abs(seconds);

      totalTimeDiff.timeDiff.forEach((time) => {
        remainingTime -=
          time.count * (intervals.find((item) => item.label === time.unit)?.seconds || 1);
      });
      count = Math.floor(remainingTime / interval.seconds);
    }

    if (count !== 0) totalTimeDiff.timeDiff.push({ count, unit: interval.label });
  });

  let remainingTimeFromNow = '';

  totalTimeDiff.timeDiff.forEach((time, index) => {
    if (index < 2)
      remainingTimeFromNow = `${remainingTimeFromNow}${remainingTimeFromNow ? ', ' : ''}${
        time.count
      } ${time.unit}s`;
  });
  totalTimeDiff.formattedTimeDiff = remainingTimeFromNow;

  return totalTimeDiff;
};

export const getDDMMYYYYFormat = (date: Date) => {
  const formattedDate = date.toLocaleDateString('en-GB');

  return formattedDate;
};

export const getDateFromDDMMYYYYFormat = (dateString: string) => {
  const dateParts = dateString.split('/');
  const date = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);

  return date;
};

export const getYYYYMMDDFormat = (date: Date) => {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const day = date.toLocaleString('default', { day: '2-digit' });

  // Generate yyyy-mm-dd date string
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const getYYYYMMDDHHMMFormat = (date: Date) => {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const formattedTimeString = `${hours}:${minutes}`;
  const formattedDateString = getYYYYMMDDFormat(date);

  // Generate yyyy-mm-dd hh:mm date string
  const formattedDate = `${formattedDateString} ${formattedTimeString}`;

  return formattedDate;
};

export const getDateObjectFromYYYYMMDDHHMMString = (dateString: string) => {
  const [datePart, timePart] = dateString.split(' ');
  const [year, month, day] = datePart.split('-').map(Number);
  const [hour, minute] = timePart.split(':').map(Number);

  let dateObject;

  try {
    dateObject = new Date(year, month - 1, day, hour, minute);
  } catch {
    return null;
  }

  return dateObject;
};

export const extractDateAndTime = (timestamp: string) => {
  if (!timestamp) return { formattedDate: '', formattedTime: '' };
  const dateObj = new Date(timestamp);

  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');

  const hours = String(dateObj.getHours() % 12 || 12).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');
  const meridiem = dateObj.getHours() >= 12 ? 'PM' : 'AM';

  const formattedDate = `${day}-${month}-${year}`;
  const formattedTime = `${hours}:${minutes}:${seconds} ${meridiem}`;

  return { formattedDate, formattedTime };
};

export const getDateAndTimeString = (timestamp: string) => {
  if (!timestamp) return '';
  const date = new Date(timestamp);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');

  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? '12' : String(hours % 12);

  const formattedDate = `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;

  return formattedDate;
};

export const formatDateUsingIntlDateFormatter = (date: Date, options: Intl.DateTimeFormatOptions) =>
  new Intl.DateTimeFormat('en-GB', options).format(date);

export const formatUTCDateToTimeZoneUTC = (utcDateString: string) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcDate = new Date(utcDateString);
  const userOffset = userTimeZone === 'UTC' ? 0 : new Date().getTimezoneOffset();

  utcDate.setUTCMinutes(utcDate.getUTCMinutes() + userOffset);

  return utcDate.toISOString();
};

export const formatUTCDateToTimeZone = (utcDateString: string) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcDate = new Date(utcDateString);
  const userOffset = userTimeZone === 'UTC' ? 0 : new Date().getTimezoneOffset();

  utcDate.setUTCMinutes(utcDate.getUTCMinutes() + userOffset);

  return utcDate.toISOString();
};

export const getDaysInDateRange = (
  initialDate: Date,
  finalDate: Date,
  includeFinalDate: boolean
): number => {
  const millisecondsPerDay = 1000 * 3600 * 24;
  const initialTimestamp = initialDate.getTime();
  const finalTimestamp = finalDate.getTime();

  const numberOfDays = Math.floor((finalTimestamp - initialTimestamp) / millisecondsPerDay);

  return includeFinalDate ? numberOfDays + 1 : numberOfDays;
};

export const checkIfSameDay = (date1: Date, date2: Date) =>
  date1.getDate() === date2.getDate() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getFullYear() === date2.getFullYear();

export const getTime = (date: { hours: string; minutes: string; seconds: string }) =>
  `${date?.hours?.toString()?.padStart(2, '0') ?? '00'}:${
    date?.minutes?.toString()?.padStart(2, '0') ?? '00'
  }:${date?.seconds?.toString()?.padStart(2, '0') ?? '00'}`;

export const formatDateToDDMMMYYYY = (dateString: string) => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const formattedDate = `${day} ${monthNames[month]} ${year}`;

  return formattedDate;
};

export const getMinDateInTimeZone = (timeZone?: string) => {
  const currentDate = new Date();

  if (timeZone) {
    const currentTimeInSelectedTimeZone = currentDate.toLocaleString('en-US', {
      timeZone
    });
    const minDate = new Date(currentTimeInSelectedTimeZone);

    return minDate;
  }

  return currentDate;
};
