<template>
  <button
    :class="buttonClasses"
    @click="(evt) => handleClick(evt)"
    @keydown="onKeyDownPress"
    type="button"
  >
    <div
      :class="`btn-loader ${type !== ButtonType.PRIMARY && 'btn-loader-blue'}`"
      v-if="isLoading"
    />
    <slot />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { ButtonType, SizeType } from '@/constants/components';

// Emit click event
const emit = defineEmits(['click']);

// CustomButtonProps interface
interface CustomButtonProps {
  type?: ButtonType;
  size?: SizeType;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
  onKeyDownPress?: (e: KeyboardEvent) => void;
}

// Default props
const props = withDefaults(defineProps<CustomButtonProps>(), {
  type: ButtonType.PRIMARY,
  size: SizeType.MEDIUM,
  disabled: false,
  className: '',
  isLoading: false,
  onKeyDownPress: () => null
});

// Computed property for button classes
const buttonClasses = computed(() => {
  const baseClasses = `rounded-lg focus:outline-none transition-colors duration-300 w-full flex gap-2 items-center justify-center font-semibold ${props.className}`;

  return [
    baseClasses,
    {
      'bg-primary_500 hover:bg-primary_400 text-white': props.type === ButtonType.PRIMARY,
      '!bg-grey_300':
        (props.type === ButtonType.PRIMARY || props.type === ButtonType.SUCCESS) && props.disabled,
      'text-grey_700 border border-grey_300 hover:border-grey_100 bg-white hover:bg-grey_50':
        props.type === ButtonType.SECONDARY,
      '!border-grey_300 !bg-white opacity-50':
        props.type === ButtonType.SECONDARY && props.disabled,
      'text-primary_500 hover:opacity-70': props.type === ButtonType.TEXT_PRIMARY,
      'text-grey_500 hover:opacity-70': props.type === ButtonType.TEXT_SECONDARY,
      '!opacity-50':
        (props.type === ButtonType.TEXT_SECONDARY || props.type === ButtonType.TEXT_PRIMARY) &&
        props.disabled,
      'cursor-not-allowed': props.disabled,
      'bg-success_600 hover:bg-success_400 text-white': props.type === ButtonType.SUCCESS
    },
    {
      'text-sm px-4 py-2': props.size === SizeType.SMALL,
      'text-[15px] leading-6 px-5 py-2.5': props.size === SizeType.MEDIUM,
      'text-base font-semibold px-6 py-4': props.size === SizeType.LARGE
    }
  ];
});

const handleClick = (evt?: MouseEvent) => {
  evt?.stopPropagation();

  if (!props.disabled && !props.isLoading) emit('click');
};
</script>
