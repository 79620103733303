<template>
  <div
    v-show="open"
    tabindex="-1"
    aria-hidden="true"
    class="fixed top-0 left-0 right-0 z-[10000] w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full bg-[#1f1f306b]"
  >
    <div class="flex w-full h-full justify-center items-center">
      <div
        class="bg-white rounded-3xl modal-container"
        :class="{
          'w-[55vh] h-[55vh] min-w-[495px] min-h-[495px]': showGettingStarted && !isTutorial,
          'w-[130vh] h-[87vh] min-h-[734px]': !showGettingStarted || isTutorial
        }"
      >
        <GettingStarted
          v-if="showGettingStarted && !isTutorial"
          @next="handleGettingStartedPress"
          @close="$emit('close')"
        />
        <OnBoarding v-else @close="$emit('close')" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-container {
  transition: width 0.5s, height 0.89s cubic-bezier(0.075, 0.82, 0.165, 1);
}
</style>

<script setup lang="ts">
import { ref } from 'vue';

import OnBoarding from './OnBoarding.vue';
import GettingStarted from './GettingStarted.vue';

defineProps<{
  open: boolean;
  isTutorial?: boolean;
}>();

defineEmits(['close']);

const showGettingStarted = ref(true);

const handleGettingStartedPress = () => {
  showGettingStarted.value = false;
};
</script>
