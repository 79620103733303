import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { Map } from '@/types';

const useFilterStore = defineStore('filters', () => {
  const filters = ref<Map>({});
  const searchParam = ref<string>('');

  const setFilters = (data: Map) => {
    filters.value = data;
  };

  const setSearchParam = (data: string) => {
    searchParam.value = data;
  };

  const clearFilters = () => {
    filters.value = {};
    searchParam.value = '';
  };

  return {
    filters,
    searchParam,
    setFilters,
    setSearchParam,
    clearFilters
  };
});

export default useFilterStore;
