import { FIELD_TYPES } from '@/constants/common';
import i18n from '@/i18n/config';
import {
  type ProviderType,
  type ProviderAccount,
  type ProviderGroups,
  type ProviderConfiguration,
  type AccountConfiguration
} from '@/types/provider';

import type { StringMap } from '@/types';

import getProviderAccounts from './providerAccounts';
import { checkRegexMatch } from '../regexCheck';

const getProviders = (providers: Array<ProviderType>, accountDetails: Array<ProviderAccount>) => {
  const groupedProviders: ProviderGroups = {};

  providers.forEach(({ channelGroup, channel, ...provider }) => {
    if (channelGroup && channel) {
      const accounts = getProviderAccounts(accountDetails, channel, provider.id);

      const providerObj = { ...provider, channelGroup, accounts };

      if (!groupedProviders[channelGroup]) groupedProviders[channelGroup] = [];

      groupedProviders[channelGroup].push(providerObj);
    }
  });

  return groupedProviders;
};

const handleProviderUrlClick = (url: string) => {
  window.open(`https://${url}`, '_blank');
};

const getGroupedProviders = (
  providers: Array<ProviderType>,
  accountDetails: Array<ProviderAccount>
) => {
  const groupedProviders: any = {};

  providers.forEach(({ channel, ...provider }) => {
    if (channel) {
      const accounts = getProviderAccounts(accountDetails, channel, provider.id);

      if (!groupedProviders[channel]) groupedProviders[channel] = {};

      groupedProviders[channel] = { ...groupedProviders[channel], [provider.name]: accounts };
    }
  });

  return groupedProviders;
};

const getInitialFieldValues = (configuration: ProviderConfiguration) => {
  if (configuration.type === FIELD_TYPES.LIST_OBJECT && configuration.listElementFields?.length) {
    let intialFieldValue = {};

    configuration.listElementFields.forEach((listElementField) => {
      intialFieldValue = {
        ...intialFieldValue,
        [listElementField.fieldName]: ''
      };
    });

    return [intialFieldValue];
  }

  return '';
};

const getFieldErrors = (
  providerConfigurations: ProviderConfiguration[],
  accountConfig: AccountConfiguration
) => {
  let errors = {};

  providerConfigurations.forEach((field) => {
    const accountConfigElement = accountConfig[field.fieldName];

    if (field.isAutoPopulated) return;

    if (
      field.listElementFields?.length &&
      Object.keys(accountConfig).includes(field.fieldName) &&
      Array.isArray(accountConfigElement)
    ) {
      let hasError = false;
      const emptyErrorsArray = new Array(accountConfigElement.length).fill({}).map(() => {
        const emptyErrorsObject: StringMap = {};

        field.listElementFields?.forEach((listElementField) => {
          emptyErrorsObject[listElementField.fieldName] = '';
        });

        return emptyErrorsObject;
      });
      const errorsArray = emptyErrorsArray;

      field.listElementFields?.forEach((listElementField) => {
        accountConfigElement.forEach((listElement, index) => {
          const element = listElement[listElementField.fieldName];
          const isString = typeof element === 'string';
          const { isMandatory } = listElementField;
          const { validationRegex } = listElementField;

          if (isString && isMandatory && !element.trim()) {
            errorsArray[index][listElementField.fieldName] = `${
              listElementField.displayName
            } ${i18n.global.t('is_mandatory')}`;
            hasError = true;
          } else if (isString && validationRegex && element.trim()) {
            if (!checkRegexMatch(new RegExp(validationRegex), element.trim())) {
              errorsArray[index][listElementField.fieldName] =
                listElementField?.validationMessage || '';
              hasError = true;
            }
          }
        });
      });

      if (hasError)
        errors = {
          ...errors,
          [field.fieldName]: errorsArray
        };
    } else if (
      field.isMandatory &&
      typeof accountConfigElement === 'string' &&
      !accountConfigElement?.trim()
    ) {
      errors = {
        ...errors,
        [field.fieldName]: field.displayName + i18n.global.t('is_mandatory')
      };
    } else if (
      typeof accountConfigElement === 'string' &&
      accountConfigElement?.trim() &&
      field.validationRegex &&
      !checkRegexMatch(new RegExp(field.validationRegex), accountConfigElement.trim())
    ) {
      errors = {
        ...errors,
        [field.fieldName]: field?.validationMessage
      };
    }
  });

  return errors;
};

export {
  getGroupedProviders,
  getProviders,
  getInitialFieldValues,
  getFieldErrors,
  handleProviderUrlClick
};
