<template>
  <header
    class="header bg-white_1 h-[57px] flex items-center px-[38px] fixed top-0 z-50 border-b border-border-blue_2 border-t-[3px] border-t-primary_500"
    :class="hideMenu ? 'w-full' : 'left-[77px] w-[calc(100vw-77px)]'"
  >
    <div class="mr-auto text-blue_primary tracking-[2px] font-semibold text-[22px]">
      {{ $t('siren') }}
    </div>
    <div class="ml-auto flex gap-1">
      <DropDown
        :isOpen="isDropDownOpen"
        :handleOpen="handleDropDown"
        dropDownClass="!mt-10"
        :dropDownData="projectStore.projectEnvironments"
      >
        <template #input>
          <button v-if="!hideMenu && isUserInvited" type="button" @click="handleDropDown">
            <div
              class="py-1 px-3 mt-1 text-sm text-neutral-600 flex justify-between min-w-[150px] items-center"
            >
              <div class="flex items-center justify-start mr-2">
                <div :class="`rounded-2xl w-[10px] h-[10px] mr-2 ${envDropDownColor}`" />
                {{ convertTextToTitleCase(projectStore.projectEnv?.value || '') }}
              </div>
              <Arrow :isOpen="isDropDownOpen" />
            </div>
          </button>
        </template>
        <template #list>
          <li
            v-for="option in projectStore.projectEnvironments"
            :key="option.id"
            class="cursor-pointer p-3 text-sm leading-4 text-grey_800 hover:bg-grey_75 flex items-center justify-between text-left"
            :class="`${option.customStyle} ${
              projectStore.projectEnv?.value === option.value && '!bg-grey_100'
            }`"
            @click="handleDropDownItemSelect(option.id)"
            @keydown="handleDropDownItemSelect(option.id)"
          >
            <div class="flex items-center">
              <div :class="`rounded-2xl w-[10px] h-[10px] mr-2 ${getEnvColor(option.value)}`" />
              {{ convertTextToTitleCase(option.value) }}
            </div>
          </li>
        </template>
      </DropDown>
      <ProfileMenu :hideMenu="hideMenu" />
    </div>
  </header>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import ProfileMenu from '@/views/profile/components/ProfileMenu.vue';
import useProjectStore from '@/stores/project';
import DropDown from '@/components/DropDown.vue';
import Arrow from '@/components/Arrow.vue';
import usePermissions from '@/composables/usePermissions';
import { updateEnvironment } from '@/views/profile/api/api';
import { convertTextToTitleCase, getEnvColor } from '@/utils/generic';

defineProps<{
  hideMenu?: boolean;
}>();

const route = useRoute();
const { isUserInvited } = usePermissions();
const isDropDownOpen = ref<boolean>(false);

const projectStore = useProjectStore();
const { error, makeCall: updateEnv } = updateEnvironment();

const envDropDownColor = computed(() => getEnvColor(projectStore.projectEnv?.value || ''));

const handleDropDown = () => {
  isDropDownOpen.value = !isDropDownOpen.value;
};

const handleSelectEnvironment = async (envId: string) => {
  handleDropDown();

  await updateEnv({
    apiPayload: { activeProjectEnvironmentId: envId },
    urlVariables: {
      userProjectId: projectStore?.project?.id ?? ''
    }
  });

  if (error.value || projectStore.projectEnv?.id === envId) return;

  const path = route.path.split('/')[1];
  const url = `${window.location.origin}/${path}`;

  window.location.replace(url);
};

const handleDropDownItemSelect = (option: string) => {
  handleSelectEnvironment(option);
  handleDropDown();
};
</script>
