import type { Ref } from 'vue';
import type { AxiosProgressEvent } from 'axios';
import type { ApiServiceType } from '@/constants/common';

export enum ApiTypes {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH'
}

export enum ApiVersions {
  V1 = 'v1',
  V2 = 'v2'
}
export type MakeCallType = {
  apiPayload?: Record<string, unknown> | Blob | null;
  params?: Record<string, unknown> | null;
  queryString?: string | null;
  urlVariables?: Record<string, unknown> | null;
  customUrl?: string;
  handleProgress?: (progressEvent: AxiosProgressEvent) => void;
};

export type ApiPayloadType = {
  query?: string;
  method?: ApiTypes;
  apiVersion?: ApiVersions;
  payload?: Record<string, unknown> | null;
  isLazy?: boolean;
  params?: Record<string, unknown> | null;
  queryString?: string | null;
  customBaseUrl?: string;
  service?: ApiServiceType;
};

export interface ErrorType {
  errorCode: string;
  message: string;
}
export interface ApiResponseType {
  data: Record<string, any>;
  meta: Record<string, any>;
  error: Record<string, any>;
  isLoading: Ref<boolean>;
  isError: Ref<boolean>;
  makeCall: (params?: MakeCallType) => Promise<void>;
}

export interface ApiErrorResponseType {
  data: Record<string, any>;
  meta: Record<string, any>;
  error: ErrorType;
}

export enum NetworkErrorTypes {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  USER_NOT_AUTHORIZED = 'USER_NOT_AUTHORIZED',
  INSUFFICIENT_PERMISSION = 'INSUFFICIENT_PERMISSION',
  INVALID_TOKEN = 'INVALID_TOKEN',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED'
}
