import '@/assets/css/main.css';
import '@/assets/css/override.css';
import '@/assets/css/fonts.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import VueApexCharts from 'vue3-apexcharts';
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';

import App from './App.vue';
import router from './router';
import i18n from './i18n/config';
import { ROUTES } from './constants/path';

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(i18n);
app.use(VueApexCharts);
app.use(VueTippy, {
  defaultProps: {
    placement: 'auto-end'
  }
});

app.config.errorHandler = (...err) => {
  // eslint-disable-next-line no-console
  console.log(err);
  router.push(ROUTES.ERROR_FALLBACK);
};

app.mount('#app');
