import { useRoute } from 'vue-router';
import i18n from '@/i18n/config';

import { ProjectEnvs } from '@/constants/common';
import { FILTER_CHIP_ATTRIBUTES, FILTER_LIST_ATTRIBUTES } from '@/constants/logs';
import type { ChipAttributes, ChipListAttributes, FilterOption, Map } from '@/types';

// NB: type of argument function cannot be determined
export const debounce = (func: (args: any) => void, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>;

  return function callBack(this: any, ...args: any) {
    const context = this;

    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};

export const copyToClipboard = (data: string) => {
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(data);
  } else {
    const textArea = document.createElement('textarea');

    textArea.value = data;

    textArea.style.position = 'absolute';
    textArea.style.left = '-999999px';

    document.body.prepend(textArea);
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (error) {
      console.error(error);
    } finally {
      textArea.remove();
    }
  }
};

export const handleDocsUrlClick = (url: string) => {
  window.open(`https://docs.trysiren.io/${url}`, '_blank');
};

export const handleExternalUrlClick = (url: string) => {
  window.open(url, '_blank');
};

export const getLetterFromNumber = (input: number) => String.fromCharCode(65 + input);

export const formatJson = (value: string, space: number) => {
  try {
    const formattedValue = JSON.stringify(JSON.parse(value), null, space);

    return formattedValue;
  } catch (error) {
    return '';
  }
};

export const getRandomNumberInRange = (lowerLimit: number, upperLimit: number) =>
  Math.floor(Math.random() * (upperLimit - lowerLimit + 1)) + lowerLimit;

export const convertTextToTitleCase = (text: string) => {
  const uppercaseFirstCharacter = text[0].toUpperCase();
  const lowercaseRestOfText = text.slice(1).toLowerCase();
  const convertedText = uppercaseFirstCharacter + lowercaseRestOfText;

  return convertedText;
};

export const getPercentageChange = (
  currentValue: number,
  previousValue: number,
  decimalPlaces: number = 0
): number => {
  if (previousValue !== 0) {
    const percentageChange = ((currentValue - previousValue) * 100) / previousValue;

    return parseFloat(percentageChange.toFixed(decimalPlaces));
  }

  if (previousValue === 0 && currentValue === 0) return 0;

  return 100;
};

export const calculatePercentage = (value: number, total: number) => {
  if (value === 0 || total === 0) return 0;

  return Number(((value / total) * 100).toFixed(2));
};

export const getInitials = (name: string) => {
  let result = '';

  if (name && name !== '') {
    let subNames = name.split(' ');

    if (subNames?.length > 2) {
      subNames = subNames.slice(0, 2);
    } else if (subNames?.length === 1) {
      subNames = subNames[0]
        .split('')
        .filter((character, index) => index === 0 || character === character.toUpperCase());
      if (subNames?.length !== 2) subNames = name.split('').slice(0, 2);
    }

    result = subNames.map((subName) => subName[0].toUpperCase())?.join('');
  }

  return result;
};

export const getRandomColor = () => {
  const colorsArray = ['#CBE4F9', '#CDF5F6', '#EFF9DA', '#D6CDEA', '#F9D8D6', '#EBFFE4'];
  const randomIndex = Math.floor(Math.random() * colorsArray.length);

  return colorsArray[randomIndex];
};

export const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getEnvColor = (env: string) => {
  if (env === ProjectEnvs.PRODUCTION) return 'bg-success_300';
  if (env === ProjectEnvs.STAGING) return 'bg-primary_300';

  return 'bg-secondary_300';
};

export const flattenObject = (obj: Map, parentKey = '') => {
  let flattenedObject = {};

  Object.keys(obj).forEach((key) => {
    const newKey = parentKey ? `${parentKey}_${key}` : key;

    if (typeof obj[key] === 'object' && obj[key] !== null)
      Object.assign(flattenedObject, flattenObject(obj[key], newKey));
    else flattenedObject = { ...flattenedObject, [newKey]: obj[key] };
  });

  return flattenedObject;
};

export const getUnionOfKeys = (obj1: Map, obj2: Map) => {
  const combinedObject = { ...(obj1 ?? {}), ...(obj2 ?? {}) };

  return Object.keys(combinedObject);
};

export const getClonedObject = (sampleObject: Map) => JSON.parse(JSON.stringify(sampleObject));

// Generate a array of numbers with lemgth 'count' starting from 'start'.
export const sequenceGenerator = (start: number, count: number, step = 1) =>
  Array.from({ length: count }, (_, i) => start + i * step);

export const getVisibleChipCount = (
  availableWidth: number,
  chips: string[],
  chipAttributes: ChipAttributes,
  chipListAttributes: ChipListAttributes
) => {
  const widthArray = chips.map(
    (chip) =>
      chip.length * chipAttributes.averageCharacterSize +
      (chipAttributes.gap || 0) +
      (chipAttributes.iconWidth || 0) +
      chipAttributes.padding
  );

  const totalWidth =
    widthArray && !(widthArray.length > 0)
      ? 0
      : widthArray.reduce((totalWidth, width) => totalWidth + width);

  let visibleChipCount: number = 0;

  if (totalWidth + widthArray.length * chipListAttributes.gap < availableWidth) {
    // Checking if all the filters can be displayed in the available width
    visibleChipCount = chips.length;
  } else {
    // Calculating the number of filters that can be visible in the available width
    let sum = chipListAttributes.trailingTextSize;

    if (sum > availableWidth) visibleChipCount = 0;
    else
      for (let i = 0; i < widthArray.length; i++) {
        sum += widthArray[i] + chipListAttributes.gap;
        if (sum > availableWidth) {
          visibleChipCount = i;
          break;
        }
      }
  }

  return visibleChipCount;
};

export const getFilterArrays = (availableWidth: number, filters: Array<FilterOption>) => {
  /**
   * FILTER_CHIP_ATTRIBUTES and FILTER_LIST_ATTRIBUTES are
   * component attributes for width calculation
   */

  const filterChips = filters.map((filter) => filter.name);

  const visibleChipCount = getVisibleChipCount(
    availableWidth,
    filterChips,
    FILTER_CHIP_ATTRIBUTES,
    FILTER_LIST_ATTRIBUTES
  );

  const visibleFilters = filters.slice(0, visibleChipCount);
  const hiddenFilters = filters.slice(visibleChipCount);

  return { visibleFilters, hiddenFilters };
};

export const getCurrentPage = () => {
  const route = useRoute();

  return route.query?.page ? parseInt(route.query.page as string, 10) : 1;
};

export const getCurrentSearchParam = () => {
  const route = useRoute();

  return (route.query?.search as string) || '';
};

export const updateUrlWithQueryParams = (
  queryParams: { field: string; value: string; defaultValue?: string }[]
) => {
  const url = new URL(window.location.href);

  queryParams.forEach((queryParam) => {
    const { field, value, defaultValue } = queryParam;

    if (!value || value === defaultValue) url.searchParams.delete(field);
    else url.searchParams.set(field, value);
  });

  const newState = {
    ...window.history.state,
    current: `${url.pathname}${url.search}`
  };

  window.history.replaceState(newState, '', url);
};

export const updateUrlWithPageAndSearch = (page?: number | null, search?: string | null) => {
  updateUrlWithQueryParams([
    { field: 'page', value: page?.toString() || '', defaultValue: '1' },
    { field: 'search', value: search || '' }
  ]);
};

export const exportAndDownloadCSV = (data: string) => {
  const url = URL.createObjectURL(
    new Blob([data], {
      type: 'application/vnd.ms-excel'
    })
  );
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', i18n.global.t('export_filename'));
  document.body.appendChild(link);
  link.click();
};

export const getCommaSeparatedString = (arr: Array<Map>, key: string) =>
  arr.map((item) => item[key]).join(',');

export const isVisibleRight = (
  dropdownRef: HTMLElement | null,
  menuRef: HTMLElement | null,
  parentWidth: number,
  defaultMenuWidth: number = 0
) => {
  if (dropdownRef) {
    const parentRef = dropdownRef.getBoundingClientRect();

    if (parentWidth - parentRef.right > (menuRef?.clientWidth || defaultMenuWidth)) return true;

    return false;
  }

  return false;
};

export const constructUrl = (baseUrl: string, replacements: Map) => {
  let url = baseUrl;

  Object.entries(replacements).forEach(([key, value]) => {
    url = url.replace(`:${key}`, value);
  });

  return url;
};

export const convertBytesToMB = (size: number) => size / (1024 * 1024);

export const convertFileToText = (file: File, callback: (textFile: string) => void) => {
  const reader = new FileReader();
  let textFile = '';

  if (file) {
    reader.addEventListener(
      'load',
      () => {
        textFile = reader.result as string;

        callback(textFile);
      },
      false
    );

    if (file) reader.readAsText(file);
  }
};

export const convertArrayToObject = (arr: { keyName: string; value: any }[]) =>
  arr.reduce((acc: Record<string, any>, curr) => {
    acc[curr.keyName] = curr.value;

    return acc;
  }, {});

export const trimObjectValues = (obj: Map): Map => {
  const result: Map = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'string' && value !== null) result[key] = value?.trim();
    else result[key] = value;
  });

  return result;
};

export const getSuccessRate = (actualValue: number, targetValue: number) => {
  if (targetValue === 0) return 0;

  return (actualValue / targetValue) * 100;
};

export const getAvatarLetters = (name: string) => {
  if (!name) return '';

  const nameParts = name.trim().split(' ');

  if (nameParts.length === 1) return nameParts[0].substring(0, 2).toUpperCase();

  const firstLetter = nameParts[0][0].toUpperCase();
  const lastLetter = nameParts[nameParts.length - 1][0].toUpperCase();

  return firstLetter + lastLetter;
};

export const countObjectKeys = (record: Record<string, any>) => Object.keys(record)?.length;

export const countNonEmptyValues = (data: Record<string, any>) =>
  Object.values(data).filter((value) => value !== '' && value !== null && value !== undefined)
    .length;

export const replaceSelectedText = (text: string, newText: string, start: number, end: number) => {
  const before = text.slice(0, start);
  const after = text.slice(end);
  const replacedString = `${before}${newText}${after}`;

  return replacedString;
};

export const getSelection = (id: string) => {
  const textarea = document.getElementById(id) as HTMLInputElement;
  let start = 0;
  let end = 0;
  let selectedText = '';

  if (textarea) {
    start = textarea.selectionStart || 0;
    end = textarea.selectionEnd || 0;
    selectedText = textarea.value.substring(start, end);
  }

  return { start, end, selectedText };
};

export const convertToTitleCase = (str: string) =>
  str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const normalize = (value: any) =>
  value === null || value === undefined || value === '' ? '' : value;
