<template>
  <div class="w-[20px] h-[20px]">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3333 6.66665V13.3333M9.99992 9.16665V13.3333M6.66659 11.6666V13.3333M4.99992 16.6666H14.9999C15.9204 16.6666 16.6666 15.9205 16.6666 15V4.99998C16.6666 4.07951 15.9204 3.33331 14.9999 3.33331H4.99992C4.07944 3.33331 3.33325 4.07951 3.33325 4.99998V15C3.33325 15.9205 4.07944 16.6666 4.99992 16.6666Z"
        stroke="#101928"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
