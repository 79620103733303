const colors = {
  primary_1: '#3F80F6',
  primary_2: '#679DFF',
  primary_3: '#445DFF',
  primary_4: 'rgba(63,128,246,0.2)',
  white_2: '#F5F5F5',
  white_3: 'rgba(255, 255, 255, 0.9)',
  white_4: 'rgba(255, 255, 255, 0.8)',
  grey_6: '#9F9F9F',
  grey_7: '#9A9A9A',
  grey_8: '#595959',
  grey_9: '#3C3C3C',
  GREY_10: '#DDDDF3',
  black: '#000000',
  blue_selection_1: '#2C3048',
  blue_card_1: '#323F53',
  dark_mode_1: '#1D1D1D',
  dark_mode_2: '#242424',
  dark_mode_3: '#474747',
  dark_mode_4: '#282828',
  dark_mode_5: '#161616',
  rose_400: '#FB7185',
  rose_600: '#E11D48',
  amber_400: '#F59E0B',
  teal_400: '#2DD4BF',

  // New colors
  white_1: '#FFFFFF',
  grey_1: '#FBFBFB',
  grey_2: '#EDEDEE',
  grey_3: '#687886',
  grey_4: '#515165',
  grey_5: '#d9d9d9',
  blue_1: '#EFF0FD',
  blue_2: '#616CEF',
  blue_3: '#252B41',
  blue_4: '#22245D',
  blue_primary: '#1E34F4',
  green_2: '#19AC43',
  VIOLET_1: '#5C5CB9',

  // Revamp Colors
  // -------------
  // Color Palette - Primary
  primary_50: '#FFECE5',
  primary_75: '#FCD2C2',
  primary_100: '#FCB59A',
  primary_200: '#FA9874',
  primary_300: '#F77A4A',
  primary_400: '#F56630',
  primary_500: '#EB5017',
  primary_600: '#CC400C',
  primary_700: '#AD3307',
  primary_800: '#8F2802',
  primary_900: '#711E00',

  // Color Palette - Secondary
  secondary_50: '#E3EFFC',
  secondary_75: '#C6DDF7',
  secondary_100: '#B6D8FF',
  secondary_200: '#80BBFF',
  secondary_300: '#3D89DF',
  secondary_400: '#1671D9',

  // Color Palette - Neutral
  grey_22: '#344565',
  grey_50: '#F9FAFB',
  grey_75: '#F7F9FC',
  grey_100: '#F0F2F5',
  grey_200: '#E4E7EC',
  grey_300: '#D0D5DD',
  grey_400: '#98A2B3',
  grey_500: '#667185',
  grey_600: '#475367',
  grey_700: '#344054',
  grey_800: '#1D2739',
  grey_900: '#101928',

  // Color Palette - Error
  error_50: '#FBEAE9',
  error_75: '#F2BCBA',
  error_100: '#EB9B98',
  error_200: '#E26E6A',
  error_300: '#DD524D',
  error_400: '#D42620',
  error_500: '#CB1A14',
  error_700: '#9E0A05',

  // Color Palette - Success
  success_50: '#E7F6EC',
  success_75: '#B5E3C4',
  success_100: '#91D6A8',
  success_200: '#5FC381',
  success_300: '#40B869',
  success_400: '#0F973D',
  success_600: '#04802E',
  success_700: '#036B26',

  // Color Palette - Warning
  warning_50: '#FEF6E7',
  warning_100: '#F7D394',
  warning_400: '#F3A218',
  warning_700: '#865503',

  // Color Palette - Neutral
  neutral_600: '#475367'
};

export default colors;
