<template>
  <div class="relative" ref="dropDownRef">
    <slot name="input" />
    <div
      v-if="isOpen"
      ref="dropDownMenuRef"
      class="absolute min-w-full w-max mt-2 rounded-md shadow-lg bg-white border border-grey_100 overflow-clip z-10"
      :class="`${isVisibleX() ? 'left-0' : 'right-0'} ${isVisibleY() ? 'top-0' : 'bottom-[-8px]'} ${
        $attrs.class
      } ${dropDownClass}`"
    >
      <div
        class="shadow-lg bg-white overflow-clip z-10 w-max rounded-md"
        :style="{
          width: `${dropdownWidth}px`,
          minWidth: '120px'
        }"
      >
        <div
          v-if="!dropDownData.length && showEmptyState"
          class="min-w-[160px] px-2 py-3 text-sm leading-4 text-grey_400 italic"
        >
          {{ emptyStatePlaceholder || $t('no-match-found') }}
        </div>
        <ul v-else class="min-w-[160px] max-h-[250px] overflow-y-auto">
          <slot name="list">
            <li
              v-for="option in dropDownData"
              :key="option.id"
              class="p-3 text-sm leading-4 text-grey_800 hover:bg-grey_100 flex flex-row items-end gap-1"
              :class="[
                option.customStyle,
                listItemClass,
                option.disabled
                  ? 'pointer-events-none cursor-not-allowed opacity-60'
                  : 'cursor-pointer'
              ]"
              @click="handleSelectOption(option.id)"
              @keydown="!option.disabled && handleSelectOption(option.id)"
            >
              <img
                :src="option.imgUrl"
                alt="img"
                v-if="option.imgUrl && showIcon"
                class="w-4 h-4"
              />
              {{ isLocalized ? option.value : $t(option.value) }}
            </li>
          </slot>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

import { isVisibleRight } from '@/utils/generic';
import type { DropDownDataType } from '@/types';

type propType = {
  dropDownData: DropDownDataType[];
  isOpen: boolean;
  handleOpen: () => void;
  handleClick?: (action: string) => void;
  isLocalized?: boolean;
  showEmptyState?: boolean;
  emptyStatePlaceholder?: string;
  dropDownClass?: string;
  listItemClass?: string;
  showIcon?: boolean;
};
const props = defineProps<propType>();

defineOptions({
  inheritAttrs: false
});

const dropDownRef = ref<HTMLElement | null>(null);
const dropDownMenuRef = ref<HTMLElement | null>(null);

const dropdownWidth = computed(() => {
  let width = 0;

  if (dropDownRef.value) width = dropDownRef.value.getBoundingClientRect().width;

  return width;
});

const handleSelectOption = (option: string) => {
  if (props.handleClick) props.handleClick(option);
  props.handleOpen();
};

const handleOutsideClick = (event: MouseEvent) => {
  if (dropDownRef.value && !dropDownRef.value.contains(event.target as Node)) props.handleOpen();
};

const isVisibleX = () => {
  const parentWidth = document.getElementById('card-container')?.clientWidth ?? window.innerWidth;

  return isVisibleRight(dropDownRef.value, dropDownMenuRef.value, parentWidth, 155);
};

const isVisibleY = () => {
  if (dropDownRef.value) {
    const clientBottom = dropDownRef.value.getBoundingClientRect().bottom;
    const parentHeight =
      document.getElementById('list-container')?.getBoundingClientRect().bottom ??
      window.innerHeight;

    if (parentHeight - clientBottom > (dropDownMenuRef.value?.clientHeight || 155)) return true;
  }

  return false;
};

onMounted(() => {
  if (props.isOpen) document.addEventListener('click', handleOutsideClick);
});
watch(props, (open) => {
  if (open.isOpen) document.addEventListener('click', handleOutsideClick);
  else document.removeEventListener('click', handleOutsideClick);
});

onUnmounted(() => () => {
  document.removeEventListener('click', handleOutsideClick);
});
</script>
