import { ref } from 'vue';
import { defineStore } from 'pinia';

import type { TemplateType } from '@/types/templates';
import type { Map } from '@/types';

const useTemplateStore = defineStore('template', () => {
  const templates = ref<TemplateType[]>([]);
  const hasUnsavedChanges = ref<boolean>(false);
  const errors = ref<Map>({});
  const hasAttachmentError = ref<boolean>(false);
  const showError = ref<boolean>(false);

  const setTemplates = (data: TemplateType[]) => {
    templates.value = data;
  };

  const setUnsavedChanges = (value: boolean) => {
    hasUnsavedChanges.value = value;
  };

  const setErrors = (data: Map) => {
    errors.value = data;
  };

  const setAttachmentError = (value: boolean) => {
    hasAttachmentError.value = value;
  };

  const setShowError = (value: boolean) => {
    showError.value = value;
  };

  const reset = () => {
    templates.value = [];
    hasUnsavedChanges.value = false;
    errors.value = {};
    hasAttachmentError.value = false;
    showError.value = false;
  };

  return {
    templates,
    hasUnsavedChanges,
    errors,
    hasAttachmentError,
    showError,
    setTemplates,
    setUnsavedChanges,
    setErrors,
    setAttachmentError,
    setShowError,
    reset
  };
});

export default useTemplateStore;
