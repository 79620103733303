import SMS from '@/assets/icons/templates/Sms.vue';
import Email from '@/assets/icons/templates/Email.vue';
import Push from '@/assets/icons/templates/Push.vue';
import WhatsApp from '@/assets/icons/templates/WhatsApp.vue';
import Slack from '@/assets/icons/templates/Slack.vue';
import Teams from '@/assets/icons/templates/Teams.vue';
import InApp from '@/assets/icons/templates/InApp.vue';
import Discord from '@/assets/icons/templates/Discord.vue';
import Line from '@/assets/icons/templates/Line.vue';
import i18n from '@/i18n/config';

import type { Map, DropDownDataType } from '@/types';
import type { TemplateFieldType } from '@/types/templates';

import { TemplateTypes } from './common';
import {
  JSON_PLACEHOLDER_REGEX,
  URL_REGEX,
  EMAIL,
  PLACEHOLDER_REGEX,
  COMMA_SEPARATED_EMAIL,
  COLOR_REGEX,
  NUMBER_INPUT_REGEX
} from './regex';
import { TEMPLATE_FIELD_ERRORS } from './errors';

export const TemplatesTab = ['Version', 'Live'];

export enum TemplateAction {
  ADD = 'add',
  EDIT = 'edit',
  DUPLICATE = 'duplicate',
  DELETE = 'delete',
  HIDE = 'hide'
}

export enum MandatoryTemplateFields {
  SMS_MESSAGE = 'sms_message',
  SMS_VARIABLE = 'sms_variable',
  EMAIL_SUBJECT = 'email_subject',
  EMAIL_TEMPLATE = 'email_template',
  EMAIL_ATTACHMENTS = 'email_attachments',
  PUSH_TITLE = 'push_title',
  PUSH_MESSAGE = 'push_message',
  PUSH_DURATION = 'push_duration',
  PUSH_ADDITIONAL_DATA = 'push_additional_data',
  PUSH_ANDROID_IMAGE = 'push_android_image',
  PUSH_IOS_IMAGE = 'push_ios_image',
  TEMPLATE_NAME = 'template_name',
  WHATSAPP_NAME = 'whatsapp_name',
  WHATSAPP_TEXT_OR_URL = 'whatsapp_text_or_url',
  WHATSAPP_FILENAME = 'whatsapp_filename',
  SLACK_MESSAGE = 'slack_message',
  SLACK_ATTACHMENTS = 'slack_attachments',
  MS_TEAMS_MESSAGE = 'ms_teams_message',
  MS_TEAMS_ATTACHMENTS = 'ms_teams_attachments',
  IN_APP_MESSAGE = 'in_app_message',
  IN_APP_AVTAR = 'in_app_avtar',
  IN_APP_ACTION_URL = 'in_app_action_url',
  IN_APP_ADDITIONAL_DATA = 'in_app_additional_data',
  DISCORD_MESSAGE = 'discord_message',
  DISCORD_ATTACHMENTS = 'discord_attachments'
}

export const DurationDropdownOptions = [
  {
    id: 'Minutes',
    value: 'minutes'
  },
  {
    id: 'Hours',
    value: 'hours'
  },
  {
    id: 'Days',
    value: 'days'
  },
  {
    id: 'Weeks',
    value: 'weeks'
  },
  {
    id: 'Months',
    value: 'months'
  }
];

export const PriorityDropdownOptions = [
  {
    id: 'HIGH',
    value: 'high'
  },
  {
    id: 'DEFAULT',
    value: 'normal'
  }
];

export enum PlatformTypes {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB'
}

export enum EmailTemplateTypes {
  RICH_TEXT = 'Rich Text',
  HTML = 'HTML',
  PLAIN_TEXT = 'Plain Text'
}

export enum MessageTypes {
  NONE = 'NONE',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  FILE = 'DOCUMENT'
}

export const WhatsappMessageTypes = [
  {
    id: MessageTypes.NONE,
    value: 'None'
  },
  {
    id: MessageTypes.TEXT,
    value: 'Text'
  },
  {
    id: MessageTypes.IMAGE,
    value: 'Image'
  },
  {
    id: MessageTypes.VIDEO,
    value: 'Video'
  },
  {
    id: MessageTypes.FILE,
    value: 'File'
  }
];

export enum WhatsappTemplateButtons {
  NONE = 'none',
  CALL_TO_ACTION = 'callToAction',
  QUICK_REPLIES = 'quickReplies'
}

export const whatsappButtonDropDownData: DropDownDataType[] = [
  { id: WhatsappTemplateButtons.NONE, value: 'none' },
  { id: WhatsappTemplateButtons.CALL_TO_ACTION, value: 'call_to_action' },
  { id: WhatsappTemplateButtons.QUICK_REPLIES, value: 'quick_replies' }
];

export const whatsappCallToActionDropDownData: DropDownDataType[] = [
  { id: '0', value: '0' },
  { id: '1', value: '1' }
];

export const QUICK_REPLIES_COUNT = 3;
export const CALL_TO_ACTION_COUNT = 2;
export enum WhatsAppTemplatePayload {
  TYPE = 'TEMPLATE',
  LANGUAGE = 'EN',
  HEADER = 'HEADER',
  BODY = 'BODY',
  BODY_TYPE = 'TEXT',
  BUTTON_TYPE = 'BUTTON',
  PAYLOAD = 'payload',
  POLICY = 'deterministic',
  MESSAGING_PRODUCT = 'whatsapp',
  RECIPIENT_TYPE = 'individual'
}

export enum WhatsappTemplateButtonSubTypePayload {
  CALL_TO_ACTION = 'URL',
  QUICK_REPLIES = 'QUICK_REPLY'
}

export const templateIcons = {
  [TemplateTypes.SMS]: SMS,
  [TemplateTypes.EMAIL]: Email,
  [TemplateTypes.PUSH]: Push,
  [TemplateTypes.WHATSAPP]: WhatsApp,
  [TemplateTypes.SLACK]: Slack,
  [TemplateTypes.TEAMS]: Teams,
  [TemplateTypes.IN_APP]: InApp,
  [TemplateTypes.DISCORD]: Discord,
  [TemplateTypes.LINE]: Line
};

export enum FILETYPES {
  JPEG = 'JPEG',
  PDF = 'PDF',
  DOCX = 'DOCX',
  DOC = 'DOC',
  IMAGE = 'IMAGE'
}

export enum EmailContentType {
  PLAIN = 'text/plain',
  HTML = 'text/html'
}

export const ContentTypeDropdownOptions = [
  {
    id: EmailContentType.HTML,
    value: EmailContentType.HTML
  },
  {
    id: EmailContentType.PLAIN,
    value: EmailContentType.PLAIN
  }
];

export const defaultFileTypes: DropDownDataType[] = [
  { id: 'application/pdf', value: FILETYPES.PDF },
  { id: 'image/jpeg', value: FILETYPES.JPEG },
  { id: 'application/docx', value: FILETYPES.DOCX }
];

export const msTeamsFileTypes: DropDownDataType[] = [{ id: 'image', value: FILETYPES.IMAGE }];

export const defaultAttachmentHeaders: string[] = [
  'filename_placeholder_title',
  'filetype',
  'content'
];

export const msTeamsAttachmentHeaders: string[] = [
  'filename_placeholder_title',
  'filetype',
  'imageurl'
];

export const fileExtenstions: Map = {
  [FILETYPES.JPEG]: '.jpg',
  [FILETYPES.PDF]: '.pdf',
  [FILETYPES.DOCX]: '.docx'
};

export enum ATTACHMENT_FIELDS {
  FILENAME = 'fileName',
  TYPE = 'type',
  CONTENT = 'content',
  FILEURL = 'fileUrl'
}

export const ADDITIONAL_DATA_PLACEHOLDER = `{
  "id": "3234",
  "priority": "low",
  "type": "payment"
}`;

export const MAX_EMAIL_ATTACHMENTS = 20;

export const VARIABLES_PLACEHOLDER =
  '[\n\t{\n\t\t"name": "<variable_name>",\n\t\t"placeHolder": "<placeholder_value>",\n\t\t"defaultValue": "<default_value>"\n\t}\n]';

export const Platforms = [
  { label: PlatformTypes.ANDROID as string, index: 0 },
  { label: PlatformTypes.IOS as string, index: 1 },
  { label: PlatformTypes.WEB as string, index: 2 }
];

export const EmailTemplate = [
  { label: EmailTemplateTypes.RICH_TEXT, index: 0 },
  { label: EmailTemplateTypes.HTML, index: 1 }
];

export const EmailTemplatePlainText = [{ label: EmailTemplateTypes.PLAIN_TEXT, index: 0 }];

export const MAX_TEMPLATE_TITLE_LENGTH = 70;

export enum PUSH_TEMPLATE_FIELDS {
  PUSH_TITLE = 'title',
  PUSH_MESSAGE = 'message',
  ADDITIONAL_SETTINGS_TTL = 'additionalSettings_ttl',
  ADDITIONAL_SETTINGS_DURATION = 'additionalSettings_duration',
  ADDITIONAL_SETTINGS_ANALYTICS_LABEL = 'additionalSettings_analyticsLabel',
  ADDITIONAl_DATA = 'additionalData',
  ANDROID_SETTINGS_IMAGE = 'androidSettings_image',
  ANDROID_SETTINGS_DESTINATION_URL = 'androidSettings_destinationUrl',
  ANDROID_SETTINGS_ICON = 'androidSettings_icon',
  ANDROID_SETTINGS_COLOR = 'androidSettings_color',
  ANDROID_SETTINGS_SOUND = 'androidSettings_sound',
  ANDROID_SETTINGS_BADGE = 'androidSettings_badge',
  ANDROID_SETTINGS_TAG = 'androidSettings_tag',
  ANDROID_SETTINGS_TICKER = 'androidSettings_ticker',
  ANDROID_SETTINGS_COLLAPSEKEY = 'androidSettings_collapseKey',
  IOS_SETTINS_IMAGE = 'iosSettings_image',
  IOS_SETTINS_SUBTITLE = 'iosSettings_subtitle',
  IOS_SETTINS_ICON = 'iosSettings_icon',
  IOS_SETTINS_SOUND = 'iosSettings_sound',
  IOS_SETTINS_BADGE = 'iosSettings_badge',
  IOS_SETTINS_COLLAPSEKEY = 'iosSettings_collapseKey',
  WEB_SETTINGS_IMAGE = 'webSettings_image',
  WEB_SETTINGS_LINK = 'webSettings_link',
  WEB_SETTINGS_ICON = 'webSettings_icon',
  WEB_SETTINGS_BADGE = 'webSettings_badge',
  WEB_SETTINGS_TAG = 'webSettings_tag'
}

export const PUSH_TEMPLATE: TemplateFieldType[] = [
  {
    key: PUSH_TEMPLATE_FIELDS.PUSH_TITLE,
    isMandatory: true,
    label: 'title'
  },
  {
    key: PUSH_TEMPLATE_FIELDS.PUSH_MESSAGE,
    isMandatory: true,
    label: 'message'
  },
  {
    key: PUSH_TEMPLATE_FIELDS.ADDITIONAL_SETTINGS_TTL,
    isMandatory: false,
    regex: NUMBER_INPUT_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.PUSH_VALID_TTL
  },
  {
    key: PUSH_TEMPLATE_FIELDS.ADDITIONAL_SETTINGS_ANALYTICS_LABEL,
    isMandatory: false
  },
  {
    key: PUSH_TEMPLATE_FIELDS.ADDITIONAl_DATA,
    isMandatory: false
  },
  {
    key: PUSH_TEMPLATE_FIELDS.ANDROID_SETTINGS_IMAGE,
    isMandatory: false,
    regex: URL_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.PUSH_VALID_URL
  },
  {
    key: PUSH_TEMPLATE_FIELDS.ANDROID_SETTINGS_DESTINATION_URL,
    isMandatory: false,
    regex: URL_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.PUSH_VALID_URL
  },
  {
    key: PUSH_TEMPLATE_FIELDS.ANDROID_SETTINGS_ICON,
    isMandatory: false
  },
  {
    key: PUSH_TEMPLATE_FIELDS.ANDROID_SETTINGS_SOUND,
    isMandatory: false
  },
  {
    key: PUSH_TEMPLATE_FIELDS.ANDROID_SETTINGS_COLOR,
    isMandatory: false,
    regex: COLOR_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.PUSH_VALID_COLOR
  },
  {
    key: PUSH_TEMPLATE_FIELDS.ANDROID_SETTINGS_BADGE,
    isMandatory: false,
    regex: NUMBER_INPUT_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.PUSH_VALID_BADGE
  },
  {
    key: PUSH_TEMPLATE_FIELDS.ANDROID_SETTINGS_TAG,
    isMandatory: false
  },
  {
    key: PUSH_TEMPLATE_FIELDS.ANDROID_SETTINGS_TICKER,
    isMandatory: false
  },
  {
    key: PUSH_TEMPLATE_FIELDS.ANDROID_SETTINGS_COLLAPSEKEY,
    isMandatory: false
  },
  {
    key: PUSH_TEMPLATE_FIELDS.IOS_SETTINS_IMAGE,
    isMandatory: false,
    regex: URL_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.PUSH_VALID_URL
  },
  {
    key: PUSH_TEMPLATE_FIELDS.IOS_SETTINS_SUBTITLE,
    isMandatory: false
  },
  {
    key: PUSH_TEMPLATE_FIELDS.IOS_SETTINS_ICON,
    isMandatory: false
  },
  {
    key: PUSH_TEMPLATE_FIELDS.IOS_SETTINS_SOUND,
    isMandatory: false
  },
  {
    key: PUSH_TEMPLATE_FIELDS.IOS_SETTINS_BADGE,
    isMandatory: false,
    regex: NUMBER_INPUT_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.PUSH_VALID_BADGE
  },
  {
    key: PUSH_TEMPLATE_FIELDS.IOS_SETTINS_COLLAPSEKEY,
    isMandatory: false
  },
  {
    key: PUSH_TEMPLATE_FIELDS.WEB_SETTINGS_IMAGE,
    isMandatory: false,
    regex: URL_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.PUSH_VALID_URL
  },
  {
    key: PUSH_TEMPLATE_FIELDS.WEB_SETTINGS_LINK,
    isMandatory: false,
    regex: URL_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.PUSH_VALID_URL
  },
  {
    key: PUSH_TEMPLATE_FIELDS.WEB_SETTINGS_ICON,
    isMandatory: false
  },
  {
    key: PUSH_TEMPLATE_FIELDS.WEB_SETTINGS_BADGE,
    isMandatory: false,
    regex: NUMBER_INPUT_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.PUSH_VALID_BADGE
  },
  {
    key: PUSH_TEMPLATE_FIELDS.WEB_SETTINGS_TAG,
    isMandatory: false
  }
];

export enum SMS_TEMPLATE_FIELDS {
  MESSAGE = 'body',
  DLT_TEMPLATE_ID = 'dltTemplateId'
}

export const SMS_TEMPLATE: TemplateFieldType[] = [
  {
    key: SMS_TEMPLATE_FIELDS.MESSAGE,
    isMandatory: true,
    label: 'message'
  },
  {
    key: SMS_TEMPLATE_FIELDS.DLT_TEMPLATE_ID,
    isMandatory: false
  }
];

export enum SLACK_TEMPLATE_FIELDS {
  MESSAGE = 'body'
}

export const SLACK_TEMPLATE: TemplateFieldType[] = [
  {
    key: SLACK_TEMPLATE_FIELDS.MESSAGE,
    isMandatory: true,
    label: 'message_content'
  }
];

export enum TEAMS_TEMPLATE_FIELDS {
  MESSAGE = 'body'
}

export const TEAMS_TEMPLATE: TemplateFieldType[] = [
  {
    key: TEAMS_TEMPLATE_FIELDS.MESSAGE,
    isMandatory: true,
    label: 'message_content'
  }
];

export enum INAPP_TEMPLATE_FIELDS {
  TITLE = 'header',
  MESSAGE = 'body',
  SUBTEXT = 'subHeader',
  ACTION_URL = 'actionUrl',
  AVATAR = 'avatar_imageUrl',
  ADDITIONAl_DATA = 'additionalData',
  THUMBNAIL_URL = 'thumbnailUrl'
}

export const INAPP_TEMPLATE: TemplateFieldType[] = [
  {
    key: INAPP_TEMPLATE_FIELDS.TITLE,
    isMandatory: false
  },
  {
    key: INAPP_TEMPLATE_FIELDS.MESSAGE,
    isMandatory: true,
    label: 'message_content'
  },
  {
    key: INAPP_TEMPLATE_FIELDS.SUBTEXT,
    isMandatory: false
  },
  {
    key: INAPP_TEMPLATE_FIELDS.ACTION_URL,
    isMandatory: false,
    regex: URL_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.IN_APP_VALID_URL
  },
  {
    key: INAPP_TEMPLATE_FIELDS.AVATAR,
    isMandatory: false,
    regex: URL_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.IN_APP_VALID_URL
  },
  {
    key: INAPP_TEMPLATE_FIELDS.ADDITIONAl_DATA,
    isMandatory: false,
    regex: JSON_PLACEHOLDER_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.IN_APP_ADDITIONAL_DATA
  },
  {
    key: INAPP_TEMPLATE_FIELDS.THUMBNAIL_URL,
    isMandatory: false,
    regex: URL_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.IN_APP_THUMBNAIL_URL
  }
];

export const TEMPLATE_ATTACHMENTS: TemplateFieldType[] = [
  {
    key: ATTACHMENT_FIELDS.FILENAME,
    isMandatory: true,
    label: 'file_name'
  },
  {
    key: ATTACHMENT_FIELDS.TYPE,
    isMandatory: true,
    label: 'file_type'
  },
  {
    key: ATTACHMENT_FIELDS.CONTENT,
    isMandatory: true,
    label: 'content',
    regex: PLACEHOLDER_REGEX,
    errorMessage: TEMPLATE_FIELD_ERRORS.ATTACHMENT_CONTENT
  }
];

export enum EMAIL_TEMPLATE_FIELDS {
  SUBJECT = 'subject',
  CONTENT = 'body',
  CC = 'cc',
  BCC = 'bcc',
  FROM_NAME = 'fromName',
  FROM_EMAIL = 'fromEmail',
  REPLY_TO = 'replyTo'
}

export const EMAIL_TEMPLATE: TemplateFieldType[] = [
  {
    key: EMAIL_TEMPLATE_FIELDS.SUBJECT,
    isMandatory: true,
    label: 'subject'
  },
  {
    key: EMAIL_TEMPLATE_FIELDS.CONTENT,
    isMandatory: true,
    label: 'content'
  },
  {
    key: EMAIL_TEMPLATE_FIELDS.CC,
    isMandatory: false,
    regex: COMMA_SEPARATED_EMAIL,
    errorMessage: TEMPLATE_FIELD_ERRORS.INVALID_EMAIL
  },
  {
    key: EMAIL_TEMPLATE_FIELDS.BCC,
    isMandatory: false,
    regex: COMMA_SEPARATED_EMAIL,
    errorMessage: TEMPLATE_FIELD_ERRORS.INVALID_EMAIL
  },
  {
    key: EMAIL_TEMPLATE_FIELDS.FROM_NAME,
    isMandatory: false
  },
  {
    key: EMAIL_TEMPLATE_FIELDS.FROM_EMAIL,
    isMandatory: false,
    regex: EMAIL,
    errorMessage: TEMPLATE_FIELD_ERRORS.INVALID_EMAIL
  },
  {
    key: EMAIL_TEMPLATE_FIELDS.REPLY_TO,
    isMandatory: false,
    regex: EMAIL,
    errorMessage: TEMPLATE_FIELD_ERRORS.INVALID_EMAIL
  }
];

export enum TinyMCEEditorMode {
  READONLY = 'readonly',
  DESIGN = 'design'
}

export enum DISCORD_TEMPLATE_FIELDS {
  MESSAGE = 'content'
}

export const DISCORD_TEMPLATE: TemplateFieldType[] = [
  {
    key: DISCORD_TEMPLATE_FIELDS.MESSAGE,
    isMandatory: true,
    label: 'message_content'
  }
];

export enum WHATSAPP_TEMPLATE_FIELDS {
  TITLE = 'title',
  URL = 'url',
  FILENAME = 'fileName'
}

export enum LineMessageTypes {
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video'
}

export const LineMessageFieldTypes: DropDownDataType[] = [
  { id: LineMessageTypes.TEXT, value: i18n.global.t('text') },
  { id: LineMessageTypes.IMAGE, value: i18n.global.t('image') },
  { id: LineMessageTypes.VIDEO, value: i18n.global.t('video') }
];

export enum LINE_TEMPLATE_FIELDS {
  TYPE = 'type',
  TEXT = 'text',
  CONTENT_URL = 'originalContentUrl',
  PREVIEW_URL = 'previewImageUrl'
}

export const SmsMessageLength = 160;

export enum ACTION_OPTIONS_ID {
  SAVE_DRAFT = 'save-as-draft'
}
