import { dataSourceActions } from './datasource';
import { ProviderDropDownKeys } from './providers';

export enum Permissions {
  // GENERAL
  APP_ENV_CHANGE_PROD = 'app-env-change-prod',
  APP_ENV_CHANGE_STG = 'app-env-change-stg',
  // DASHBOARD
  DASHBOARD_PAGE_VIEW = 'dashboard-page-view',
  // PROVIDERS
  PROVIDERS_PAGE_VIEW = 'providers-page-view',
  PROVIDER_INTEGRATIONS_PAGE_VIEW = 'provider-integrations-page-view',
  PROVIDER_INTEGRATIONS_CREATE = 'provider-integrations-create',
  PROVIDER_INTEGRATIONS_EDIT = 'provider-integrations-edit',
  PROVIDER_INTEGRATIONS_DELETE = 'provider-integrations-delete',
  // TEMPLATES
  TEMPLATES_PAGE_VIEW = 'templates-page-view',
  TEMPLATE_CREATE = 'template-create',
  TEMPLATE_DELETE = 'template-delete',
  TEMPLATE_DUPLICATE = 'template-duplicate',
  TEMPLATE_CONTENT_EDIT = 'template-content-edit',
  TEMPLATE_EDIT_PAGE_VIEW = 'template-edit-page-view',
  TEMPLATE_CONTENT_PUBLISH = 'template-content-publish',
  // WORKFLOWS
  WORKFLOWS_PAGE_VIEW = 'workflows-page-view',
  WORKFLOW_CREATE = 'workflow-create',
  WORKFLOW_CONTENT_EDIT = 'workflow-content-edit',
  WORKFLOW_EDIT_PAGE_VIEW = 'workflow-edit-page-view',
  WORKFLOW_CONTENT_PUBLISH = 'workflow-content-publish',
  WORKFLOW_CLONE = 'workflow-clone',
  WORKFLOW_DUPLICATE = 'workflow-duplicate',
  // LOGS
  LOGS_PAGE_VIEW = 'logs-page-view',
  LOGS_EXPORT = 'export-notification-logs',
  // KEYS {CONFIGURATION MENU}
  KEYS_PAGE_VIEW = 'keys-page-view',
  KEYS_CREATE = 'keys-create',
  KEYS_EDIT = 'keys-edit',
  KEYS_DELETE = 'keys-delete',
  // WEBHOOK CONFIG {CONFIGURATION MENU}
  WEBHOOK_CONFIG_PAGE_VIEW = 'webhook-config-page-view',
  WEBHOOK_CONFIG_EDIT = 'webhook-config-edit',
  // PROFILE
  PROFILE_PAGE_VIEW = 'profile-page-view',
  PROFILE_PAGE_EDIT = 'profile-page-edit',
  // TEAM
  TEAM_PAGE_VIEW = 'user-page-view',
  TEAM_INVITE = 'user-invite',
  TEAM_EDIT = 'user-edit',
  TEAM_DELETE = 'user-delete',
  // DATA-SOURCES
  DATASOURCES_PAGE_VIEW = 'datasources-page-view',
  DATASOURCE_CONNECTIONS_PAGE_VIEW = 'datasource-connections-page-view',
  DATASOURCE_CONNECTION_CREATE = 'datasource-connection-create',
  DATASOURCE_CONNECTION_EDIT = 'datasource-connection-edit',
  DATASOURCE_CONNECTION_DELETE = 'datasource-connection-delete',
  // QUERIES
  DATA_FETCH_INFOS_PAGE_VIEW = 'data-fetch-infos-page-view',
  DATA_FETCH_INFO_CREATE = 'data-fetch-info-create',
  DATA_FETCH_INFO_DELETE = 'data-fetch-info-delete',
  DATA_FETCH_INFO_DETAILS_PAGE_VIEW = 'data-fetch-info-details-page-view',
  DATA_FETCH_INFO_EDIT = 'data-fetch-info-edit',
  DATA_FETCH_INFO_QUERY_EXECUTE = 'data-fetch-info-query-execute',
  // COHORT
  COHORTS_PAGE_VIEW = 'cohorts-page-view',
  COHORT_CREATE = 'cohort-create',
  COHORT_EDIT = 'cohort-edit',
  COHORT_DELETE = 'cohort-delete',
  COHORT_EDIT_PAGE_VIEW = 'cohort-edit',
  // CAMPAIGN
  CAMPAIGNS_PAGE_VIEW = 'campaigns-page-view',
  CAMPAIGN_CREATE = 'campaign-create',
  CAMPAIGN_DELETE = 'campaign-delete',
  CAMPAIGN_EDIT = 'campaign-edit',
  CAMPAIGN_STOP = 'campaign-stop',
  // SCHEDULE
  SCHEDULES_PAGE_VIEW = 'schedule-page-view',
  SCHEDULES_CREATE = 'schedule-create',
  SCHEDULES_EDIT = 'schedule-edit',
  SCHEDULES_DELETE = 'schedule-delete',
  // USER
  USER_EDIT = 'audience-edit',
  USER_DELETE = 'audience-delete',
  USER_PAGE_VIEW = 'audience-page-view',
  USER_CREATE = 'audience-create',
  // SEGMENT
  SEGMENT_PAGE_VIEW = 'segment-page-view',
  SEGMENT_CREATE = 'segment-create',
  SEGMENT_EDIT = 'segment-edit',
  SEGMENT_DELETE = 'segment-delete'
}

export const WorkflowActionPermissions = [
  {
    action: ProviderDropDownKeys.EDIT,
    access: Permissions.PROVIDER_INTEGRATIONS_EDIT
  },
  {
    action: ProviderDropDownKeys.DELETE,
    access: Permissions.PROVIDER_INTEGRATIONS_DELETE
  }
];

export const DataSourceActionsPermissions = [
  {
    action: dataSourceActions.EDIT,
    access: Permissions.DATASOURCE_CONNECTION_EDIT
  },
  {
    action: dataSourceActions.DELETE,
    access: Permissions.DATASOURCE_CONNECTION_DELETE
  }
];

export const SegmentsPermissions = [
  {
    action: dataSourceActions.EDIT,
    access: Permissions.SEGMENT_EDIT
  },
  {
    action: dataSourceActions.DELETE,
    access: Permissions.SEGMENT_DELETE
  }
];
