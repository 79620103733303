/* eslint-disable prefer-destructuring */
export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
export const API_CAMPAIGN_BASE_URL = import.meta.env.VITE_API_CAMPAIGN_BASE_URL;
export const API_AI_CAMPAIGN_BASE_URL = import.meta.env.VITE_API_AI_CAMPAIGN_BASE_URL;
export const APP_ENV = import.meta.env.VITE_APP_ENV;
export const CAMPAIGN_TENANT = import.meta.env.VITE_CAMPAIGN_TENANT;

export enum ENV {
  DEV = 'development',
  STG = 'staging',
  PROD = 'production'
}
