import { ref } from 'vue';
import axios from 'axios';
import { Mutex } from 'async-mutex';
import type { AxiosError } from 'axios';

import i18n from '@/i18n/config';
import useLogout from '@/composables/useLogout';
import useProjectStore from '@/stores/project';
import useProfileStore from '@/stores/profile';
import { API_BASE_URL, API_CAMPAIGN_BASE_URL, API_AI_CAMPAIGN_BASE_URL } from '@/constants/config';
import { ERROR_STATUS_CODES, ApiServiceType } from '@/constants/common';
import { ROUTES } from '@/constants/path';
import { ApiTypes, ApiVersions, NetworkErrorTypes } from '@/types/api';
import { getTimeDifferenceFromNow } from '@/utils/dateFormatters';
import type {
  ApiErrorResponseType,
  ApiPayloadType,
  ApiResponseType,
  MakeCallType
} from '@/types/api';
import { constructUrl } from '@/utils/generic';

const getApiBaseURL = (service: ApiServiceType) => {
  if (service === ApiServiceType.CAMPAIGN) return API_CAMPAIGN_BASE_URL;
  if (service === ApiServiceType.CAMPAIGN_AI) return API_AI_CAMPAIGN_BASE_URL;

  return API_BASE_URL;
};

const mutex = new Mutex();

const useApi = ({
  query = '',
  method = ApiTypes.GET,
  apiVersion = ApiVersions.V1,
  payload = null,
  isLazy = false,
  params = null,
  queryString = null,
  service = ApiServiceType.DEFAULT
}: ApiPayloadType): ApiResponseType => {
  const isLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);
  const data = ref<any>(null);
  const meta = ref<any>(null);
  const error = ref<any>(null);

  const projectStore = useProjectStore();
  const profileStore = useProfileStore();
  const { clearUserDataAndRedirectToLogin } = useLogout();
  let projectEnvId = '';

  if (projectStore?.projectEnv?.id) projectEnvId = projectStore.projectEnv.id;

  const API_URL = `${getApiBaseURL(service)}/${apiVersion}/${query}`;

  const makeApiCall = async (apiParams?: MakeCallType) => {
    const {
      apiPayload = null,
      params = null,
      queryString = null,
      urlVariables = null,
      customUrl = '',
      handleProgress
    } = apiParams || {};

    await mutex.waitForUnlock();
    isLoading.value = true;
    let url = API_URL;

    if (urlVariables) url = constructUrl(url, urlVariables);

    if (queryString) url = `${url}?projectEnvironmentId=${projectEnvId}&${queryString}`;

    try {
      const apiResponse: ApiResponseType = await axios({
        url: customUrl || url,
        method,
        data: apiPayload,
        onUploadProgress: handleProgress,
        headers: {
          ...(service !== ApiServiceType.CAMPAIGN_AI && {
            'X-Project-Environment-Id': projectEnvId
          }),
          ...(customUrl && { 'Content-Type': 'text/csv' })
        },
        withCredentials: !customUrl,
        ...(!queryString && {
          params: {
            ...params,
            ...(projectEnvId &&
              service !== ApiServiceType.CAMPAIGN_AI &&
              !customUrl && { projectEnvironmentId: projectEnvId })
          },
          paramsSerializer: {
            indexes: null
          }
        })
      });
      const { data: apiData, meta: apiMeta } = apiResponse;

      data.value = apiData;
      meta.value = apiMeta;
      error.value = null;
      isError.value = false;
    } catch (err: any) {
      data.value = null;
      meta.value = null;
      error.value = err?.response?.data;
      isError.value = true;

      if (err) {
        const errorValue = err as AxiosError<ApiErrorResponseType>;

        if (errorValue?.response) {
          const { status, headers, data: errorData } = errorValue.response;

          switch (status) {
            case ERROR_STATUS_CODES.ERROR_403:
              if (errorData?.error?.errorCode === NetworkErrorTypes.INSUFFICIENT_PERMISSION)
                profileStore.getProfileData();
              else clearUserDataAndRedirectToLogin();
              break;

            case ERROR_STATUS_CODES.ERROR_401:
              if (!mutex.isLocked()) {
                if (errorData?.error?.errorCode === NetworkErrorTypes.TOKEN_EXPIRED) {
                  await mutex.acquire();
                  const REFRESH_API_URL = `${API_BASE_URL}/v1/auth/refresh-auth-tokens`;

                  try {
                    await axios.get(REFRESH_API_URL, { headers: {}, withCredentials: true });
                    await mutex.release();
                    await makeApiCall({ apiPayload, params, queryString, urlVariables });
                  } catch {
                    await mutex.release();
                    clearUserDataAndRedirectToLogin();
                  }
                } else {
                  clearUserDataAndRedirectToLogin();
                }
              } else {
                await mutex.waitForUnlock();
                await makeApiCall({ apiPayload, params, queryString, urlVariables });
              }
              break;

            case ERROR_STATUS_CODES.ERROR_404:
              if ([ApiTypes.GET, ApiTypes.POST].includes(method))
                window.location.replace(`${window.location.origin}${ROUTES.PAGE_NOT_FOUND}`);
              break;

            case ERROR_STATUS_CODES.ERROR_429:
              if (headers && headers['retry-after']) {
                /*
                 * Specific case of rate limiting.
                 * Replacing default 'Limit exceeded' message to 'Retry after some time'
                 */
                const canRetryFrom = getTimeDifferenceFromNow(headers['retry-after']);

                if (
                  canRetryFrom.isFutureDate &&
                  !!canRetryFrom.formattedTimeDiff &&
                  errorData?.error
                )
                  errorData.error.message = `${i18n.global.t('retry_after_x_time', {
                    time: canRetryFrom.formattedTimeDiff
                  })}`;
              }
              break;

            default:
              break;
          }
        }
      }
    } finally {
      isLoading.value = false;
    }
  };

  const makeApiCallIfNeeded = () => {
    if (!isLazy) makeApiCall({ apiPayload: payload, params, queryString });
  };

  makeApiCallIfNeeded();

  return {
    data,
    meta,
    error,
    isLoading,
    isError,
    makeCall: makeApiCall
  };
};

export default useApi;
