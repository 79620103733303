import type { ProviderAccount } from '@/types/provider';

const getProviderAccounts = (
  providerAccounts: Array<ProviderAccount>,
  channel: string,
  provider: string
) => {
  const accounts = providerAccounts
    .filter((account) => account.provider.id === provider && account.channel === channel)
    .map((account) => ({
      id: account.id,
      name: account.name,
      configuration: account.configuration,
      providerCode: account.providerCode,
      icon: account.provider.logoUrl,
      supportedWebhookEvents: account.provider.supportedWebhookEvents
    }));

  return accounts;
};

export default getProviderAccounts;
