<template>
  <div class="max-h-[272px] overflow-y-auto">
    <div
      v-for="(organization, index) in organizations"
      :key="organization.id"
      class="rounded-xl bg-grey_50 border border-grey_200 ease-in cursor-pointer card-gradient p-4"
      :class="{
        'border border-primary_100 bg-primary_50 pointer-events-none bg-opacity-50':
          organization.id === selectedOrganizationId,
        'mt-3': !!index,
        'mr-2': organizations.length > 3
      }"
      @click="handleClick(organization.id)"
      @keydown="handleClick(organization.id)"
    >
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <InitialIcon :name="organization.name" class="mr-3" />
          <div>
            <div class="text-black text-[16px] font-semibold max-w-[400px]">
              {{ organization.name }}
            </div>
            <div class="text-grey_500 text-[14px]">{{ organization.role }}</div>
          </div>
        </div>
        <img
          v-if="organization.id === selectedOrganizationId"
          :src="CheckedPrimary"
          alt="checked"
          class="w-[24px]"
        />
      </div>
    </div>
  </div>

  <div
    class="flex items-center"
    :class="
      hideSecondaryButton ? 'flex-col justify-center' : 'flex-row gap-[14px] w-full justify-end'
    "
  >
    <div v-if="!hideSecondaryButton">
      <Button
        :type="ButtonType.SECONDARY"
        @click="onClose"
        class="min-w-[120px] text-base font-semibold mt-4 mb-2"
        >{{ $t('cancel') }}
      </Button>
    </div>
    <div :class="{ 'w-full': hideSecondaryButton }">
      <Button
        :disabled="disabled"
        :isLoading="isLoading"
        @click="onSwitchOrganization"
        class="min-w-[120px] text-base font-semibold mt-4 mb-2"
        :class="buttonClassName"
        >{{ primaryButtonLabel || $t('switch_organization') }}
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';

import i18n from '@/i18n/config';
import CheckedPrimary from '@/assets/icons/CheckedPrimary.svg?url';
import InitialIcon from '@/components/InitialIcon.vue';
import Button from '@/components/Button.vue';
import useNotifierStore from '@/stores/notifier';
import { ROUTES } from '@/constants/path';
import { NotificationTypes } from '@/constants/common';
import { ButtonType } from '@/constants/components';
import { switchUserOrganization } from '@/views/team/api/api';
import type { OrganizationType } from '@/types/projects';

const props = defineProps<{
  organizations: OrganizationType[];
  activeOrganizationId: string;
  onClose: () => void;
  disabled?: boolean;
  primaryButtonLabel?: string;
  hideSecondaryButton?: boolean;
  buttonClassName?: string;
}>();

const emits = defineEmits(['onSelect']);

const notifier = useNotifierStore();
const router = useRouter();

const selectedOrganizationId = ref<string>('');

const {
  isError: isSwitchError,
  error,
  isLoading,
  makeCall: switchOrganization
} = switchUserOrganization();

const handleClick = (organizationId: string) => {
  if (selectedOrganizationId.value !== organizationId) {
    selectedOrganizationId.value = organizationId;
    emits('onSelect', selectedOrganizationId.value);
  }
};

const onSwitchOrganization = async () => {
  if (selectedOrganizationId?.value)
    if (selectedOrganizationId.value === props.activeOrganizationId) {
      await props.onClose();
      router.push(ROUTES.DASHBOARD);
    } else {
      await switchOrganization({
        apiPayload: {
          organizationId: selectedOrganizationId.value
        }
      });

      if (!isSwitchError.value) {
        await props.onClose();

        const url = `${window.location.origin}${ROUTES.DASHBOARD}`;

        window.location.replace(url);
      } else {
        notifier.setNotification({
          message: error.value.error?.message ?? i18n.global.t('something_went_wrong'),
          type: NotificationTypes.ERROR
        });
        props.onClose();
      }
    }
};

watchEffect(() => {
  if (props.activeOrganizationId) selectedOrganizationId.value = props.activeOrganizationId;
});
</script>
