import Success from '@/assets/icons/insights/Success.svg?url';
import Failed from '@/assets/icons/insights/Failed.svg?url';
import SMS from '@/assets/icons/templates/Sms.vue';
import PUSH from '@/assets/icons/templates/Push.vue';
import WHATSAPP from '@/assets/icons/templates/WhatsApp.vue';
import EMAIL from '@/assets/icons/templates/Email.vue';
import SLACK from '@/assets/icons/templates/Slack.vue';
import IN_APP from '@/assets/icons/templates/InApp.vue';
import TEAMS from '@/assets/icons/templates/Teams.vue';
import DISCORD from '@/assets/icons/templates/Discord.vue';
import LINE from '@/assets/icons/templates/Line.vue';
import type { ChipAttributes, ChipListAttributes, Map } from '@/types';

import { STATUS_COLOR } from './common';
import colors from './colors';

export enum INSIGHTS_TYPE {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

export enum INSIGHTS_SOURCE_TYPE {
  PROVIDER = 'PROVIDER',
  EVENT_WORKFLOW = 'EVENT_WORKFLOW'
}

export enum LOG_STATUS_TYPE {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}
export const LOG_RECIPIENT_MAX_LENGTH = 20;

export const LOG_STATUS_COLOR = {
  [LOG_STATUS_TYPE.IN_PROGRESS]: STATUS_COLOR.YELLOW,
  [LOG_STATUS_TYPE.SUCCESS]: STATUS_COLOR.GREEN,
  [LOG_STATUS_TYPE.FAILED]: STATUS_COLOR.RED
};

export const channelIcons: Map = {
  PUSH,
  WHATSAPP,
  EMAIL,
  SMS,
  SLACK,
  IN_APP,
  TEAMS,
  DISCORD,
  LINE
};

export const InsightIcons: Map = {
  [INSIGHTS_TYPE.SUCCESS]: Success,
  [INSIGHTS_TYPE.FAILED]: Failed
};

export const LogsFailedStatus = [
  {
    group: null,
    name: 'Send Failed',
    value: 'SEND_FAILED'
  },
  {
    group: null,
    name: 'Delivery Failed',
    value: 'DELIVERY_FAILED'
  }
];

export const InsightMessages: Map = {
  [INSIGHTS_TYPE.SUCCESS]: 'insight_success_message'
};

export enum LOGS_DETAIL_TABS {
  SUMMARY = 'summary',
  PLACEHOLDERS = 'placeholders',
  CONTENT = 'content',
  RESPONSE = 'response'
}

export const LogDetailsTabs = [
  {
    id: LOGS_DETAIL_TABS.SUMMARY,
    label: 'Summary',
    index: 0
  },
  {
    id: LOGS_DETAIL_TABS.PLACEHOLDERS,
    label: 'Placeholders',
    index: 1
  },
  {
    id: LOGS_DETAIL_TABS.CONTENT,
    label: 'Content',
    index: 2
  },
  {
    id: LOGS_DETAIL_TABS.RESPONSE,
    label: 'Response',
    index: 3
  }
];

export const LogSummaryFields = [
  {
    id: 'messageId',
    label: 'message_id',
    copy: true
  },
  {
    id: 'requestId',
    label: 'request_id',
    copy: true
  },
  {
    id: 'channel',
    label: 'channel'
  },
  {
    id: 'providerName',
    label: 'provider_name'
  },
  {
    id: 'destination',
    label: 'destination',
    copy: true
  },
  {
    id: 'date',
    label: 'date'
  },
  {
    id: 'time',
    label: 'time'
  },
  {
    id: 'integrationCustom',
    label: 'integration_custom'
  },
  {
    id: 'routing',
    label: 'routing'
  },
  {
    id: 'template',
    label: 'template'
  },
  {
    id: 'event',
    label: 'event'
  },
  {
    id: 'retry',
    label: 'retry'
  }
];

export const LogsCodeEditorStyles = {
  height: '486px',
  width: '705px',
  padding: '1px',
  borderRadius: '6px',
  backgroundColor: `${colors.grey_800} !important`
};

export enum FILTER_CATEGORIES {
  DATE_RANGE = 'createdAt',
  TEMPLATES = 'templateId',
  CHANNELS = 'channel',
  PROVIDERS = 'providerId',
  ACCOUNT_NAME = 'providerIntegrationId',
  WORKFLOW = 'eventWorkflowId',
  STATUS = 'status'
}

export enum DATE_RANGE {
  TODAY = 'today',
  WEEK = 'week',
  MONTH = 'month',
  ALL = 'all',
  CUSTOM = 'custom'
}

export const LAST_7_DAYS = 7;
export const LAST_30_DAYS = 30;
export const LAST_90_DAYS = 90;

export const DateRangeOptions = [
  {
    value: '',
    name: 'last_90_days'
  },
  {
    value: DATE_RANGE.TODAY,
    name: 'today'
  },
  {
    value: DATE_RANGE.WEEK,
    name: 'last_7_days'
  },
  {
    value: DATE_RANGE.MONTH,
    name: 'last_30_days'
  },
  {
    value: DATE_RANGE.CUSTOM,
    name: 'custom_dates'
  }
];

export const FILTER_CHIP_ATTRIBUTES: ChipAttributes = Object.freeze({
  averageCharacterSize: 10,
  padding: 24,
  iconWidth: 12,
  gap: 8
});

export const FILTER_LIST_ATTRIBUTES: ChipListAttributes = Object.freeze({
  gap: 12,
  trailingTextSize: 70
});

export const MAX_FIELD_LENGTH = 40;
