<template>
  <div
    v-if="notifyStore.show"
    class="bg-white flex text-grey_500 text-sm text-start mt-6 p-5 border border-grey_300 rounded-[6px] border-l-[6px]"
    :class="[
      styles.border,
      {
        'fixed max-w-[550px] min-w-[384px] right-4 bottom-4 z-[10000]': !notify.isInline,
        'w-full': notify.isInline
      }
    ]"
  >
    <div
      class="border h-fit flex-shrink-0 p-[6px] rounded-lg"
      :class="[styles?.iconWrapper, sizeClasses.wrapper]"
    >
      <img :src="styles.icon" alt="alert_icon" class="w-5 block" />
    </div>
    <div :class="[sizeClasses.gap, 'w-full']">
      <p class="text-grey_900 w-[calc(100%-25px)] text-base font-semibold">
        {{ notify.title || styles.title }}
      </p>
      <p class="text-grey_600 text-sm font-normal mt-1">
        {{ notify.message }}
      </p>
      <p
        v-if="notify.actionText && notify.onAction"
        role="presentation"
        @click="notify.onAction"
        :class="styles.text"
        class="text-sm font-semibold cursor-pointer mt-4"
      >
        {{ notify.actionText }}
      </p>
    </div>
    <div
      v-if="!notify.hideClose"
      class="w-[30px] h-[30px] hover:bg-grey_100 rounded-full flex items-center justify-center"
      :class="{
        'absolute right-5 top-3': !notify.isInline,
        relative: notify.isInline
      }"
      @click="notifyStore.clearNotification()"
      @keydown="notifyStore.clearNotification()"
    >
      <img src="@/assets/icons/alert/Close.svg" alt="Close" class="w-5" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';

import Success from '@/assets/icons/alert/Success.svg';
import Error from '@/assets/icons/alert/Error.svg';
import Info from '@/assets/icons/alert/Info.svg';
import Warning from '@/assets/icons/alert/Warning.svg';
import useNotifierStore from '@/stores/notifier';
import { NotificationTypes } from '@/constants/common';
import { SizeType } from '@/constants/components';

const notifyStore = useNotifierStore();
const notify = ref();

const styles = computed(() => {
  switch (notifyStore.notification.type) {
    case NotificationTypes.SUCCESS:
      return {
        iconWrapper: 'bg-success_50 border-success_75',
        border: 'border-l-success_600',
        text: 'text-success_600',
        icon: Success,
        title: 'Success'
      };

    case NotificationTypes.WARNING:
      return {
        iconWrapper: 'bg-warning_50 border-warning_75',
        border: 'border-l-warning_500',
        text: 'text-warning_500',
        icon: Warning,
        title: 'Warning'
      };

    case NotificationTypes.INFO:
      return {
        iconWrapper: 'bg-secondary_50 border-secondary_75',
        border: 'border-l-secondary_500',
        text: 'text-secondary_500',
        icon: Info,
        title: 'Information'
      };
    case NotificationTypes.ERROR:
    default:
      return {
        iconWrapper: 'bg-error_50 border-error_75',
        border: 'border-l-error_500',
        text: 'text-error_500',
        icon: Error,
        title: 'Error'
      };
  }
});

const sizeClasses = computed(() =>
  notifyStore.notification.size === SizeType.MEDIUM
    ? {
        wrapper: 'p-5',
        title: 'text-base',
        gap: 'pl-4'
      }
    : {
        wrapper: 'p-3',
        title: 'text-sm',
        gap: 'pl-4'
      }
);

watchEffect(() => {
  notify.value = notifyStore.notification;
  if (notifyStore.show && !notify.value.isInline)
    setTimeout(() => {
      notifyStore.clearNotification();
    }, 5000);
});
</script>
