export enum SizeType {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small'
}

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TEXT_PRIMARY = 'text_primary',
  TEXT_SECONDARY = 'text_secondary',
  SUCCESS = 'success'
}

export enum InputType {
  NUMBER = 'number',
  TEXT = 'text',
  PASSWORD = 'password'
}

export enum CheckboxType {
  NORMAL = 'number',
  ROUNDED = 'text'
}

export enum BadgeType {
  SUCCESS = 'success',
  FAILED = 'failed',
  WARNING = 'warning',
  INFO = 'info',
  DEFAULT = 'default'
}

export enum Position {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom'
}

export enum NotchPosition {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
  CENTER = 'center'
}
