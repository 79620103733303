<template>
  <div class="relative flex items-center" ref="profileRef">
    <div role="presentation" class="cursor-pointer" @click="toggleOpen">
      <div class="py-1 px-3 flex justify-between items-center">
        <img src="@/assets/icons/Avatar.svg" alt="avatar" class="mr-2" :height="28" :width="28" />
        <Arrow :isOpen="isOpen" />
      </div>
    </div>

    <div
      v-if="isOpen"
      class="absolute top-10 right-0 rounded-md shadow-lg bg-white border border-grey_100 z-10 w-[300px]"
    >
      <div class="flex items-center p-3 border-b border-grey_100">
        <div class="w-8 h-8 mr-3">
          <img src="@/assets/icons/Avatar.svg" alt="avatar" :height="32" :width="32" />
        </div>
        <div>
          <div class="text-grey_900 text-sm font-semibold max-w-[220px] truncate">
            {{ profileStore.profile?.name }}
          </div>
          <div class="text-grey_500 text-xs max-w-[220px] truncate">
            {{ profileStore.profile?.email }}
          </div>
        </div>
      </div>
      <div class="flex flex-col text-sm text-grey_900 font-normal">
        <div v-if="!hideMenu">
          <div
            role="presentation"
            @click="handleManageAccountClick"
            class="cursor-pointer flex items-center p-3 hover:bg-grey_100"
          >
            <img src="@/assets/icons/ManageAccount.svg" alt="Version" class="w-5 h-5 mr-2" />
            {{ $t('manage_account') }}
          </div>
          <div
            v-if="showSwitchOrganization"
            role="presentation"
            @click="handleSwitchOrganizationClick"
            class="cursor-pointer flex items-center p-3 hover:bg-grey_100"
          >
            <img src="@/assets/icons/Switch.svg" alt="Version" class="w-5 h-5 mr-2" />
            {{ $t('switch_organization') }}
          </div>
          <div
            v-if="hasPermission(Permissions.TEAM_PAGE_VIEW)"
            role="presentation"
            @click="handleTeamsClick"
            class="cursor-pointer flex items-center p-3 hover:bg-grey_100"
          >
            <img src="@/assets/icons/User.svg" alt="team" class="w-5 h-5 mr-2" />
            {{ $t('team') }}
          </div>
        </div>
        <div
          role="presentation"
          @click="handleLogoutClick"
          class="cursor-pointer flex items-center p-3 hover:bg-grey_100"
        >
          <img src="@/assets/icons/logout.svg" alt="Version" class="w-5 h-5 mr-2" />
          {{ $t('logout') }}
        </div>
      </div>
    </div>
  </div>

  <SwitchOrganization
    v-if="openSwitchOrganization"
    :isOpen="openSwitchOrganization"
    :onClose="closeSwitchOrganization"
  />
</template>

<script setup lang="ts">
import { computed, onUnmounted, ref, watch } from 'vue';

import useProfileStore from '@/stores/profile';
import router from '@/router';
import Arrow from '@/components/Arrow.vue';
import useLogout from '@/composables/useLogout';
import usePermissions from '@/composables/usePermissions';
import { ROUTES } from '@/constants/path';
import { Permissions } from '@/constants/permissions';

import SwitchOrganization from './SwitchOrganization.vue';
import { userLogout } from '../../login/api';

defineProps<{
  hideMenu?: boolean;
}>();

const profileStore = useProfileStore();
const { clearUserDataAndRedirectToLogin } = useLogout();
const { hasPermission } = usePermissions();

const profileRef = ref<HTMLElement | null>(null);
const isOpen = ref<boolean>(false);
const openSwitchOrganization = ref<boolean>(false);

const { makeCall: logout } = userLogout();

const showSwitchOrganization = computed(() =>
  profileStore?.profile?.externalOrganizations?.some((organization) => organization.hasAccess)
);

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};

const handleManageAccountClick = () => {
  toggleOpen();
  router.push(ROUTES.PROFILE);
};

const handleTeamsClick = () => {
  toggleOpen();
  router.push(ROUTES.TEAMS);
};

const handleOutsideClick = (event: MouseEvent) => {
  if (profileRef.value && !profileRef.value.contains(event.target as Node)) toggleOpen();
};

const handleSwitchOrganizationClick = () => {
  toggleOpen();
  openSwitchOrganization.value = true;
};

const closeSwitchOrganization = () => {
  openSwitchOrganization.value = false;
};

watch(
  () => isOpen.value,
  (open) => {
    if (open) document.addEventListener('click', handleOutsideClick);
    else document.removeEventListener('click', handleOutsideClick);
  }
);

onUnmounted(() => () => {
  document.removeEventListener('click', handleOutsideClick);
});

const handleLogoutClick = async () => {
  await logout();
  toggleOpen();
  clearUserDataAndRedirectToLogin();
};
</script>
