<template>
  <div class="relative bg-white rounded-3xl p-4 w-full h-full">
    <div class="relative flex-col w-full h-full overflow-hidden">
      <div class="video-container flex-shrink-0">
        <Carousel
          ref="onBoardingCarousel"
          :key="key"
          :items-to-show="1"
          :mouse-drag="false"
          :touch-drag="false"
          @init="adjustSlide"
        >
          <Slide v-for="(data, index) in ONBOARDING_DATA" :key="data.id">
            <div class="carousel__item">
              <WebMSource :key="data.id" :source="data.video" :onVideoEnded="onVideoEnded(index)" />
            </div>
          </Slide>
        </Carousel>
      </div>
      <div class="title-container relative">
        <div class="text-grey_700 text-2xl font-semibold mt-3">
          {{ $t(currentData.title) }}
        </div>
        <div class="text-black text-lg font-light mt-0.5">
          {{ $t(currentData.description) }}
        </div>
      </div>
      <div
        class="absolute text-primary_500 text-base cursor-pointer hover:text-primary_300 right-2 bottom-0"
        @click="$emit('close')"
        @keypress="$emit('close')"
      >
        {{ $t('close') }}
      </div>
      <div class="absolute flex gap-5 left-1/2 -translate-x-1/2 bottom-3">
        <div
          v-for="(data, index) in ONBOARDING_DATA"
          :key="data.id"
          class="dot w-3 h-3 rounded-3xl border-primary_500 border"
          :class="{
            'bg-primary_500': index <= tourIndex,
            'bg-white': index > tourIndex
          }"
        />
      </div>
    </div>

    <!-- TODO Refactor with button tag -->
    <div class="absolute -left-20 bottom-1/2 translate-y-1/2">
      <div
        class="control-button control-button-dimensions bg-white flex items-center justify-center cursor-pointer hover:bg-grey_50 relative"
        :class="{ 'pointer-events-none': isFirstStep }"
        @click="handleBackPress"
        @keypress="handleBackPress"
      >
        <div
          v-if="isFirstStep"
          class="control-button-dimensions absolute bg-black opacity-[0.13]"
        />
        <Chevron fill="#EB5017" />
      </div>
    </div>
    <div class="absolute -right-20 bottom-1/2 translate-y-1/2">
      <div
        class="control-button control-button-dimensions flex items-center justify-center cursor-pointer hover:bg-grey_50 relative bg-white"
        :class="{
          '!bg-grey_50 control-button-active': hasCurrentVideoEnded && !isLastStep,
          'pointer-events-none': isLastStep
        }"
        @click="handleNextPress"
        @keypress="handleNextPress"
      >
        <div v-if="isLastStep" class="control-button-dimensions absolute bg-black opacity-[0.13]" />
        <Chevron fill="#EB5017" :rotation="180" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.dot {
  transition: background-color 0.3s ease;
}

.control-button {
  transition: scale 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.control-button-active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #eb5017;
  border-radius: 50%;
  opacity: 0;
  animation: pulse-border 1s linear infinite;
}

@keyframes pulse-border {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.control-button-active {
  scale: 1.2;
}

.control-button:hover {
  scale: 1.2;
}

.carousel__item {
  height: calc(90vh * 79 / 100);
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.control-button-dimensions {
  height: 44px;
  width: 44px;
  border-radius: 24px;
}
</style>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';
import { nanoid } from 'nanoid';
import 'vue3-carousel/dist/carousel.css';

import Chevron from '@/assets/icons/vue-icons/Chevron.vue';
import { ONBOARDING_DATA } from '@/constants/appTour';

import WebMSource from '../WebMSource.vue';

interface CarouselType {
  slideTo: (index: number) => void;
}

const emits = defineEmits(['close']);

const tourLength = ONBOARDING_DATA.length;

const tourIndex = ref(0);
const hasCurrentVideoEnded = ref(false);
const onBoardingCarousel = ref<CarouselType | null>(null);
const key = ref<string>(nanoid(6));

const currentData = computed(() => ONBOARDING_DATA[tourIndex.value]);
const isLastStep = computed(() => tourIndex.value === tourLength - 1);
const isFirstStep = computed(() => tourIndex.value === 0);

const resetVideoEndedFlag = () => {
  hasCurrentVideoEnded.value = false;
};

const updateStep = (newStep: number) => {
  tourIndex.value = newStep;
  onBoardingCarousel.value?.slideTo(newStep);
};

const remountCarousal = () => {
  key.value = nanoid(6);
  resetVideoEndedFlag();
};

const adjustSlide = () => {
  onBoardingCarousel.value?.slideTo(tourIndex.value);
};

const handleNextPress = () => {
  resetVideoEndedFlag();
  if (!isLastStep.value) {
    const newStep = tourIndex.value + 1;

    updateStep(newStep);
  } else {
    emits('close');
  }
};

const handleBackPress = () => {
  resetVideoEndedFlag();
  if (!isFirstStep.value) {
    const newStep = tourIndex.value - 1;

    updateStep(newStep);
  }
};

const onVideoEnded = (index: number) => () => {
  if (index === tourIndex.value) hasCurrentVideoEnded.value = true;
};

onMounted(() => {
  window.addEventListener('resize', remountCarousal);
});

onUnmounted(() => {
  window.removeEventListener('resize', remountCarousal);
});
</script>
