import i18n from '@/i18n/config';
import SentIcon from '@/assets/icons/SentOutline.svg';
import ClickedIcon from '@/assets/icons/clicked.svg';
import DeliveredIcon from '@/assets/icons/delivered.svg';
import UsersIcon from '@/assets/icons/users.svg';
import Success from '@/assets/icons/Success.svg';
import ViewedIcon from '@/assets/icons/viewed.svg';
import CampaignName from '@/assets/icons/campaign-name.svg';
import CampaignDescription from '@/assets/icons/campaign-description.svg';
import CampaignGoal from '@/assets/icons/campaign-goals.svg';
import CampaignLabel from '@/assets/icons/campaign-label.svg';
import CampaignSummaryName from '@/assets/icons/campaigns/CampaignName.svg';
import CampaignSummaryDescription from '@/assets/icons/campaigns/CampaignDescription.svg';
import CampaignSummaryGoal from '@/assets/icons/campaigns/CampaignGoal.svg';
import CampaignTimezone from '@/assets/icons/campaigns/CampaignTimezone.svg';
import CampaignStarts from '@/assets/icons/campaigns/CampaignStarts.svg';
import CampaignRuns from '@/assets/icons/campaigns/CampaignRuns.svg';
import CampaignEnd from '@/assets/icons/campaigns/CampaignEnds.svg';
import SummaryLabel from '@/assets/icons/campaigns/CampaignLabel.svg';
import SMS from '@/assets/icons/templates/Sms.vue';
import PUSH from '@/assets/icons/templates/Push.vue';
import WHATSAPP from '@/assets/icons/templates/WhatsApp.vue';
import EMAIL from '@/assets/icons/templates/Email.vue';
import SLACK from '@/assets/icons/templates/Slack.vue';
import IN_APP from '@/assets/icons/templates/InApp.vue';
import TEAMS from '@/assets/icons/templates/Teams.vue';
import DISCORD from '@/assets/icons/templates/Discord.vue';
import LINE from '@/assets/icons/templates/Line.vue';
import GraphIcon from '@/assets/icons/campaigns/GraphIcon.vue';
import TableIcon from '@/assets/icons/campaigns/TableIcon.vue';
import Redshift from '@/assets/icons/datasources/Redshift.svg';
import BigQuery from '@/assets/icons/datasources/Bigquery.svg';
import Snowflake from '@/assets/icons/datasources/Snowflake.svg';
import MySQL from '@/assets/icons/datasources/Mysql.svg';
import PostgreSQL from '@/assets/icons/datasources/postgres.svg';
import Mixpanel from '@/assets/icons/datasources/mixPanel.svg';
import { SchedulingFrequencyTypes } from '@/constants/schedule';
import type {
  CamapignExecutionDataType,
  CampaignEditTitleFields,
  CampaignPreviewDetailsType,
  CampaignPreviewSummaryType,
  CampaignPreviewType,
  CampaignRequiredFields,
  CampaignTemplateSelectErrorType,
  CampaignVariableErrorType
} from '@/types/campaigns';
import type { Map } from '@/types';

import { STATUS_COLOR, TemplateTypes } from './common';

export const defaultCampaignCreationErrors = {
  campaignName: ''
};

export enum ActionTypes {
  EDIT = 'EDIT',
  DELETE = 'DELETE'
}

export enum CampaignDetailsType {
  CAMPAIGN_TYPE = 'type',
  CAMPAIGN_SCHEDULED_START = 'start',
  CAMPAIGN_SCHEDULED_END = 'end',
  CAMPAIGN_TIMEZONE = 'timezone',
  CAMPAIGN_ACTIVE_CHANNELS = 'activeChannels',
  CAMPAIGN_COHORT = 'cohort',
  CAMPAIGN_MODE = 'campaignMode'
}

export const campaignDetailsList = [
  {
    header: i18n.global.t('cohort_name'),
    value: CampaignDetailsType.CAMPAIGN_COHORT,
    isChannelItem: false
  },
  {
    header: i18n.global.t('campaign_preview.campaign_cadence'),
    value: CampaignDetailsType.CAMPAIGN_TYPE,
    isChannelItem: false
  },
  {
    header: i18n.global.t('campaign_preview.scheduled_start'),
    value: CampaignDetailsType.CAMPAIGN_SCHEDULED_START,
    isChannelItem: false
  },
  {
    header: i18n.global.t('campaign_preview.scheduled_end'),
    value: CampaignDetailsType.CAMPAIGN_SCHEDULED_END,
    isChannelItem: false
  },
  {
    header: i18n.global.t('timezone'),
    value: CampaignDetailsType.CAMPAIGN_TIMEZONE,
    isChannelItem: false
  },
  {
    header: i18n.global.t('campaign_mode'),
    value: CampaignDetailsType.CAMPAIGN_MODE,
    isChannelItem: false
  },
  {
    header: i18n.global.t('active_channels'),
    value: CampaignDetailsType.CAMPAIGN_ACTIVE_CHANNELS,
    isChannelItem: true
  }
];

export enum CampaignStaticsticsType {
  CAMPAIGN_STATS_TOTAL_TARGET_AUDIENCE = 'targetAudienceCount',
  CAMPAIGN_STATS_TOTAL_SENT = 'sentCount',
  CAMPAIGN_STATS_TOTAL_DELIVERED = 'deliveredCount',
  CAMPAIGN_STATS_TOTAL_CLICKED = 'clickedCount',
  CAMPAIGN_STATS_COMPLETION_RATE = 'completionRate',
  CAMPAIGN_STATS_TOTAL_VIEWED = 'viewedCount',
  CAMPAIGN_STATS_SUCCESS_RATE = 'successRate',
  CAMPAIGN_STATS_DELIVERED_RATE = 'deliveredRate',
  CAMPAIGN_STATS_VIEWED_RATE = 'viewedRate',
  CAMPAIGN_STATS_CLIKED_RATE = 'clickThroughRate'
}

export const campaignStatisticsList = [
  {
    header: i18n.global.t('campaign_preview.total_target_audience'),
    value: CampaignStaticsticsType.CAMPAIGN_STATS_TOTAL_TARGET_AUDIENCE,
    imgUrl: UsersIcon
  },
  {
    header: i18n.global.t('campaign_preview.total_sent'),
    value: CampaignStaticsticsType.CAMPAIGN_STATS_TOTAL_SENT,
    imgUrl: DeliveredIcon
  },
  {
    header: i18n.global.t('campaign_preview.completion_rate'),
    value: CampaignStaticsticsType.CAMPAIGN_STATS_COMPLETION_RATE,
    imgUrl: ''
  }
];

export const campaignAnalyticsList = [
  {
    header: i18n.global.t('campaign_preview.total_target_audience'),
    value: CampaignStaticsticsType.CAMPAIGN_STATS_TOTAL_TARGET_AUDIENCE,
    imgUrl: UsersIcon,
    hasTrend: false,
    isPercentage: false
  },
  {
    header: i18n.global.t('campaign_preview.sent'),
    value: CampaignStaticsticsType.CAMPAIGN_STATS_TOTAL_SENT,
    message: 'campaign_preview.success_rate',
    imgUrl: SentIcon,
    hasTrend: true,
    targetValue: CampaignStaticsticsType.CAMPAIGN_STATS_TOTAL_TARGET_AUDIENCE,
    isPercentage: false
  },
  {
    header: i18n.global.t('campaign_preview.delivered'),
    value: CampaignStaticsticsType.CAMPAIGN_STATS_TOTAL_DELIVERED,
    message: 'campaign_preview.delivered_rate',
    imgUrl: DeliveredIcon,
    hasTrend: true,
    targetValue: CampaignStaticsticsType.CAMPAIGN_STATS_TOTAL_SENT,
    isPercentage: false
  },
  {
    header: i18n.global.t('campaign_preview.viewed'),
    value: CampaignStaticsticsType.CAMPAIGN_STATS_TOTAL_VIEWED,
    message: 'campaign_preview.view_rate',
    imgUrl: ViewedIcon,
    hasTrend: true,
    targetValue: CampaignStaticsticsType.CAMPAIGN_STATS_TOTAL_DELIVERED,
    isPercentage: false
  },
  {
    header: i18n.global.t('campaign_preview.clicked'),
    value: CampaignStaticsticsType.CAMPAIGN_STATS_TOTAL_CLICKED,
    message: 'campaign_preview.ctr',
    imgUrl: ClickedIcon,
    hasTrend: true,
    targetValue: CampaignStaticsticsType.CAMPAIGN_STATS_TOTAL_DELIVERED,
    isPercentage: false
  }
];

export const totalAnalyticsList = [
  {
    header: i18n.global.t('campaign_preview.total_sent'),
    value: CampaignStaticsticsType.CAMPAIGN_STATS_TOTAL_SENT,
    imgUrl: SentIcon,
    hasTrend: false,
    targetValue: '',
    isPercentage: false
  },
  {
    header: i18n.global.t('campaign_preview.success_rate'),
    value: CampaignStaticsticsType.CAMPAIGN_STATS_SUCCESS_RATE,
    message: 'campaign_preview.success_rate',
    imgUrl: Success,
    hasTrend: false,
    isPercentage: true
  },
  {
    header: i18n.global.t('campaign_preview.delivered_rate'),
    value: CampaignStaticsticsType.CAMPAIGN_STATS_DELIVERED_RATE,
    message: 'campaign_preview.delivered_rate',
    imgUrl: DeliveredIcon,
    hasTrend: false,
    isPercentage: true
  },
  {
    header: i18n.global.t('campaign_preview.view_rate'),
    value: CampaignStaticsticsType.CAMPAIGN_STATS_VIEWED_RATE,
    message: 'campaign_preview.view_rate',
    imgUrl: ViewedIcon,
    hasTrend: false,
    isPercentage: true
  },
  {
    header: i18n.global.t('campaign_preview.ctr'),
    value: CampaignStaticsticsType.CAMPAIGN_STATS_CLIKED_RATE,
    message: 'campaign_preview.ctr',
    imgUrl: ClickedIcon,
    hasTrend: false,
    isPercentage: true
  }
];

export enum CampaignStatusType {
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  ACTIVE = 'ACTIVE',
  RUNNING = 'RUNNING',
  STOPPED = 'STOPPED',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED'
}

export enum CampaignTriggerType {
  IMMEDIATELY = 'IMMEDIATELY',
  SCHEDULE = 'SCHEDULE'
}

export const CAMPAIGN_STATUS_COLOR = {
  [CampaignStatusType.DRAFT]: STATUS_COLOR.GREY,
  [CampaignStatusType.SCHEDULED]: STATUS_COLOR.YELLOW,
  [CampaignStatusType.ACTIVE]: STATUS_COLOR.BLUE,
  [CampaignStatusType.RUNNING]: STATUS_COLOR.BLUE,
  [CampaignStatusType.STOPPED]: STATUS_COLOR.RED,
  [CampaignStatusType.COMPLETED]: STATUS_COLOR.GREEN,
  [CampaignStatusType.ARCHIVED]: STATUS_COLOR.YELLOW
};

export const providerPreviewContentDefaultValue = {
  title: '',
  message: '',
  subTitle: '',
  avatar: '',
  isPlainText: false,
  messageList: []
};

export const executionInputDataDefaultvalue = {
  channel: null,
  templateVariables: {},
  notifyVariables: {},
  providerIntegrationId: ''
};

export enum CampaignTemplateSelectSection {
  TEMPLATE_ERROR = 'templateError',
  CHANNEL_ERROR = 'channelError'
}

export const defaultTemplateSelectErrors: CampaignTemplateSelectErrorType = {
  templateError: '',
  channelError: ''
};

export const defaultCampaignPreviewDetailsValue: CampaignPreviewDetailsType = {
  type: '',
  start: '',
  end: '',
  timezone: '',
  activeChannels: '',
  cohort: '',
  campaignMode: ''
};

export const defaultCampaignPreviewSummary: CampaignPreviewSummaryType = {
  totalSent: 0,
  totalTargetAudience: 0,
  completionRate: 0,
  totalDelivered: 0,
  totalClicked: 0,
  totalViewed: 0
};

export const campaignEditStatuses = [CampaignStatusType.DRAFT, CampaignStatusType.SCHEDULED];

export const campaignStopStatuses = [
  CampaignStatusType.SCHEDULED,
  CampaignStatusType.RUNNING,
  CampaignStatusType.ACTIVE
];

export const defaultCampaignVariableErrors: CampaignVariableErrorType = {
  providerError: '',
  recipientError: '',
  variableError: {}
};

export const campaignScheduleFrequencies = [
  {
    id: SchedulingFrequencyTypes.ONCE,
    value: 'once'
  },
  {
    id: SchedulingFrequencyTypes.DAILY,
    value: 'daily'
  },
  {
    id: SchedulingFrequencyTypes.WEEKLY,
    value: 'weekly'
  },
  {
    id: SchedulingFrequencyTypes.MONTHLY,
    value: 'monthly'
  }
];

export enum CampaignModes {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM'
}

export const maxCounts: { [key in CampaignModes]: number } = {
  [CampaignModes.CUSTOM]: 2,
  [CampaignModes.DEFAULT]: 3
};

export const ChatProgressText = [i18n.global.t('siren_is_analyzing'), i18n.global.t('thinking')];

export const SCHEMA_LIST_MAX_LENGTH = 16;

export enum VariableSourceType {
  SCHEMA = 'SCHEMA',
  USER = 'USER'
}

export enum CampaignKeys {
  Details = 'Details',
  Whom = 'AudienceSelection',
  How = 'ChannelSelection',
  What = 'MessageTemplate',
  When = 'CampaignTrigger'
}

export const campaignDetailsKeyList = [
  i18n.global.t('name'),
  i18n.global.t('description'),
  i18n.global.t('goal'),
  i18n.global.t('campaign_details.label')
];

export const campaignDetailsMappings = {
  Name: { title: i18n.global.t('campaign_details.name'), icon: CampaignName, type: 'text' },
  Description: {
    title: i18n.global.t('campaign_details.description'),
    icon: CampaignDescription,
    type: 'text'
  },
  Goal: { title: i18n.global.t('campaign_details.goal'), icon: CampaignGoal, type: 'goal' },
  Label: { title: i18n.global.t('campaign_details.label'), icon: CampaignLabel, type: 'label' }
};

export enum ScheduleDetailsType {
  SCHEDULE_START_DATE = 'StartTime',
  SCHEDULE_RUN = 'scheduleRuns',
  SCHEDULE_END_DATE = 'EndTime'
}

export const scheduleDetailsList = [
  {
    header: i18n.global.t('starts'),
    value: ScheduleDetailsType.SCHEDULE_START_DATE
  },
  {
    header: i18n.global.t('runs'),
    value: ScheduleDetailsType.SCHEDULE_RUN
  },
  {
    header: i18n.global.t('ends'),
    value: ScheduleDetailsType.SCHEDULE_END_DATE
  }
];

// TODO: Update all mappings
const templateMapping = {
  Template: { title: i18n.global.t('Template Name'), icon: CampaignName },
  VariableList: { title: i18n.global.t('Variables'), icon: CampaignName }
};

// TODO: Update all mappings
const scheduleMapping = {
  TimeZone: { title: i18n.global.t('Time Zone'), icon: CampaignName },
  Timing: { title: i18n.global.t('Timing'), icon: CampaignName }
};

// TODO: Confirm key names from BE
export const previewContent: CampaignPreviewType = {
  Details: {
    title: i18n.global.t('campaign_preview_title.detail'),
    content: campaignDetailsMappings
  },
  AudienceSelection: {
    title: i18n.global.t('campaign_preview_title.whom')
  },
  ChannelSelection: {
    title: i18n.global.t('campaign_preview_title.how')
  },
  MessageTemplate: {
    title: i18n.global.t('campaign_preview_title.what'),
    content: templateMapping
  },
  CampaignTrigger: {
    title: i18n.global.t('campaign_preview_title.when'),
    content: scheduleMapping
  }
};

export const requiredFields: CampaignRequiredFields = {
  Details: ['Description', 'Goal', 'Name'],
  AudienceSelection: ['SelectedCohort'],
  ChannelSelection: ['ChannelSelection', 'SelectedProvider', 'RecipientInfo'],
  MessageTemplate: ['SelectedTemplate'],
  CampaignTrigger: ['SelectedTimezone', 'StartTime']
};

export enum ScheduleType {
  ONCE = 'ONCE',
  RECURRING = 'RECURRING'
}

export enum ChatUserType {
  AI = 'ai',
  USER = 'user'
}

export enum ChatAttribute {
  UserInput = 'user_input',
  CampaignGoal = 'goals',
  Cohort = 'cohorts',
  Channel = 'channels',
  Provider = 'providers',
  Template = 'templates',
  TemplateEdit = 'template_edit',
  Timezone = 'timezone',
  ScheduleType = 'schedule_type',
  FrequencyType = 'frequency_type',
  ScheduleRecur = 'schedule_recur',
  ScheduleOnce = 'schedule_once',
  TestRun = 'test_campaign',
  Publish = 'publish',
  RecipientTest = 'test_recipient_info',
  Recipient = 'recipient_map',
  Published = 'published',
  TimeSuggestion = 'optimal_time_suggestion',
  TestCohort = 'testCohort',
  TestAudienceSelection = 'test_audience_selection',
  TestApproval = 'test_approval',
  TestLog = 'test_log'
}

export const RecipentTestMessage: Map = {
  [TemplateTypes.EMAIL]: i18n.global.t('recipient_message.email'),
  [TemplateTypes.SMS]: i18n.global.t('recipient_message.sms'),
  [TemplateTypes.PUSH]: i18n.global.t('recipient_message.push'),
  [TemplateTypes.SLACK]: i18n.global.t('recipient_message.slack'),
  [TemplateTypes.IN_APP]: i18n.global.t('recipient_message.inapp'),
  [TemplateTypes.DISCORD]: i18n.global.t('recipient_message.discord'),
  [TemplateTypes.TEAMS]: i18n.global.t('recipient_message.teams'),
  [TemplateTypes.LINE]: i18n.global.t('recipient_message.line'),
  [TemplateTypes.WHATSAPP]: i18n.global.t('recipient_message.whatsapp')
};

export const ScheduleTypeOptions = [{ name: 'Immediately' }, { name: 'Schedule it later' }];

export const TestRunOptions = [{ name: 'No' }, { name: "Let's do a test run" }];

export const PublishOptions = [{ name: 'Make Changes' }, { name: 'Go live' }];

export const TimeOptions = [{ name: 'Yes' }, { name: 'No' }];

export const TestAudienceOptions = [
  { name: 'Using Test Cohort', key: 'TestCohort' },
  { name: 'Send Manually', key: 'TestRecipientInfo' }
];

export const TestApprovalOptions = [
  { name: 'Looks Good', key: 'test success' },
  { name: 'Make Changes', key: 'make changes' },
  { name: 'I Didn’t Receive the Notification', key: 'test fail' }
];

export const TestLogOptions = [
  { name: 'Retry Test Run', key: "Let's do a test run" },
  { name: 'No', key: 'no' }
];

export const AttributeTitleMap: Map = {
  [ChatAttribute.CampaignGoal]: 'campaign_goal',
  [ChatAttribute.Channel]: 'channel',
  [ChatAttribute.Provider]: 'provider'
};

export enum ToneDropdownKeys {
  FORMAL = 'formal',
  FRIENDLY = 'firendly',
  CASUAL = 'casual',
  URGENT = 'urgent'
}

export enum TempalateSelectMode {
  EDIT = 'edit',
  CREATE = 'create',
  IMPORT = 'import'
}

export const toneDropdownData = [
  {
    id: ToneDropdownKeys.FORMAL,
    value: i18n.global.t('ai_tone_key.formal')
  },
  {
    id: ToneDropdownKeys.FRIENDLY,
    value: i18n.global.t('ai_tone_key.friendly')
  },
  {
    id: ToneDropdownKeys.CASUAL,
    value: i18n.global.t('ai_tone_key.casual')
  },
  {
    id: ToneDropdownKeys.URGENT,
    value: i18n.global.t('ai_tone_key.urgent')
  }
];

export const channelsUrl = {
  SMS,
  EMAIL,
  PUSH,
  WHATSAPP,
  SLACK,
  TEAMS,
  DISCORD,
  IN_APP,
  LINE
};

export const CampaignEditTitle: CampaignEditTitleFields = {
  Details: 'Campaign Details',
  AudienceSelection: 'Target Audience',
  ChannelSelection: 'Channel Selection',
  MessageTemplate: 'Message Template',
  CampaignTrigger: 'Campaign Trigger Details'
};

export const CampaignEditAttribute: CampaignEditTitleFields = {
  Details: 'campaign_detail',
  AudienceSelection: 'campaign_cohorts',
  ChannelSelection: 'campaign_channel',
  MessageTemplate: 'campaign_message_templates',
  CampaignTrigger: 'campaign_trigger_details'
};

export enum CampaignActionTypes {
  EDIT = 'edit',
  ADD = 'add'
}

export enum CampaignEditChatAttribute {
  Cohort = 'Cohort',
  Channel = 'Channel',
  Provider = 'Provider',
  Recipient = 'Recipient info'
}

export const CAMPAIGN = 'CAMPAIGN';

export const campaignCountDownOptions = [
  {
    header: i18n.global.t('campaign_preview.total_run'),
    value: 'totalRuns'
  },
  {
    header: i18n.global.t('campaign_preview.completed_run'),
    value: 'completedRuns'
  },
  {
    header: i18n.global.t('campaign_preview.pending_run'),
    value: 'pendingRuns'
  }
];

export const campaignCountDownTimerOptions = [
  {
    header: i18n.global.t('campaign_preview.days'),
    key: 'days'
  },
  {
    header: i18n.global.t('campaign_preview.hours'),
    key: 'hours'
  },
  {
    header: i18n.global.t('campaign_preview.minutes'),
    key: 'minutes'
  },
  {
    header: i18n.global.t('campaign_preview.seconds'),
    key: 'seconds'
  }
];

export enum CampaignDetailsTabsId {
  SUMMARY = 'summary',
  ANALYTICS = 'analytics'
}

export const CampaignDetailsTabs = [
  {
    id: CampaignDetailsTabsId.ANALYTICS,
    label: i18n.global.t('analytics'),
    index: 0
  },
  {
    id: CampaignDetailsTabsId.SUMMARY,
    label: i18n.global.t('summary'),
    index: 1
  }
];

export enum CampaignRunType {
  ALL = 'All',
  ONE = '1'
}

export enum CampaignRunArrowType {
  LEFT = 'left',
  RIGHT = 'right'
}

export const campaignAnalyticsTrendToggle = [
  { label: 'Graph', icon: GraphIcon },
  { label: 'Table', icon: TableIcon }
];

export const dashboardPreviewContent: CampaignPreviewType = {
  Details: {
    title: i18n.global.t('Campaign Details'),
    content: campaignDetailsMappings
  },
  AudienceSelection: {
    title: i18n.global.t('Target Audience')
  },
  ChannelSelection: {
    title: i18n.global.t('Delivery Channel')
  },
  CampaignTrigger: {
    title: i18n.global.t('Campaign Schedule'),
    content: scheduleMapping
  }
};

// TODO: update id once data is recieved from BE
export const campaignSummaryContent = [
  {
    id: 'description',
    name: 'Description'
  },
  {
    id: 'goal',
    name: 'Goal'
  },
  {
    id: 'label',
    name: 'Label'
  },
  {
    id: 'startTime',
    name: 'Scheduled Start time'
  },
  {
    id: 'endTime',
    name: 'Scheduled end time'
  },
  {
    id: 'createdBy',
    name: 'Created by'
  },
  {
    id: 'targetAudience',
    name: 'Target Audience'
  },
  {
    id: 'deliveryChannel',
    name: 'Delivery Channel'
  },
  {
    id: 'provider',
    name: 'Provider'
  },
  {
    id: 'timeZone',
    name: 'Time Zone'
  }
];

export const defaultCampaignExecutionData: CamapignExecutionDataType = {
  id: '',
  status: '',
  executionTime: '',
  targetAudienceCount: 0,
  sentCount: 0,
  deliveredCount: 0,
  clickedCount: 0,
  viewedCount: 0,
  runNumber: 0
};

export enum CampaignExecutionMode {
  TRIGGER = 'TRIGGER',
  TEST_RUN = 'TEST_RUN'
}

export const summaryDetailFields = [
  {
    id: 'name',
    label: i18n.global.t('campaign_details.name'),
    icon: CampaignSummaryName,
    field: campaignDetailsKeyList[0]
  },
  {
    id: 'description',
    label: i18n.global.t('campaign_details.description'),
    icon: CampaignSummaryDescription,
    field: campaignDetailsKeyList[1]
  },
  {
    id: 'goal',
    label: i18n.global.t('campaign_details.goal'),
    icon: CampaignSummaryGoal,
    field: campaignDetailsKeyList[2]
  },
  {
    id: 'label',
    label: i18n.global.t('campaign_details.label'),
    icon: SummaryLabel,
    field: campaignDetailsKeyList[3]
  }
];

export const scheduleDetailsKeyList = [
  i18n.global.t('schedule_fields.selected_timezone'),
  i18n.global.t('schedule_fields.start_time'),
  i18n.global.t('schedule_fields.frequency_type'),
  i18n.global.t('schedule_fields.end_time')
];

export const campaignSummaryFields = [
  {
    id: 'timezone',
    label: i18n.global.t('time_zone'),
    icon: CampaignTimezone,
    field: scheduleDetailsKeyList[0]
  },
  {
    id: 'starts',
    label: i18n.global.t('starts'),
    icon: CampaignStarts,
    field: scheduleDetailsKeyList[1]
  },
  {
    id: 'runs',
    label: i18n.global.t('runs'),
    icon: CampaignRuns,
    field: scheduleDetailsKeyList[2]
  },
  {
    id: 'end',
    label: i18n.global.t('end'),
    icon: CampaignEnd,
    field: scheduleDetailsKeyList[3]
  }
];

export const RecipientVariableMap: Map = {
  [TemplateTypes.WHATSAPP]: { label: 'recipient-variable-names.whatsapp', key: 'whatsapp' },
  [TemplateTypes.EMAIL]: { label: 'recipient-variable-names.email', key: 'email' },
  [TemplateTypes.SMS]: { label: 'recipient-variable-names.sms', key: 'sms' },
  [TemplateTypes.PUSH]: { label: 'recipient-variable-names.push', key: 'pushToken' },
  [TemplateTypes.SLACK]: { label: 'recipient-variable-names.slack', key: 'slack' },
  [TemplateTypes.TEAMS]: { label: 'recipient-variable-names.teams', key: 'teams' },
  [TemplateTypes.IN_APP]: { label: 'recipient-variable-names.in_app', key: 'inApp' },
  [TemplateTypes.DISCORD]: { label: 'recipient-variable-names.discord', key: 'discord' },
  [TemplateTypes.LINE]: { label: 'recipient-variable-names.line', key: 'line' }
};

export const CohortIcon: Map = {
  Redshift,
  BigQuery,
  Snowflake,
  MySQL,
  PostgreSQL,
  Mixpanel
};
