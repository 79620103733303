import { ref } from 'vue';
import { defineStore } from 'pinia';

import { SizeType } from '@/constants/components';
import type { NotificationType } from '@/types';

const defaultNotification: NotificationType = {
  title: '',
  message: '',
  type: '',
  actionText: '',
  size: SizeType.SMALL,
  isInline: false,
  onAction: () => null
};

const useNotifierStore = defineStore('notifier', () => {
  const show = ref<boolean>(false);
  const notification = ref<NotificationType>(defaultNotification);

  const setNotification = (notificationInfo: NotificationType) => {
    show.value = true;
    notification.value = notificationInfo;
  };

  const clearNotification = () => {
    show.value = false;
    notification.value = defaultNotification;
  };

  return {
    show,
    notification,
    setNotification,
    clearNotification
  };
});

export default useNotifierStore;
