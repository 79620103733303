import router from '@/router';
import LocalStorage from '@/services/storage';
import useKeysStore from '@/stores/keys';
import useLogsStore from '@/stores/logs';
import useNotifierStore from '@/stores/notifier';
import useProfileStore from '@/stores/profile';
import useProjectStore from '@/stores/project';
import useProviderStore from '@/stores/provider';
import useTemplateStore from '@/stores/template';
import useWorkflowStore from '@/stores/workflow';
import { ROUTES } from '@/constants/path';
import { LOGIN_STATUS } from '@/constants/common';

export default () => {
  const keysStore = useKeysStore();
  const logsStore = useLogsStore();
  const notifierStore = useNotifierStore();
  const profileStore = useProfileStore();
  const projectStore = useProjectStore();
  const providerStore = useProviderStore();
  const templateStore = useTemplateStore();
  const workflowStore = useWorkflowStore();

  const clearUserData = () => {
    // clear local storage data
    LocalStorage.removeItem(LOGIN_STATUS);

    // clear store data.
    keysStore.reset();
    logsStore.reset();
    notifierStore.clearNotification();
    projectStore.reset();
    profileStore.reset();
    providerStore.reset();
    templateStore.reset();
    workflowStore.reset();
  };

  const clearUserDataAndRedirectToLogin = () => {
    clearUserData();
    router.push(ROUTES.LOGIN);
  };

  return { clearUserData, clearUserDataAndRedirectToLogin };
};
