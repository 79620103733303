<template>
  <Walkthrough
    v-if="showWalkthrough"
    :open="showWalkthrough"
    @close="handleWalkThrough"
    :isTutorial="true"
  />
  <div
    class="sidebar flex flex-col justify-between w-[82px] bg-grey_900 h-full px-[8px] py-4 pt-[32px] fixed top-0 z-50 transition-all duration-300 overflow-hidden"
  >
    <div>
      <div class="mb-10 ml-[14px] flex">
        <img
          src="@/assets/icons/Siren.svg"
          alt="siren logo"
          class="cursor-pointer"
          :height="28"
          :width="32"
          role="presentation"
          @click="navigateToHome()"
        />
        <div class="siren-text text-[22px] ml-3 font-bold text-white opacity-0">Siren</div>
      </div>
      <div v-for="(nav, index) in authorisedHeaderNavs" :key="nav.label">
        <div v-if="nav?.subRoutes">
          <div ref="navItem" :key="index">
            <div
              class="sidenav h-12 relative flex mt-1 items-center overflow-hidden hover:bg-grey_800 rounded-[4px] cursor-pointer"
              :class="isSelectedNav(nav) ? 'bg-grey_800' : ''"
              :onclick="() => onNavClick(nav)"
            >
              <div class="absolute left-[18px]">
                <div class="h-6 w-6 min-h-6 min-w-6 flex items-center justify-center">
                  <component
                    :is="nav.icon"
                    v-bind="{ fill: isSelectedNav(nav) ? colors.primary_400 : colors.grey_200 }"
                  />
                </div>
              </div>
              <div
                class="sidebar-label hidden text-white text-[15px] font-normal pl-[54px] pr-[12px] overflow-hidden text-nowrap"
                :class="isSelectedNav(nav) ? 'text-blue_primary' : ''"
              >
                {{ $t(nav.label) }}
              </div>
              <div
                v-if="BetaFeatures.includes(nav.label)"
                class="sidebar-label-icon text-xs px-2 rounded-full border border-primary_400 text-primary_400"
              >
                {{ $t('beta') }}
              </div>
              <img
                v-if="nav?.subRoutes"
                src="@/assets/icons/AccordionArrow.svg"
                alt="filter"
                :class="isOpenedRoute(nav) ? '' : 'rotate-180'"
                class="sidebar-label-icon absolute right-3"
                :height="20"
                :width="20"
              />
            </div>
            <router-link
              :to="subRoute.path"
              :key="subRoute.label"
              :aria-label="subRoute.label"
              v-for="subRoute in getAuthorisedSubRoutes(nav?.subRoutes)"
              class="hidden"
              :class="isOpenedRoute(nav) ? 'sidebar-label' : ''"
            >
              <div
                class="sidenav h-12 relative flex mt-1 items-center overflow-hidden hover:bg-grey_800 rounded-[4px] cursor-pointer w-[240px]"
                :class="isSelectedNav(subRoute) ? 'bg-grey_800' : ''"
                ref="navItem"
              >
                <div
                  class="sidebar-label text-white text-[15px] font-normal pl-[54px] pr-[16px] overflow-hidden text-nowrap"
                  :class="isSelectedNav(subRoute) ? 'text-blue_primary' : ''"
                >
                  {{ $t(subRoute.label) }}
                </div>
              </div>
            </router-link>
          </div>
        </div>

        <router-link :to="nav.path" :aria-label="nav.label" v-else>
          <div
            class="sidenav h-12 relative flex mt-1 items-center overflow-hidden hover:bg-grey_800 rounded-[4px] cursor-pointer"
            :class="isSelectedNav(nav) ? 'bg-grey_800' : ''"
          >
            <div class="absolute left-[18px]">
              <div class="h-6 w-6 min-h-6 min-w-6 flex items-center justify-center">
                <component
                  :is="nav.icon"
                  v-bind="{ fill: isSelectedNav(nav) ? colors.primary_400 : colors.grey_200 }"
                />
              </div>
            </div>
            <div
              class="sidebar-label hidden text-white text-[15px] font-normal pl-[54px] pr-[12px] overflow-hidden text-nowrap"
              :class="isSelectedNav(nav) ? 'text-blue_primary' : ''"
            >
              {{ $t(nav.label) }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="border-t border-grey_800 pt-4">
      <div
        role="presentation"
        class="sidenav h-12 relative flex mt-1 items-center overflow-hidden hover:bg-grey_800 rounded-[4px] cursor-pointer"
        @click.stop.prevent="handleWalkThrough"
      >
        <div class="absolute left-[18px]">
          <div class="h-6 w-6 min-h-6 min-w-6 flex items-center justify-center">
            <img src="@/assets/icons/sidebar/Watch.svg" alt="watch" />
          </div>
        </div>
        <div
          class="sidebar-label hidden text-white text-[15px] font-normal pl-[54px] pr-[12px] overflow-hidden text-nowrap"
        >
          {{ $t('watch_tutorial') }}
        </div>
      </div>
      <div
        role="presentation"
        class="sidenav h-12 relative flex mt-1 items-center overflow-hidden hover:bg-grey_800 rounded-[4px] cursor-pointer"
        @click.stop.prevent="handleProviderUrlClick(ProviderDocMap.Siren)"
      >
        <div class="absolute left-[18px]">
          <div class="h-6 w-6 min-h-6 min-w-6 flex items-center justify-center">
            <img src="@/assets/icons/sidebar/Docs.svg" alt="docs" />
          </div>
        </div>
        <div
          class="sidebar-label hidden text-white text-[15px] font-normal pl-[54px] pr-[12px] overflow-hidden text-nowrap"
        >
          {{ $t('documentation') }}
        </div>
      </div>
      <div class="ml-3 flex items-center pt-4">
        <div
          class="h-[40px] min-w-[40px] bg-grey_600 border-4 border-solid border-grey_800 rounded-lg text-white flex items-center justify-center cursor-default"
        >
          {{ activeOrganization?.name ? getInitials(activeOrganization.name) : '' }}
        </div>

        <div
          class="org-name opacity-0 text-white text-[15px] font-normal rounded-md max-w-[190px] text-ellipsis overflow-hidden whitespace-nowrap ml-3"
        >
          {{ activeOrganization?.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import useProfileStore from '@/stores/profile';
import usePermissions from '@/composables/usePermissions';
import colors from '@/constants/colors';

import { getInitials } from '@/utils/generic';
import { handleProviderUrlClick } from '@/utils/providers/providers';
import { ProviderDocMap } from '@/constants/providers';
import { headerNavs, ROUTES, type NavType } from '@/constants/path';
import { CAMPAIGN_TENANT } from '@/constants/config';
import { BetaFeatures } from '@/constants/common';

import Walkthrough from './appTour/Walkthrough.vue';

const router = useRouter();
const route = useRoute();
const profileStore = useProfileStore();
const { hasPermission } = usePermissions();
const { activeOrganization } = profileStore.profile || {};

const openedRoutes = ref<ROUTES[]>([]);
const showWalkthrough = ref<boolean>(false);

const authorisedHeaderNavs = computed(() =>
  headerNavs.filter(
    (nav) =>
      nav.access.some(hasPermission) &&
      (!nav.hasFeatureFlag ||
        JSON.parse(CAMPAIGN_TENANT).includes(profileStore.profile?.activeOrganization.name))
  )
);

const getAuthorisedSubRoutes = (subRoutes: NavType[]) =>
  subRoutes.filter((nav) => nav.access.some(hasPermission));

const navigateToHome = () => {
  router.push(ROUTES.DEFAULT);
};

const handleWalkThrough = async () => {
  showWalkthrough.value = !showWalkthrough.value;
};

const isSelectedNav = (nav: NavType) => {
  const currentPathTemplate = route.matched[route.matched.length - 1].path;

  if (nav.path === currentPathTemplate || nav.childRoutes?.includes(currentPathTemplate))
    return true;

  return false;
};

const isOpenedRoute = (nav: NavType) => [...openedRoutes.value].includes(nav.path);

const onNavClick = (nav: NavType) => {
  const tempRoutes = [...openedRoutes.value];

  if (tempRoutes.includes(nav.path)) tempRoutes.splice(tempRoutes.indexOf(nav.path), 1);
  else tempRoutes.push(nav.path);

  openedRoutes.value = tempRoutes;
};
</script>

<style>
.accordian-shadow {
  box-shadow: 0px 1.5px 4px -1px rgba(16, 25, 40, 0.07);
}

.sidebar:hover {
  width: 272px;
}

.sidebar:hover .siren-text {
  opacity: 1;
}

.sidebar:hover .sidenav {
  justify-content: flex-start;
}

.sidebar:hover .sidebar-label {
  display: block;
  text-wrap: nowrap;
}

.sidebar-label-icon {
  opacity: 0;
  transition: all 150ms ease-in;
}

.sidebar:hover .sidebar-label-icon {
  display: block;
  opacity: 1;
}

.sidebar:hover .org-name {
  opacity: 1;
  transition: all 500ms ease-in;
}
</style>
