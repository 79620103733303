import { createI18n } from 'vue-i18n';
import enTranslations from './locales/en.json';

type MessageSchema = typeof enTranslations;

const translations = {
  en: enTranslations
};

const i18n = createI18n<[MessageSchema], 'en'>({
  locale: 'en',
  fallbackLocale: 'en',
  messages: translations
});

export default i18n;
